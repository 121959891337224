import React from "react";

const PredictorDetailsBanner = () => {
  return (
    <div className="w-full  overflow-hidden max-md:flex-col bg-white flex py-10">
      <div className="container flex justify-between items-center max-md:flex-col">
        <div className="flex flex-col items-start gap-4 w-[522px]">
          <h3 className="text-[#173CBA] font-inter text-[24px] font-semibold leading-[34px]">
            Early Rank & College Predictor 2025
          </h3>
          <p className="text-[#173CBA] font-inter text-[20px] font-normal leading-[34px]">
            Category - General PwD
          </p>
          <p className="text-[#173CBA] font-inter text-[20px] font-normal leading-[34px]">
            JEE Main 2025 Percentile - 99
          </p>
          <p className="text-[#173CBA] font-inter text-[20px] font-normal leading-[34px]">
            Predicted JEE Main 2025 CRL Rank - 220
          </p>
        </div>

        <div className="flex flex-col w-[413px] gap-1">
          <div className="w-[413px] h-[216px] flex-shrink-0 rounded-[10px] bg-[radial-gradient(82.88%_47.84%_at_53.63%_47.84%,_#173CBA_0%,_#001868_100%)] flex flex-col gap-6 items-center justify-center text-white py-7">
            <p className="text-[18px] font-bold leading-[34px] text-center">
              Predicted JEE Main Category 2025 Rank
            </p>
            <div className="flex items-center justify-between w-full px-10">
              <span className="text-[14px] text-white/30">198</span>
              <p className="text-[24px] font-bold leading-[34px] text-center text-white">
                220
              </p>
              <span className="text-[14px] text-white/30">242</span>
            </div>

            <img
              src="/images/Early-College-predictor-icon.webp"
              alt="Downward Indicator"
              className="w-[413px] h-[47px] flex-shrink-0"
            />
          </div>
          <div className="w-full flex py-1">
            <p className="text-[#333] text-right flex justify-end font-inter text-[14px] font-normal leading-[34px] ml-auto">
              Predicted JEE Main 2025 CRL Rank - 13,000
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PredictorDetailsBanner;
