import React, { useEffect, useState, useCallback } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import config from "../../../util/config";

const ArticlesBlogs = () => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [navBtnDisabled, setNavBtnDisabled] = useState({
    prev: true,
    next: false,
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  const [articles, setArticles] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(
          config.apiUrl + "/api/course/get-all-streams-courses"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setArticles(result.exploreMore?.articles || []);
      } catch (err: any) {
        setError(err.message || "An unknown error occurred");
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    if (swiperRef && swiperRef.slides) {
      setTotalSlides(swiperRef.slides.length);
    }
  }, [swiperRef]);

  const handleSlideChange = () => {
    if (swiperRef) {
      setCurrentSlide(swiperRef.realIndex || 0);
      setNavBtnDisabled({
        prev: swiperRef.isBeginning,
        next: swiperRef.isEnd,
      });
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="w-full bg-[#E9F3EE] py-[20px]  max-sm:py-6">
      <h3 className="text-[#173CBA] text-[40px] font-bold mb-9 md:ml-[70px] max-md:text-center">
        Articles & <span className="text-[#0C9]">Blogs</span>
      </h3>
      <div className="container flex w-full">
        <Swiper
          onSwiper={setSwiperRef}
          onSlideChange={handleSlideChange}
          spaceBetween={30}
          className=""
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
        >
          {articles.map((card, index: number) => (
            <SwiperSlide key={index}>
              <div className="bg-[#F7F7F7] border border-[#F7F7F7] rounded-xl overflow-hidden">
                <div className="flex flex-col gap-5 h-44 min-max-lg:h-36 min-max-md:h-28 overflow-hidden">
                  <a
                    href={`blogs/career-courses/${card.slug}`}
                    className="flex flex-col gap-5 h-48 min-max-lg:h-36 min-max-md:h-28 overflow-hidden"
                  >
                    <img src={card.image} alt={card.title} />
                  </a>
                </div>
                <div className="flex flex-col gap-5 pt-4 px-4 pb-9">
                  <div className="flex flex-col gap-2.5">
                    <h4 className="text-base font-semibold text-black line-clamp-2 ">
                      {card.title}
                    </h4>
                    <p className="text-sm text-[#555555] font-normal flex flex-row gap-1">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_308_4127)">
                          <path
                            d="M10.9968 2.35682H10.4574V1.27808H9.37865V2.35682H3.98494V1.27808H2.9062V2.35682H2.36683C1.77352 2.35682 1.28809 2.84225 1.28809 3.43556V12.0655C1.28809 12.6588 1.77352 13.1442 2.36683 13.1442H10.9968C11.5901 13.1442 12.0755 12.6588 12.0755 12.0655V3.43556C12.0755 2.84225 11.5901 2.35682 10.9968 2.35682ZM10.9968 12.0655H2.36683V5.05367H10.9968V12.0655Z"
                            fill="#00C798"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_308_4127">
                            <rect
                              width="12.9449"
                              height="12.9449"
                              fill="white"
                              transform="translate(0.208984 0.73877)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {card.publishDate}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm h-10 text-[#555555] line-clamp-2">
                      {card.description}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ArticlesBlogs;
