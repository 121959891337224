import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import FiltersSidebar from "./components/FiltersSidebar";
import LeadGenCtaBtn from "../../features/lead-generation/LeadGenCtaBtn";
import KnowMoreModal from "./components/KnowMoreModal";
import config from "../../util/config"; // ✅ Import API config
import collageListBGImg from "../../images/application/collagelist-bg-img.jpeg";
import "./application-admission-styles.scss";
const API_URL = `${config.apiUrl}/api/application-form/intermediate-page`;
const DETAILS_API_URL = `${config.apiUrl}/api/application-form/getknowmoredata`;

type FilterOption = {
  name: string;
  count: number;
  key: string;
};

type FilterCategory = {
  categoryKey: string;
  categoryName: string;
  options: FilterOption[];
};

type University = {
  title: string;
  id: number;
  category: string;
  name: string;
  bulletPoints: string;
  bannerImage: string;
  iconImage?: string | null;
  applyUrl: string;
  state: {
    stateId: number;
    stateName: string;
  };
};

type UniversityDetails = {
  id: number;
  applicationFormId: {
    id: number;
    category: string;
    name: string;
    title: string;
    bulletPoints: string;
    bannerImage: string;
    iconImage?: string | null;
    applyUrl: string;
    state: {
      stateId: number;
      stateName: string;
    };
  };
  established: string;
  exam: string;
  courses: string;
  instituteType: string;
  gender: string;
  facultyCount: number;
  campusSize: string;
};

const ApplicationToAdmissionFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<Record<string, string[]>>({
    category: [],
    educationStream: [],
    targetYear: [],
    state: [],
    degree: [],
    level: [],
  });

  const [universities, setUniversities] = useState<University[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterCategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedUniversity, setSelectedUniversity] =
    useState<UniversityDetails | null>(null);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const hasFetched = useRef(false);

  // ✅ Load filters from URL on first render
  useEffect(() => {
    const getFiltersFromURL = (): Record<string, string[]> => {
      return {
        category: searchParams.get("category")?.split(",") || [],
        educationStream: searchParams.get("educationStream")?.split(",") || [],
        targetYear: searchParams.get("targetYear")?.split(",") || [],
        state: searchParams.get("state")?.split(",") || [],
        degree: searchParams.get("degree")?.split(",") || [],
        level: searchParams.get("level")?.split(",") || [],
      };
    };
    setFilters(getFiltersFromURL());
  }, []);

  // ✅ API Call: Runs only when filters change
  useEffect(() => {
    if (hasFetched.current) {
      const fetchUniversities = async () => {
        try {
          setLoading(true);
          const requestBody = {
            category: filters.category[0] || "",
            educationStream: filters.educationStream[0] || "",
            level: filters.level[0] || "",
            degree: filters.degree[0] || "",
            targetYear: filters.targetYear[0] || "",
            state: filters.state[0] || "",
            sortBy: "",
            search: "",
            offset: 0,
            fetchCount: 10,
          };

          const response = await fetch(API_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
            body: JSON.stringify(requestBody),
          });

          const data = await response.json();
          setUniversities(data.list || []);
          setFilterOptions(data.filters || []);
        } catch (error) {
          console.error("Error fetching universities:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchUniversities();
    } else {
      hasFetched.current = true;
    }
  }, [filters]);

  const fetchUniversityDetails = async (id: number) => {
    try {
      const response = await fetch(
        `${DETAILS_API_URL}?applicationFormId=${id}`,
        {
          method: "GET",
          headers: {
            Accept: "*/*",
          },
        }
      );

      const data = await response.json();
      setSelectedUniversity(data);
    } catch (error) {
      console.error("Error fetching university details:", error);
    }
  };
  const handleCheckboxChange = (category: string, value: string) => {
    setFilters((prev) => {
      const updatedCategory = prev[category].includes(value)
        ? prev[category].filter((item) => item !== value)
        : [...prev[category], value];

      const newParams = new URLSearchParams(searchParams);
      updatedCategory.length > 0
        ? newParams.set(category, updatedCategory.join(","))
        : newParams.delete(category);
      setSearchParams(newParams);

      return { ...prev, [category]: updatedCategory };
    });
  };
  const handleRemoveFilter = (category: string, value: string) => {
    setFilters((prev) => {
      const updatedFilters = {
        ...prev,
        [category]: prev[category].filter((item) => item !== value),
      };

      const newParams = new URLSearchParams(searchParams);
      if (updatedFilters[category].length > 0) {
        newParams.set(category, updatedFilters[category].join(","));
      } else {
        newParams.delete(category);
      }
      setSearchParams(newParams);

      return updatedFilters;
    });
  };
  return (
    <div className="p-4">
      {/* Mobile Filter Button */}
      <div className="flex flex-col items-end md:hidden">
        <button
          className="p-2 bg-blue text-white text-lg font-semibold rounded-md"
          onClick={() => setShowMobileFilter(true)}
        >
          Open Filters
        </button>
      </div>

      <div className="flex gap-4 justify-start items-start">
        {/* Conditionally Show Filters Sidebar */}

        <div className="hidden md:block w-1/3 bg-blue p-4 text-white rounded-md">
          <FiltersSidebar
            filters={filters}
            setFilters={setFilters}
            handleCheckboxChange={handleCheckboxChange}
            filterOptions={filterOptions}
          />
        </div>

        {/* Universities List */}
        <div className="w-full md:w-2/3">
          <div className="flex flex-col gap-4">
            <div className="flex flex-wrap gap-2">
              {Object.entries(filters).map(([category, values]) =>
                values.map((value) => (
                  <div
                    key={value}
                    className="bg-white text-black py-1.5 px-3 flex items-center space-x-2 shadow-lg border border-[#7C7C7C]"
                  >
                    <span>{filterOptions
                      .find((fo) => fo.categoryKey === category)
                      ?.options.find((o) => o.key === value)?.name || value}</span>
                    <button
                      onClick={() => handleRemoveFilter(category, value)}
                      className=" text-black"
                    >
                      ✕
                    </button>
                  </div>
                ))
              )}
            </div>
            {loading ? (
              <p>Loading universities...</p>
            ) : universities.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {universities.map((university) => (
                    <div
                    key={university.id}
                    className={`relative border border-[#7C7C7C] p-4 rounded-sm shadow-lg bg-white overflow-hidden application-admission`}
                    >
                    <img 
                      src={university.bannerImage || university.iconImage || collageListBGImg} 
                      alt="Background" 
                      className="absolute inset-0 w-full h-full object-cover opacity-15 z-0"
                    />
                    <div className="flex flex-col justify-between h-full relative z-10">
                      {/* University Info */}
                      <div className="flex flex-col">
                      <div className="flex items-center gap-2">
                        {/* University Logo */}
                        <div className="max-w-32 w-32 h-12 overflow-hidden bg-white">
                        {university.iconImage && (
                          <img
                          src={university.iconImage}
                          alt={university.name}
                          className="w-full h-full object-cover"
                          />
                        )}
                        </div>

                        {/* University Name */}
                        <div className="bg-blue p-2 w-full">
                        <h3 className="font-semibold text-white text-sm leading-5 whitespace-normal break-words line-clamp-2">
                          {university.title}
                        </h3>
                        </div>
                      </div>

                      <hr className="bg-green w-full h-[1px] border-transparent my-2" />

                      <div className="mt-2 text-sm text-gray-700">
                        <div
                        dangerouslySetInnerHTML={{
                          __html: university.bulletPoints,
                        }}
                        className="text-black text-sm listMarker"
                        />
                      </div>
                      </div>

                      {/* Action Buttons - Now placed outside the university info div */}
                      <div className="mt-4 flex gap-3 justify-center">
                      <LeadGenCtaBtn
                        pageName="home-landing"
                        ctaName="dream-colleges-apply-now"
                        className="bg-green text-white py-2 px-4 rounded-md hover:bg-blue hover:text-white transition-all"
                      >
                        Apply Now
                      </LeadGenCtaBtn>
                      <button
                        onClick={() =>
                        fetchUniversityDetails(university.id)
                        }
                        className="bg-white border border-green text-green py-2 px-4 rounded-md hover:bg-green hover:text-white transition-all"
                      >
                        Know More
                      </button>
                      </div>
                    </div>
                    </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500">
                No universities match the selected filters.
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        {showMobileFilter && (
          <div className="w-full fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-[1111] top-0 h-full">
            <div className="bg-blue w-full p-6 rounded-lg h-full overflow-y-auto">
              <button
                className="absolute top-4 right-6 text-white text-xl"
                onClick={() => setShowMobileFilter(false)}
              >
                ✕
              </button>
              <div className="mt-4 overflow-y-auto">
                <FiltersSidebar
                  filters={filters}
                  setFilters={setFilters}
                  handleCheckboxChange={handleCheckboxChange}
                  filterOptions={filterOptions}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Know More Modal */}
      {selectedUniversity && (
        <KnowMoreModal
          details={selectedUniversity}
          onClose={() => setSelectedUniversity(null)}
        />
      )}
    </div>
  );
};

export default ApplicationToAdmissionFilter;