import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import TitleCard from "../../components/TitleCard";

import StudyAbroadImage from "../../../../images/blog-article/study-abroad-image.png";
import ExamBlogsImage1 from "../../../../images/blog-article/exam-section-image1.jpg";
import ExamBlogsImage2 from "../../../../images/blog-article/exam-section-image2.jpeg";
import ExamBlogsImage3 from "../../../../images/blog-article/exam-section-image3.jpg";
import { VisualStoriesCard } from "../../components/VisualStoriesCard";
import VisualStory from "../../../../components/VisualStory";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import VisualStoryCard from "../../../../components/blogs-and-articles/VisualStoryCard-new";
import parse from "html-react-parser";
interface WebAndVisualStoriesProps {
  webAndVisualStoriesResponse: any;
}

const WebAndVisualStories: React.FC<WebAndVisualStoriesProps> = ({
  webAndVisualStoriesResponse,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [visualStoryIndex, setVisualStoryIndex] = useState<number>(-1);
  const [isOpenVisualStory, setIsOpenVisualStory] = useState(false);

  // Helper function to generate slug from title
  const generateSlug = (title: string): string => {
    return title
      .toLowerCase()
      .replace(/[^\w\s-]/g, '')
      .replace(/\s+/g, '-')
      .trim();
  };

  // Get stories data
  const getStoriesData = () => {
    return webAndVisualStoriesResponse[0]?.["Web And VisualStories"]
      ?.subcategories["Articles & Blogs"]?.results || [];
  };

  // Check for story slug in URL when component mounts
  useEffect(() => {
    const storySlug = searchParams.get('story');
    if (storySlug) {
      const stories = getStoriesData();
      const storyIndex = stories.findIndex(
        (story: any) => generateSlug(story.title) === storySlug
      );
      
      if (storyIndex >= 0) {
        setVisualStoryIndex(storyIndex);
        setIsOpenVisualStory(true);
      }
    }
  }, [searchParams, webAndVisualStoriesResponse]);

  const openVisualStory = (index: number) => {
    setVisualStoryIndex(index);
    setIsOpenVisualStory(true);
    
    // Get the story title and generate slug
    const stories = getStoriesData();
    const story = stories[index];
    const slug = generateSlug(story.title);
    
    // Update URL with story slug
    searchParams.set('story', slug);
    setSearchParams(searchParams);
  };

  const closeVisualStory = () => {
    setIsOpenVisualStory(false);
    
    // Remove slug from URL when closing
    searchParams.delete('story');
    setSearchParams(searchParams);
  };

  const getVisualStorySlides = (slides: any[]) => {
    return slides?.map((slide) => ({
      image: slide.image,
      content: (
        <div>
          <h1 className="text-xl font-bold text-center text-white">
            {slide.title}
          </h1>
          <p className="mt-2 text-base text-justify text-white">
            {parse(slide.description)}
          </p>
        </div>
      ),
    }));
  };

  console.log("inga paruda inga inga",webAndVisualStoriesResponse[0]?.[
    "Web And VisualStories"
  ]?.subcategories["Articles & Blogs"]?.results);
  // useEffect(() => {
  //   getVisualStorySlides();
  // }, [visualStoryIndex]);
  return (
    <>
      <div className="container grid grid-cols-1 gap-4 py-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
        <div className="grid grid-flow-row gap-4 grid-rows">
          <TitleCard
            title="Web and Visual Stories"
            bgColor="#173CBA"
            color="#ffffff"
          />
        </div>

        <div>
          {/* {[
            StudyAbroadImage,
            ExamBlogsImage1,
            ExamBlogsImage2,
            ExamBlogsImage3,
          ].map((item, index) => (
            <>
              <VisualStoriesCard
                title="Tset"
                description="Hello"
                image={item}
                openVisualStory={() => setIsOpenVisualStory(true)}
              />
            </>
          ))} */}
          <CustomSwiper
            className="!p-2 h-full"
            items={getStoriesData().map((item: any, index: any) => (
              <React.Fragment key={index}>
                <VisualStoriesCard
                  title={item?.title}
                  description={item?.description}
                  image={item?.imageUrl}
                  topic={item?.topic}
                  publishedDate={item?.publishedDate}
                  openVisualStory={() => openVisualStory(index)}
                />
              </React.Fragment>
            ))}
            navigation={"bottom"}
            autoplay={true}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 2, spaceBetween: 20 },
              1024: { slidesPerView: 3, spaceBetween: 20 },
              1280: { slidesPerView: 4, spaceBetween: 30 },
            }}
            progressBar={true}
          />
        </div>
      </div>

      <VisualStory
        isOpen={isOpenVisualStory}
        slides={
          (isOpenVisualStory &&
            getVisualStorySlides(
              webAndVisualStoriesResponse[0]?.["Web And VisualStories"]
                ?.subcategories["Articles & Blogs"]?.results[visualStoryIndex]
                ?.sliders
            )) ||
          []
          // webAndVisualStoriesResponse[0]?.["Web And VisualStories"]?.subcategories[
          //   "Articles & Blogs"
          // ]?.results[visualStoryIndex]?.sliders
        }
        handleClose={closeVisualStory}

      />
    </>
  );
};

export default WebAndVisualStories;
