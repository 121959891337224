import React, { useState, useEffect, useCallback } from 'react';
import apiService from '../../services/appService';
import BookingForm from '../BookingForm/BookingForm';
import './StudentDashboard.css';

// Define interfaces for the data structures
interface Mentor {
  mentorId: string | number;
  name: string;
  // Add other mentor properties as needed
}

interface Booking {
  bookingId: string | number;
  mentorId: string | number;
  status: 'PENDING' | 'APPROVED' | 'CANCELLED' | 'COMPLETED'; // Adjust status values as needed
  slotDate: string;
  startTime: string;
  endTime: string;
  meetingLink?: string;
  recordingUrl?: string;
}

interface StudentDashboardProps {
  token: string;
}

function StudentDashboard({ token }: StudentDashboardProps) {
  const [mentors, setMentors] = useState<Mentor[]>([]);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  

  const fetchMentors = useCallback(async (): Promise<void> => {
    setLoading(true);
    setError("");
    try {
      const response = await apiService.getMentors(token);
      setMentors(response.data as Mentor[]);
    } catch (err) {
      setError("Failed to load mentors");
    } finally {
      setLoading(false);
    }
  }, [token]); // Dependency of fetchMentors

  const fetchBookings = useCallback(async (): Promise<void> => {
    setLoading(true);
    setError("");
    try {
      const response = await apiService.getStudentBookings(token);
      setBookings((response.data as Booking[]) || []);
    } catch (err) {
      setBookings([]);
      setError("No bookings found");
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchMentors();
    fetchBookings();
  }, [fetchMentors, fetchBookings]);

  const handleCancelBooking = async (bookingId: string | number): Promise<void> => {
    setLoading(true);
    setError('');
    try {
      await apiService.updateBookingStatus(token, bookingId, 'CANCELLED');
      setBookings(bookings.map(b => 
        b.bookingId === bookingId ? { ...b, status: 'CANCELLED' } : b
      ));
    } catch (err) {
      setError('Failed to cancel booking');
    } finally {
      setLoading(false);
    }
  };

  const hasActiveBooking: boolean = bookings.some(b => 
    b.status === 'PENDING' || b.status === 'APPROVED'
  );

  return (
    <div className="student-dashboard">
      <h2>Student Dashboard</h2>
      {loading && <p className="loading">Loading...</p>}
      {error && <p className="error">{error}</p>}

      <BookingForm 
        token={token} 
        mentors={mentors} 
        fetchBookings={fetchBookings} 
        hasActiveBooking={hasActiveBooking} 
      />

      <div className="booking-details">
        <h3>Your Bookings</h3>
        {bookings.length > 0 ? (
          <ul className="booking-list">
            {bookings.map(booking => (
              <li 
                key={booking.bookingId} 
                className={`booking-item ${booking.status.toLowerCase()}`}
              >
                <div>
                  <p><strong>Status:</strong> {booking.status}</p>
                  <p>
                    <strong>Mentor:</strong> 
                    {mentors.find(m => m.mentorId === booking.mentorId)?.name || 'Unknown'}
                  </p>
                  <p>
                    <strong>Slot:</strong> 
                    {booking.slotDate} {booking.startTime} - {booking.endTime}
                  </p>
                  {booking.meetingLink && (
                    <p>
                      <strong>Meeting Link:</strong> 
                      <a href={booking.meetingLink} target="_blank" rel="noopener noreferrer">
                        {booking.meetingLink}
                      </a>
                    </p>
                  )}
                  {booking.recordingUrl && (
                    <p>
                      <strong>Recording:</strong> 
                      <a href={booking.recordingUrl} target="_blank" rel="noopener noreferrer">
                        {booking.recordingUrl}
                      </a>
                    </p>
                  )}
                </div>
                {(booking.status === 'PENDING' || booking.status === 'APPROVED') && (
                  <button 
                    onClick={() => handleCancelBooking(booking.bookingId)} 
                    disabled={loading}
                  >
                    Cancel
                  </button>
                )}
              </li>
            ))}
          </ul>
        ) : (
          !loading && <p>No bookings found.</p>
        )}
      </div>
    </div>
  );
}

export default StudentDashboard;