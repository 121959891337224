import React from "react";

interface Section {
  title: string;
  items: string[];
}

interface EducationSidebarAddProps {
  sections: Section[];
}

const EducationSidebarAdds: React.FC<EducationSidebarAddProps> = ({
  sections,
}) => {
  return (
    <div className="w-full flex flex-col gap-5">
      {sections.map((section, idx) => (
        <div key={idx} className="bg-white shadow-lg">
          <div className="bg-[#173CBA] text-white text-sm font-semibold px-4 py-3 text-center">
            {section.title}
          </div>
          <div className="w-full border border-gray-300 p-4">
            <ul className="list-none p-0">
              {section.items.map((item, index) => (
                <li
                  key={index}
                  className="text-sm leading-5 p-1.5 border-b-0 border-[0.5px] border-[#DAD5D5] last-of-type:border-b-[0.5px]"
                >
                  <a className="text-[#747474] text-sm w-full block">{item}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EducationSidebarAdds;
