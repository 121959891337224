import React from "react";
import PredictorDetailsBanner from "./components/PredictorDetailsBanner";
import TopCollegesUniversities from "./components/TopCollegesUniversities";
import ScholarshipAdmissions from "../scholarship-results/components/ScholarshipAdmissions";

const CollegePredictorDetails = () => {
  return (
    <div>
      <PredictorDetailsBanner />
      <TopCollegesUniversities />
      <ScholarshipAdmissions />
    </div>
  );
};

export default CollegePredictorDetails;
