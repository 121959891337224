import React, { useState, useRef, useEffect } from "react";
import CareerVisualSpotBlogCards from "./CareerVisualSpotBlogCards";
import CareerVisualSpotBlogCardsPopup from "./CareerVisualSpotBlogPopup";
import CustomSwiper from "../../../components/Slider/CustomSwiper";
import CareerCards from "./CareerCards";

interface Career {
  name: string;
  title: string;
}

interface CareerInterest {
  imageUrls: string;
  name: string;
  careers: Career[];
}

interface Iprops {
  careerInterest: CareerInterest[];
  careerbullseye: {
    imageUrl: string;
    name: string;
    description: string;
    slug: string;
  }[];
  careerVisualStories: {
    image: string;
    slides: {
      image: string;
      description: string;
      title: string;
    }[];
    pushlishDate?: string;
    description: string;
    title: string;
    slug: string;
  }[];
  careerspotlights: {
    image: string;
    pushlishDate?: string;
    link: string;
    description: string;
    title: string;
    views: string;
    slug: string;
  }[];
  blogsAndArticles: {
    imageUrl: string;
    publishedDate?: string;
    description: string;
    title: string;
    slug: string;
  }[];
}

const KnowledgeHub: React.FC<Iprops> = ({
  careerInterest,
  careerbullseye,
  careerVisualStories,
  careerspotlights,
  blogsAndArticles,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabIndicatorStyle, setTabIndicatorStyle] =
    useState<React.CSSProperties>({
      marginLeft: 0,
      width: 0,
    });
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedPopupData, setSelectedPopupData] = useState<any>(null);

  const tabRef = useRef<HTMLButtonElement[]>([]);

  const reorderSlides = (slides: any[], startIndex: number) => {
    return [...slides.slice(startIndex), ...slides.slice(0, startIndex)];
  };

  const handleCardClick = (slides: any[], index: number) => {
    const reorderedSlides = reorderSlides(slides, index);
    setIsPopupVisible(true);
    setSelectedPopupData({
      slides: reorderedSlides,
      initialIndex: index,
    });
  };

  const tabs = [
    {
      name: "Career Interest",
      content: careerInterest?.map((data, index) => (
        <CareerCards
          key={index}
          imageUrls={data.imageUrls}
          title={data.name}
          careers={data.careers}
        />
      )),
    },
    // {
    //   name: "Career Bullseye",
    //   content: careerbullseye.map((data, index) => (
    //     <CareerCards
    //       key={index}
    //       image={data.imageUrl}
    //       title={data.name}
    //       careers={[]}
    //     />
    //   )),
    // },
    // {
    //   name: "Career Visual Stories",
    //   content: careerVisualStories.map((data, index) => (
    //     <CareerVisualSpotBlogCards
    //       key={index}
    //       image={data.image}
    //       title={data.title}
    //       description={data.description}
    //       pushlishDate={data.pushlishDate}
    //       slug={data.slug}
    //       onCardClick={() => handleCardClick(data.slides, 0)}
    //     />
    //   )),
    // },
    // {
    //   name: "Career Spotlights",
    //   content: careerspotlights.map((data, index) => (
    //     <CareerVisualSpotBlogCards
    //       key={index}
    //       image={data.image}
    //       title={data.title}
    //       description={data.description}
    //       pushlishDate={data.pushlishDate}
    //       slug={data.slug}
    //       onCardClick={() =>
    //         handleCardClick(
    //           careerspotlights.map((item) => ({
    //             image: item.image,
    //             title: item.title,
    //             description: item.description,
    //           })),
    //           index
    //         )
    //       }
    //     />
    //   )),
    // },
    {
      name: "Blogs & Articles",
      content: blogsAndArticles.map((data, index) => (
        <CareerVisualSpotBlogCards
          key={index}
          image={data.imageUrl}
          title={data.title}
          description={data.description}
          postedOn={data.publishedDate}
          slug={data.slug}
          onCardClick={() =>
            handleCardClick(
              blogsAndArticles.map((item) => ({
                image: item.imageUrl,
                title: item.title,
                description: item.description,
              })),
              index
            )
          }
        />
      )),
    },
  ];

  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
    setIndicatorToActiveTab(index);
  };

  const setIndicatorToActiveTab = (index: number) => {
    const tabElement = tabRef.current[index];
    if (!tabElement) return;

    const tabRect = tabElement.getBoundingClientRect();
    const containerRect = tabElement.parentElement?.getBoundingClientRect();
    const distance = tabRect.left - (containerRect?.left || 0);

    setTabIndicatorStyle({
      marginLeft: `${distance + tabRect.width / 4}px`,
      width: `${tabRect.width / 2}px`,
    });
  };

  useEffect(() => {
    setIndicatorToActiveTab(activeTabIndex);
  }, [activeTabIndex]);

  return (
    <>
      {isPopupVisible && selectedPopupData && (
        <CareerVisualSpotBlogCardsPopup
          slides={selectedPopupData.slides}
          isOpen={isPopupVisible}
          initialIndex={selectedPopupData.initialIndex}
          onClose={() => setIsPopupVisible(false)}
        />
      )}
      <div className="container mx-auto">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-between">
            <h2 className="text-2xl lg:text-5xl font-bold text-black">
              Knowledge Hub
            </h2>
          </div>

          <div className="flex flex-col gap-7">
            <div className="relative">
              <div
                onMouseLeave={() => setIndicatorToActiveTab(activeTabIndex)}
                className="flex tab-container-occupation gap-4 lg:gap-20"
              >
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    ref={(el) => (tabRef.current[index] = el!)}
                    onClick={() => handleTabClick(index)}
                    className={`py-2 text-sm sm:text-base font-semibold lg:leading-[1.375rem] font-open-sans lg:text-xl hover:text-[#173CBA] transition-all duration-300 ease-in-out ${
                      activeTabIndex === index
                        ? "text-[#173CBA] font-bold"
                        : "font-normal text-[#A8A8A8]"
                    }`}
                  >
                    {tab.name}
                  </button>
                ))}
                <div
                  className="absolute bottom-[-4px] h-[6px] bg-[#00C798] rounded-lg transition-all duration-300 ease-in-out"
                  style={tabIndicatorStyle}
                />
              </div>
            </div>
          </div>

          <div className="ExploreMore-occupation w-full mt-2">
            <CustomSwiper
              items={tabs[activeTabIndex].content}
              navigation={"bottom"}
              autoplay={true}
              loop={true}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 20 },
                1024: { slidesPerView: 3, spaceBetween: 20 },
                1280: { slidesPerView: 4, spaceBetween: 30 },
              }}
              progressBar={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default KnowledgeHub;
