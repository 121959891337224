import ExamsAndAdmissions from "./sections/ExamsAndAdmissions";
import StudyAbroad from "./sections/StudyAbroad";
import CollegeAndScholarship from "./sections/CollegeAndScholarship";
import PremiumBlogs from "./sections/PremiumBlogs";
import CareersAndCourses from "./sections/CareersAndCourses";
import WebAndVisualStories from "./sections/WebAndVisualStories";
import PopularMentors from "./sections/PopularMentors";
import ArticlesAndLatestNews from "./sections/ArticlesAndLatestNews";
import Loader from "../../../components/Loader/Loader";
import Banner from "./sections/Banner";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import CollegeMentorScholarshipAd from "../components/CollegeMentorScholarshipAd";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../../images/navbar/College Mentor_Final_Logo-01.png";
import config from "../../../util/config";
import { useEffect } from "react";

const BlogsAndArticlesMain = () => {
  const { loading, landingData: allSectionData } = useSelector(
    (state: RootState) => state.blogSlice
  );

  useEffect(() => {
    document.title =
      "College Mentor | Popular Education News Blogs & Study updates";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Discover popular educational blogs with College Mentor. Get Updates on latest school news, JEE and NEET, scholarship & top-ranked universities for students."
      );
  }, []);

  if (loading) {
    return <Loader />;
  } else if (!allSectionData) return;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentor | Popular Education News Blogs & Study updates
          </title>
          <meta
            name="description"
            content="Discover popular educational blogs with College Mentor. Get Updates on latest school news, JEE and NEET, scholarship & top-ranked universities for students."
          />
          <meta
            name="keywords"
            content="latest school news, best universities for international students, popular education news blogs, best academic blogs, government education updates, blogs about student life, jee and neet updates
best blogs for students, most popular university courses, blogs on college admissions, exam preparation blogs, exam updates 2025, international study admissions news, latest college admission news, list of top-ranked universities, scholarship updates for students."
          />
          <link rel="canonical" href={`${config.apiUrl}/blogs`} />

          <meta
            property="og:title"
            content="College Mentor | Popular Education News Blogs & Study updates"
          />
          <meta
            property="og:description"
            content="Discover popular educational blogs with College Mentor. Get Updates on latest school news, JEE and NEET, scholarship & top-ranked universities for students."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | Popular Education News Blogs & Study updates"
          />
          <meta
            name="twitter:description"
            content="Discover popular educational blogs with College Mentor. Get Updates on latest school news, JEE and NEET, scholarship & top-ranked universities for students."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
          
          {/* Schema Markup for Blogs Page */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Blog",
              "name": "College Mentor Blogs",
              "url": `${config.apiUrl}/blogs`,
              "description": "College Mentor Blogs - Discover expert insights on exams, admissions, scholarships, career guidance, courses, colleges, and study abroad opportunities.",
              "hasPart": [
                {
                  "@type": "BlogPosting",
                  "name": "Career Course Tips",
                  "url": `${config.apiUrl}/blogs/career-courses`
                },
                {
                  "@type": "BlogPosting",
                  "name": "Exams",
                  "url": `${config.apiUrl}/blogs/exams`
                },
                {
                  "@type": "BlogPosting",
                  "name": "College Admission Insights",
                  "url": `${config.apiUrl}/blogs/admissions`
                },
                {
                  "@type": "BlogPosting",
                  "name": "College Insights",
                  "url": `${config.apiUrl}/blogs/colleges`
                },
                {
                  "@type": "BlogPosting",
                  "name": "Scholarship Opportunities",
                  "url": `${config.apiUrl}/blogs/scholarships`
                },
                {
                  "@type": "BlogPosting",
                  "name": "Study Abroad Career Guidance",
                  "url": `${config.apiUrl}/blogs/study-abroad`
                }
              ],
              "publisher": {
                "@type": "EducationalOrganization",
                "name": "College Mentor",
                "url": config.apiUrl,
                "logo": `${config.apiUrl}/static/media/College%20Mentor_Final_Logo-01.ae0ff9c1ffa9fc9fac3e.png`,
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+91-7997166666",
                  "contactType": "customer support",
                  "availableLanguage": ["English", "Hindi", "Telugu"]
                },
                "sameAs": [
                  "https://www.facebook.com/people/College-Mentor/61570539353128/",
                  "https://www.instagram.com/college.mentor_/",
                  "https://x.com/college_mentor_",
                  "https://www.linkedin.com/company/mycollegementor",
                  "https://www.youtube.com/@college.mentor"
                ]
              }
            })}
          </script>
        </Helmet>
      </HelmetProvider>
      <Banner data={allSectionData?.sections} />
      <CollegeMentorScholarshipAd />
      <div className="md:py-4">
        <div className="grid grid-flow-row gap-0 grid-rows">
          <ArticlesAndLatestNews
            data={allSectionData?.sections}
            title="Articles & Blogs"
            secondaryTitle="Latest News"
          />
          <ExamsAndAdmissions
            examsAndAdmissionsResponse={allSectionData?.sections}
          />
          <CollegeMentorScholarshipAd />

          <CollegeAndScholarship
            collegeAndScholarshipResponse={allSectionData?.sections}
          />
          <PremiumBlogs premiumBlogs={allSectionData?.sections} />
          <CareersAndCourses
            careersAndCoursesResponse={allSectionData?.sections}
          />
          <StudyAbroad studyAbroadResponse={allSectionData?.sections} />
          <WebAndVisualStories
            webAndVisualStoriesResponse={allSectionData?.sections}
          />
          <PopularMentors
            popularMentorResponse={
              allSectionData?.sections[0]["Popular Authors"]
            }
          />
        </div>
      </div>
    </>
  );
};

export default BlogsAndArticlesMain;
