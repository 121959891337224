import React, { useRef } from "react";
import html2canvas from "html2canvas"; // Kept for potential future use, but not needed here
import certificateImage from "../../college/assets/ParticipeCertificate.png";
import signatureImage from "../../college/assets/RajashekarSignature.png";
import participationContent from "../../college/assets/participateCerContent.png";
import moment from "moment";
import "./Certificate.scss";
interface CertificateGeneratorProps {
  defaultName?: string;
  defaultPresentedTo?: string;
  defaultContent?: string;
  defaultFinancialAward?: string;
  defaultCertificateId?: string;
  defaultDate?: string;
  certificateImageSrc?: string;
}

const ParticipationCertificate: React.FC<CertificateGeneratorProps> = ({
  defaultName = "M.RAJA SHEKAR",
  defaultPresentedTo = "Mr. RajaShekar",
  defaultCertificateId = "CMST-67890",
  defaultDate = moment().format("MMMM D, YYYY"),
  certificateImageSrc = certificateImage,
}) => {
  const certificateRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="certificate-wrapper" ref={certificateRef}>
      <img
        src={certificateImageSrc}
        alt="Certificate"
        className="certificate-image"
      />
      <div className="certificate-text">
        <span className="presented-to">{defaultPresentedTo}</span>
        <img
          src={participationContent}
          alt="content"
          className="image-content"
        />
        {signatureImage && (
          <img src={signatureImage} alt="Signature" className="signature" />
        )}
        <span className="certificate-id">{defaultCertificateId}</span>
        <span className="date">{defaultDate}</span>
      </div>
    </div>
  );
};

export default ParticipationCertificate;
