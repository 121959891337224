import { useSelector } from "react-redux";
import FAQV2 from "../../../../components/FAQV2";
import { RootState } from "../../../../store/store";

const KnowledgeHub = () => {
  const faqs = useSelector((state: RootState) => state.educationLoan.faqs);

  return (
    <div className="bg-pink-light p-8">
      <h3 className="text-4xl font-bold text-blue mb-4">
        Knowledge Hub (FAQ’S)
      </h3>
      <FAQV2 questionsAndAnswers={faqs} />
    </div>
  );
};

export default KnowledgeHub;
