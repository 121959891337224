import React from "react";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";

type KnowMoreProps = {
  details: {
    id: number;
    applicationFormId: {
      id: number;
      category: string;
      title: string;
      name: string;
      bulletPoints: string;
      bannerImage: string;
      iconImage?: string | null;
      applyUrl: string;
      state: {
        stateId: number;
        stateName: string;
      };
    };
    established: string;
    exam: string;
    courses: string;
    instituteType: string;
    gender: string;
    facultyCount: number;
    campusSize: string;
  } | null;
  onClose: () => void;
};

const KnowMoreModal: React.FC<KnowMoreProps> = ({ details, onClose }) => {
  if (!details) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4">
      <div className="bg-white w-full max-w-40rem rounded-lg shadow-2xl p-6 border border-blue relative">
        <button
          className="absolute top-3 right-4 text-xl text-gray-600 hover:text-black"
          onClick={onClose}
        >
          ✕
        </button>

        <h4 className="text-2xl font-bold text-blue text-center">
          {details.applicationFormId.title}
        </h4>
        <hr className="border-t border-green-400 mb-4" />

        <div className="text-gray-700 text-sm">
          <div className="grid grid-cols-2 gap-y-2">
            <div className="px-4 py-2 font-semibold border-b border-[#7C7C7C]">
              Established
            </div>
            <div className="px-4 py-2 border-b border-[#7C7C7C]">
              {details.established}
            </div>

            <div className="px-4 py-2 font-semibold border-b border-[#7C7C7C]">
              Exam
            </div>
            <div className="px-4 py-2 border-b border-[#7C7C7C]">
              {details.exam}
            </div>

            <div className="px-4 py-2 font-semibold border-b border-[#7C7C7C]">
              Courses
            </div>
            <div className="px-4 py-2 border-b border-[#7C7C7C]">
              {details.courses}
            </div>

            <div className="px-4 py-2 font-semibold border-b border-[#7C7C7C]">
              Institute Type
            </div>
            <div className="px-4 py-2 border-b border-[#7C7C7C]">
              {details.instituteType}
            </div>

            <div className="px-4 py-2 font-semibold border-b border-[#7C7C7C]">
              Gender
            </div>
            <div className="px-4 py-2 border-b border-[#7C7C7C]">
              {details.gender}
            </div>

            <div className="px-4 py-2 font-semibold border-b border-[#7C7C7C]">
              Faculty Count
            </div>
            <div className="px-4 py-2 border-b border-[#7C7C7C]">
              {details.facultyCount}
            </div>

            <div className="px-4 py-2 border-b font-semibold border-[#7C7C7C]">
              Campus Size
            </div>
            <div className="px-4 border-b py-2 border-[#7C7C7C]">
              {details.campusSize}
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-center">
        <LeadGenCtaBtn
          pageName="home-landing"
          ctaName="dream-colleges-apply-now"
          className="bg-green text-white py-2 px-4 rounded-md hover:bg-blue hover:text-white transition-all"
        >
         Apply 
        </LeadGenCtaBtn>
        </div>
      </div>
    </div>
  );
};

export default KnowMoreModal;
