import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavigationTabs from "./components/NavigationTabs";
import Loader from "../../components/Loader/Loader";
import config from "../../util/config";
import EducationBanner from "./components/Banner";
import "./EducationBoardsDetailsStyles.scss";

const EducationBoardsDetails: React.FC = () => {
  const { boardName } = useParams<{ boardName: string }>();
  const [tabs, setTabs] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTabs = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${config.apiUrl}/api/educationBoard/all-tabs`
        );
        const data = await response.json();
        setTabs(data.data || []);
      } catch (error) {
        console.error("Error fetching tabs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTabs();
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <EducationBanner
        title="CBSE Class 12 Exam 2025: CBSE Class 12 2025 Date Sheet (Out), Admit Card, Exam Pattern, Syllabus, Result, PYQP, Sample Papers"
        description="Get the latest updates on CBSE Class 12 2025 including important dates, admit card download, syllabus, and previous year question papers."
      />
      <NavigationTabs tabs={tabs} boardName={boardName || ""} />
    </>
  );
};

export default EducationBoardsDetails;
