import React from "react";

import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";

const Banner = () => {
  const isMdDevice = window.innerWidth >= 768;
  return (
    <div
      style={{
        background: `linear-gradient(${
          isMdDevice ? 90 : 180
        }deg, #0B3D8C 0.11%, #107CC4 54.49%, rgba(6, 175, 169, 0.745055) 83.43%)`,
      }}
    >
      <div className="container max-w-screen-2xl text-white py-8 lg:py-12">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="relative w-full md:w-1/2 flex flex-col justify-center lg:pt-12 md:pt-0">
            <div className="mb-auto">
              <Breadcrumbs
                links={[
                  { name: "Home", path: "/exams" },
                  {
                    name: "List of Exams in India",
                    path: "/list-of-exams-in-india",
                  },
                ]}
              />
            </div>
            <h1 className="text-3xl lg:text-4xl md:text-5xl mt-6 lg:mt-12 md:mt-0 font-bold line-clamp-2">
              List of Exams in India
            </h1>
            <p className="text-lg md:text-xl mt-4 mb-auto line-clamp-3">
              India offers a diverse range of exams across various educational
              levels, from undergraduate to postgraduate and doctoral programs.
            </p>
          </div>
          <div className="w-full md:w-1/2 flex items-center justify-center">
            <img
              src="/images/exam-intermediate-banner-image.png"
              alt="banner imag"
              className="w-full md:w-2/3 aspect-square object-cover rounded-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
