import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ClgLatestUpdates from "../courses-fees/ClgLatestUpdates";
import SimilarEngineeringColleges from "../SimilarEngineeringColleges";
import UniversityMentorHelp from "../UniversityMentorHelp";
import Loader from "../../Loader/Loader";
import config from "../../../util/config";
import ApplicationForms from "../sidebar/ApplicationForms";
import CoursesList from "../sidebar/CoursesList";
import type { SVGProps } from "react";
import axios from "axios";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";
import {BaseURL} from "../../../services/api";

export function EpRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        fill="currentColor"
        d="M754.752 480H160a32 32 0 1 0 0 64h594.752L521.344 777.344a32 32 0 0 0 45.312 45.312l288-288a32 32 0 0 0 0-45.312l-288-288a32 32 0 1 0-45.312 45.312z"
      ></path>
    </svg>
  );
}

export function MaterialSymbolsLightClose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"
      ></path>
    </svg>
  );
}

interface CoursesFeesTabProps {
  CoursesFees?: {
    key_0?: {
      title: string;
      description: string;
    };
    similarColleges?: Array<{
      image: string;
      name: string;
      location: string;
      title: string;
    }>;
  };
  collegeId: number | null;
}

const CoursesFeesTab: React.FC<CoursesFeesTabProps> = ({
  CoursesFees,
  collegeId,
}) => {
  const [allCourses, setAllCourses] = useState<any[]>([]);
  const [filteredCourses, setFilteredCourses] = useState<any[]>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<any>({});
  const [wishlist, setWishlist] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState("");

  const [openFilters, setOpenFilters] = useState<{ [key: string]: boolean }>(
    {}
  );
  const dropdownRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    fetchCoursesAndFilters();
  }, [currentPage, selectedFilters]);
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      Object.keys(openFilters).forEach((key) => {
        if (
          openFilters[key] &&
          dropdownRefs.current[key] &&
          !dropdownRefs.current[key]?.contains(event.target as Node)
        ) {
          setOpenFilters((prev) => ({
            ...prev,
            [key]: false,
          }));
        }
      });
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [openFilters]);
  const fetchCoursesAndFilters = async () => {
    if (!collegeId) {
      console.error("College ID is missing.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${config.apiUrl}/api/college/college/collegeRelatedCourse`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            degreeLevel: selectedFilters.degreeLevel?.[0] || "",
            degreeType: selectedFilters.degreeType?.[0] || "",
            mode: selectedFilters.mode?.[0] || "",
            id: selectedFilters.id?.[0] || "",
            streamId: selectedFilters.id?.[0] || "",
            collegeId: collegeId,
            page: currentPage,
            size: pageSize,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch course data.");
      }

      const data = await response.json();

      const mappedCourses = data.data?.data.map((course: any) => ({
        courseName: course.courseName,
        courseTitle: course.courseTitle,
        degreeLevel: course.degreeLevel,
        degreeType: course.degreeType,
        duration: course.duration,
        fees: course.fees,
        id: course.id,
        focus: course.focus,
        mode: course.mode,
      }));

      const mappedFilters = data.data?.filterData.map((filter: any) => ({
        categoryName: filter.categoryName,
        categoryKey: filter.categoryKey,
        options: filter.options.map((option: any) => ({
          name: option.name,
          count: option.count,
          key: option.key,
        })),
      }));

      setAllCourses(mappedCourses || []);
      setFilteredCourses(mappedCourses || []);
      setFilters(mappedFilters || []);
      setTotalCount(data.data?.totalCount || 0);
    } catch (error) {
      console.error("Error fetching courses and filters:", error);
      setAllCourses([]);
      setFilteredCourses([]);
      setFilters([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query); // Update input value, but don’t trigger search immediately
  };
  const handleSearchSubmit = () => {
    setCurrentPage(0); // Reset pagination

    if (searchQuery.trim() === "") {
      setFilteredCourses(allCourses); // Show all results if search is empty
      setTotalCount(allCourses.length);
    } else {
      applyFilters(searchQuery); // Apply filters only when search is triggered
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearchSubmit();
    }
  };
  const handleClearSearch = () => {
    setSearchQuery(""); // Clear search input
    setCurrentPage(0); // Reset pagination
    setFilteredCourses(allCourses); // Show all courses
    setTotalCount(allCourses.length); // Reset total count
  };
  const handleCheckboxChange = (categoryKey: string, optionKey: string) => {
    const updatedFilters = { ...selectedFilters };

    updatedFilters[categoryKey] = [optionKey];

    setSelectedFilters(updatedFilters);
    applyFilters(searchQuery, updatedFilters);
  };
  const applyFilters = (query: string, filters = selectedFilters) => {
    let filtered = allCourses.filter((course) => {
      const matchesQuery = query
        ? course.courseTitle.toLowerCase().includes(query.toLowerCase())
        : true;

      const matchesFilters = Object.keys(filters).every((filterKey) => {
        const courseValue = course[filterKey];
        return filters[filterKey]?.includes(courseValue);
      });

      return matchesQuery && matchesFilters;
    });

    setTotalCount(filtered.length);
    setCurrentPage(0);
    setFilteredCourses(filtered);
  };

  const toggleFilterDropdown = (categoryKey: string) => {
    setOpenFilters((prev) => ({
      ...prev,
      [categoryKey]: !prev[categoryKey],
    }));
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      // Handle user not logged in
      return;
    }

    getUserWishList();
  }, []);

  function getUserWishList() {
    const userId = JSON.parse(localStorage.getItem("user") ?? "{}")?.userId;
    if (userId) {
      const accessToken = localStorage.getItem("accessToken");
      BaseURL.get(`/api/wishlist/category/${userId}?category=college`, {
        headers: {
          Authorization: accessToken ? `${accessToken}` : "",
        },
      })
        .then((response) => {
          setWishlist(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching wishlist data:", error);
        });
    }
  }

  const handleWishlistToggle = async (id: number, courseTitle: string) => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/secure/login", { state: { sourcePagePath: pathname } });
      return;
    }

    const userId = JSON.parse(user).userId;

    try {
      const apiUrl = wishlist.includes(id)
        ? "/api/wishlist/remove"
        : "/api/wishlist/add";
      console.log("Payload:", {
        categoryName: courseTitle,
        categoryId: id,
        userId,
      });

      const response = await BaseURL.post(
        apiUrl,
        {
          categoryName: courseTitle,
          categoryId: id,
          userId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.data) {
        setWishlist((prevWishlist) =>
          wishlist.includes(id)
            ? prevWishlist.filter((id) => id !== id)
            : [...prevWishlist, id]
        );
      }
    } catch (error: any) {
      console.error(
        "Error toggling wishlist:",
        error.response || error.message
      );
      alert(
        `Failed to update wishlist: ${
          error.response?.data?.message || "Unknown error occurred"
        }`
      );
    }
  };

  const handleCourseSelect = (id: number) => {
    localStorage.setItem("selectedCourse", JSON.stringify(id));
  };

  const handlePagination = (
    direction: "next" | "prev",
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (direction === "next" && (currentPage + 1) * pageSize < totalCount) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (direction === "prev" && currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleClearFilters = () => {
    setSelectedFilters({});
    setSearchQuery("");
    setCurrentPage(0);
    setFilteredCourses(allCourses);
    setTotalCount(allCourses.length);
  };

  if (loading) {
    return <Loader />;
  }

  const { key_0, similarColleges } = CoursesFees || {};

  return (
    <>
      <div className="py-10 bg-[#f4f4f4]">
        <div className="container mx-auto">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-9/12">
              <div className="flex flex-col gap-6">
                {key_0 && (
                  <ClgLatestUpdates
                    title={key_0.title}
                    description={key_0.description}
                  />
                )}

                <div className="flex items-center bg-white border border-[#A3A2AC] rounded-full shadow-md px-4 py-2 w-full lg:w-[50%] relative">
                  <span
                    className="material-icons text-gray-500"
                    onClick={handleSearchSubmit}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M9.5 16q-2.725 0-4.612-1.888T3 9.5t1.888-4.612T9.5 3t4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l5.6 5.6q.275.275.275.7t-.275.7t-.7.275t-.7-.275l-5.6-5.6q-.75.6-1.725.95T9.5 16m0-2q1.875 0 3.188-1.312T14 9.5t-1.312-3.187T9.5 5T6.313 6.313T5 9.5t1.313 3.188T9.5 14"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    placeholder="Search by Courses, Specialization"
                    className="flex-1 ml-2 focus:outline-none text-sm"
                    value={searchQuery}
                    onChange={(e) => handleSearchChange(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearchSubmit();
                      }
                    }}
                  />
                  {searchQuery && (
                    <button
                      className="absolute right-3 text-black hover:text-blue bg-none border-none"
                      onClick={handleClearSearch}
                    >
                      <MaterialSymbolsLightClose />
                    </button>
                  )}
                </div>
                <div className="flex flex-col md:flex-row justify-between gap-4 max-md:items-start">
                  <div className="flex flex-wrap gap-4 bg-gradient-to-r from-blue to-green text-white rounded-lg shadow-lg px-4 py-3 w-full lg:w-[80%] justify-between">
                    {filters.map((filter: any, index: number) => (
                      <div
                        key={filter.categoryKey}
                        className="relative"
                        ref={(el) =>
                          (dropdownRefs.current[filter.categoryKey] = el)
                        }
                      >
                        <button
                          className="text-sm font-semibold flex items-center capitalize justify-between"
                          onClick={() =>
                            toggleFilterDropdown(filter.categoryKey)
                          }
                        >
                          {filter.categoryName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              d="M4.5 6L8 9.5L11.5 6"
                            />
                          </svg>
                        </button>
                        {openFilters[filter.categoryKey] && (
                          <div className="absolute bg-white text-black shadow-lg rounded mt-2 p-2 w-64 md:w-60 h-52 overflow-y-auto overflow-x-hidden">
                            {filter.options.length > 6 && (
                              <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="w-full p-1 border border-gray-500 rounded mb-2"
                              />
                            )}

                            {filter.options
                              .filter((option: { name: string }) =>
                                option.name
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              )
                              .sort(
                                (a: { name: string }, b: { name: string }) => {
                                  const aMatches = a.name
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                    ? 1
                                    : 0;
                                  const bMatches = b.name
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                    ? 1
                                    : 0;
                                  return bMatches - aMatches;
                                }
                              )
                              .map((option: any) => (
                                <label
                                  key={option.key}
                                  className={`flex gap-1 items-baseline ${
                                    selectedFilters[
                                      filter.categoryKey
                                    ]?.includes(option.key)
                                      ? "bg-blue-100"
                                      : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name={filter.categoryKey}
                                    checked={selectedFilters[
                                      filter.categoryKey
                                    ]?.includes(option.key)}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        filter.categoryKey,
                                        option.key
                                      )
                                    }
                                  />
                                  {option.name} ({option.count})
                                </label>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <button
                    className="text-red-500 text-sm"
                    onClick={handleClearFilters}
                  >
                    Clear Filters
                  </button>
                </div>

                <div id="courseResults" className="flex flex-col gap-6">
                  <ul className="space-y-4 list-none">
                    {filteredCourses.map((course: any) => (
                      <li
                        key={course.courseName}
                        className="border p-4 rounded-lg shadow-md bg-white flex flex-col md:flex-row w-full justify-between gap-8"
                        onClick={() => handleCourseSelect(course.id)}
                      >
                        <div className="flex flex-col w-full lg:w-3/4 gap-3">
                          <div className="flex gap-4 justify-between ">
                            <h3 className="font-bold text-xl text-blue">
                              {course.courseTitle}
                            </h3>
                            <div className="flex md:hidden">
                              <button
                                onClick={() =>
                                  handleWishlistToggle(
                                    course.id,
                                    course.courseTitle
                                  )
                                }
                                className="focus:outline-none cursor-pointer"
                              >
                                <svg
                                  width="14px"
                                  height="13px"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={`${
                                    wishlist.includes(course.id)
                                      ? "fill-current text-[#DC0D0D] duration-75"
                                      : "fill-current text-white"
                                  }`}
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.50001 2.60272C6.10047 0.895531 3.76182 0.367937 2.00829 1.93121C0.25475 3.49448 0.00787601 6.10816 1.38494 7.95707C2.52988 9.49425 5.99485 12.7364 7.13048 13.7858C7.25749 13.9032 7.32104 13.9619 7.39516 13.9849C7.45979 14.005 7.53057 14.005 7.59528 13.9849C7.66941 13.9619 7.73287 13.9032 7.85996 13.7858C8.9956 12.7364 12.4605 9.49425 13.6055 7.95707C14.9825 6.10816 14.7658 3.47803 12.9821 1.93121C11.1984 0.384382 8.89954 0.895531 7.50001 2.60272Z"
                                    stroke={`${
                                      wishlist.includes(course.id)
                                        ? "#DC0D0D"
                                        : "black"
                                    }`}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <ul className="list-none grid grid-cols-1 lg:grid-cols-2 gap-3">
                            <li>
                              <strong>Duration:</strong> {course.duration}
                            </li>
                            <li>
                              <strong>Mode:</strong> {course.mode}
                            </li>
                            <li>
                              <strong>Degree Level:</strong>{" "}
                              {course.degreeLevel}
                            </li>
                            <li>
                              <strong>Fees:</strong> {course.fees}
                            </li>
                          </ul>
                          {/* <p>
                            <strong>Focus:</strong> {course.focus}
                          </p> */}
                        </div>
                        <div className="flex md:flex-col justify-between md:items-end gap-3 w-full lg:w-1/4">
                          <div className="flex flex-col gap-3 md:items-end">
                            <div className="hidden md:flex">
                              <button
                                onClick={() =>
                                  handleWishlistToggle(
                                    course.id,
                                    course.courseTitle
                                  )
                                }
                                className="focus:outline-none cursor-pointer"
                              >
                                <svg
                                  width="14px"
                                  height="13px"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={`${
                                    wishlist.includes(course.id)
                                      ? "fill-current text-[#DC0D0D] duration-75"
                                      : "fill-current text-white"
                                  }`}
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.50001 2.60272C6.10047 0.895531 3.76182 0.367937 2.00829 1.93121C0.25475 3.49448 0.00787601 6.10816 1.38494 7.95707C2.52988 9.49425 5.99485 12.7364 7.13048 13.7858C7.25749 13.9032 7.32104 13.9619 7.39516 13.9849C7.45979 14.005 7.53057 14.005 7.59528 13.9849C7.66941 13.9619 7.73287 13.9032 7.85996 13.7858C8.9956 12.7364 12.4605 9.49425 13.6055 7.95707C14.9825 6.10816 14.7658 3.47803 12.9821 1.93121C11.1984 0.384382 8.89954 0.895531 7.50001 2.60272Z"
                                    stroke={`${
                                      wishlist.includes(course.id)
                                        ? "#DC0D0D"
                                        : "black"
                                    }`}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div className="flex md:flex-col gap-5 items-end">
                              <LeadGenCtaBtn
                                className="bg-green text-white px-4 py-2 rounded-md hover:bg-green flex gap-2 items-center"
                                pageName="Course Page"
                                courseName={course.courseTitle}
                                ctaName="dream-colleges-apply-now"
                              >
                                Apply Now <EpRight />
                              </LeadGenCtaBtn>
                              <Link
                                to="/mentors"
                                className="bg-green text-white px-4 py-2 rounded-md hover:bg-green flex gap-2 items-center"
                              >
                                Talk to Mentor <EpRight />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <div className="flex justify-center gap-3">
                    <button
                      onClick={(event) => handlePagination("prev", event)}
                      disabled={
                        currentPage === 0 || filteredCourses.length === 0
                      }
                      className={`bg-white border border-black px-4 py-2 rounded-full w-12 h-12 ${
                        currentPage === 0 || filteredCourses.length === 0
                          ? "cursor-not-allowed opacity-50"
                          : "hover:bg-gray-300"
                      }`}
                    >
                      <EpRight className="rotate-180" />
                    </button>
                    <button
                      onClick={(event) => handlePagination("next", event)}
                      disabled={
                        (currentPage + 1) * pageSize >= totalCount ||
                        filteredCourses.length === 0
                      }
                      className={`bg-white border border-black px-4 py-2 rounded-full w-12 h-12 ${
                        (currentPage + 1) * pageSize >= totalCount ||
                        filteredCourses.length === 0
                          ? "cursor-not-allowed opacity-50"
                          : "hover:bg-gray-300"
                      }`}
                    >
                      <EpRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/4 hidden lg:block">
              <ApplicationForms />
              <CoursesList />
            </div>
          </div>
        </div>
      </div>

      {similarColleges && similarColleges.length > 0 && (
        <SimilarEngineeringColleges colleges={similarColleges} />
      )}
    </>
  );
};

export default CoursesFeesTab;
