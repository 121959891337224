import React, { useEffect, useState } from "react";
import axios from "axios";

import Preparation from "./components/Preparation";
import Predictor from "./components/Predictor";
import Mocktests from "./components/Mocktests";
import Examjourneys from "./components/Examjourneys";
import Examalerts from "./components/Examalerts";
import Subscribe from "./components/Subscribe";
import Popularexams from "./components/popularexams/Popularexams";
import Loader from "../../components/Loader/Loader";
import SamplePapers from "./components/SamplePapers";
import PrepareSuccess from "./components/PrepareSuccess";
import ExploreRankPredictor from "./components/ExploreRankPredictor";
import ExamBanner from "./components/banner/Banner";
import { FeaturedExams } from "./components/FeaturedExams";
import { ExploreMockTestSeries } from "./components/ExploreMockTestSeries";
import ExamDownloads from "./components/ExamDownloads";
import DreamersToAchiever from "./components/dreamersachiever/DreamersToAchiever";

import NewsAlerts from "./components/NewsAlerts";
import ExcelinCompetitiveExams from "./components/competitiveexams/ExcelinCompetitiveExams";
import ExploreTopAdmissionMentors from "./components/exploretopadmission/ExploreTopAdmissionMentors";
import { ExamResources } from "./components/exam-resources/ExamResources";
import config from "../../util/config";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import { ExamLandingMockResponse } from "./mock/mockData";
export const Exams = () => {
  const [examData, setExamData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          config.apiUrl + "/api/exam/getexamLandingPage"
        );
        setExamData(response.data.data);

        setLoading(false);
      } catch (error) {
        // setError(error);
        setLoading(false);
      }
    };

    fetchData();
    document.title =
      "College Mentor | Prepare JEE, NEET, BITSAT and more exams";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Prepare for JEE, NEET, BITSAT and more exams with College Mentor. Access previous papers, syllabus details, eligibility info, and personalized study plans."
      );
    return () => {};
  }, []);

  if (loading) {
    return <Loader />;
  }
  const examBannerData = {
    examBanner: ExamLandingMockResponse.examBanner,
  };
  const featuredExamsData = {
    featuredExams: ExamLandingMockResponse.featuredExams,
  };

  const downloadsData = {
    downloads: ExamLandingMockResponse.downloads,
  };
  return (
    <>
      {" "}
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentor | Prepare JEE, NEET, BITSAT and more exams
          </title>
          <meta
            name="description"
            content="Prepare for JEE, NEET, BITSAT and more exams with College Mentor. Access previous papers, syllabus details, eligibility info, and personalized study plans."
          />
          <meta
            name="keywords"
            content="JEE preparation, NEET mock tests, BITSAT study materials, CLAT exam resources, NIFT test papers, CUET syllabus, online mock tests, exam eligibility, study plans, AI-driven tests, JEE Mains, NEET, syllabus, eligibility, entrance exams, important exam dates, mock tests, past papers."
          />
          <link rel="canonical" href={`${config.apiUrl}/exams`} />

          <meta
            property="og:title"
            content="College Mentor | Prepare JEE, NEET, BITSAT and more exams"
          />
          <meta
            property="og:description"
            content="Prepare for JEE, NEET, BITSAT and more exams with College Mentor. Access previous papers, syllabus details, eligibility info, and personalized study plans."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | Prepare JEE, NEET, BITSAT and more exams"
          />
          <meta
            name="twitter:description"
            content="Prepare for JEE, NEET, BITSAT and more exams with College Mentor. Access previous papers, syllabus details, eligibility info, and personalized study plans."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
          
          {/* Schema Markup for Exams Page */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "CollectionPage",
              "name": "College Mentor Exams",
              "url": `${config.apiUrl}/exams`,
              "description": "College Mentor Exams - Find details about various competitive exams and entrance tests, including eligibility criteria, application deadlines, and syllabus information.",
              "about": [
                {
                  "@type": "Thing",
                  "name": "College Entrance Exams"
                },
                {
                  "@type": "Thing",
                  "name": "Competitive Entrance Exams"
                },
                {
                  "@type": "Thing",
                  "name": "International Entrance Exams"
                },
                {
                  "@type": "Thing",
                  "name": "Scholarship Exams"
                }
              ],
              "hasPart": [
                {
                  "@type": "WebPage",
                  "name": "Upcoming College Mentor Scholarship Test | Empowering +2 students with ₹1 Crore in scholarships",
                  "url": `${config.apiUrl}/college-mentor-scholarship`
                }
              ],
              "publisher": {
                "@type": "EducationalOrganization",
                "name": "College Mentor",
                "url": config.apiUrl,
                "logo": `${config.apiUrl}/static/media/College%20Mentor_Final_Logo-01.ae0ff9c1ffa9fc9fac3e.png`,
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+91-7997166666",
                  "contactType": "customer support",
                  "availableLanguage": ["English", "Hindi", "Telugu"]
                }
              }
            })}
          </script>
        </Helmet>
      </HelmetProvider>
      <div>
        <ExamBanner data={examBannerData} />
        <FeaturedExams data={featuredExamsData} />
        <ExcelinCompetitiveExams />
        <NewsAlerts />
        <PrepareSuccess />
        <ExploreRankPredictor />
        {/* <ExploreMockTestSeries data={examData} /> */}
        <ExploreTopAdmissionMentors data={examData} />
        {/* {data?.mockTest && <Mocktests data={data.mockTest} />} */}
        <ExamDownloads data={downloadsData} />
        <DreamersToAchiever />
        <ExamResources />
        {/* <Predictor /> */}
        {/* <Preparation /> */}
        {/* <Popularexams /> */}

        {/* <Examjourneys /> */}
        {/* <Examalerts /> */}
        {/* <SamplePapers /> */}
        {/* <Subscribe /> */}
      </div>
    </>
  );
};
