import { SVGProps } from "react";
import "./SubscribeNow.scss";
import LeadGenCtaBtn from "../../features/lead-generation/LeadGenCtaBtn";
import { Link } from "react-router-dom";

const takeAFreeTestContent = [
  {
    title: "College Mentor Scholarship Test 1 Cr",
    button: "Apply Now",
  },
];
export function MaterialSymbolsLightClose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"
      ></path>
    </svg>
  );
}

const SubscribeNow = () => {
  // const [isPopupVisible, setIsPopupVisible] = useState(false);
  // const [currentUser, setCurrentUser] = useState<string | null>(
  //   localStorage.getItem("user")
  // );
  // const [leadGenerationPopupIsOpen, setLeadGenerationPopupIsOpen] =
  //   useState(false);
  // const { pathname } = useLocation()
  // const user = localStorage.getItem("user");
  // const navigate = useNavigate();

  return (
    <div className="flex py-8 my-10  max-sm:my-4 subscribe-now-section bg-gradient-to-r from-blue-900 to-teal-500">
      <div className="container px-4 mx-auto">
        <div className="flex flex-row w-full">
          {takeAFreeTestContent.map((content, index) => (
            <div
              key={index}
              className="relative flex items-center justify-between w-full gap-4 md:flex-row"
            >
              <div className="flex flex-row items-center gap-3">
                <div className="p-2 magazine-box">
                  <span className="text-xs text-white">
                    <strong>College Mentor</strong> <br /> Magazine PRO
                  </span>
                  <img
                    src="https://cdncollegementor.blob.core.windows.net/careers/careerWebp/subsribeNow/college-mentor-scholarship-logo.webp"
                    alt="magazinePro"
                    className="mb-2 bg-white"
                  />
                  <Link
                    to="https://cocubes.in/collegementor"
                    target="_blank"
                    className="flex flex-row items-center justify-center gap-2 mx-auto subscribe-btn white-color sm:text-xs sm:w-48"
                  >
                    Exam Live
                  </Link>
                </div>
                <h3 className="m-0 ml-40 text-sm font-bold text-white subscribe-title md:text-lg lg:text-2xl">
                  {content.title}
                </h3>
              </div>
              <div className="flex flex-col">
                <Link
                  to="https://cocubes.in/collegementor"
                  target="_blank"
                  className="flex flex-row items-center justify-center gap-2 subscribe-now white-color btn-styles sm:text-xs sm:w-48 button-styles"
                >
                  Exam Live
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscribeNow;
