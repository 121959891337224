import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/bundle";

const StudentTestimonialsContent = [
  {
    title: "Student testimonials",
    description:
      "Get honest feedback from the students who’ve taken the courses you’re considering. Our mentors can share their experiences to help you choose the right path.",
    studentTestimonials: [
      {
        name: "Dipankar Roy",
        testimonial:
          "The College Mentor course was a game-changer! It helped me discover my true passion. Highly recommend!",
        profileImg:
          "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/testimonial/boy1.svg",
        designation: "",
        color: "#000",
      },
      {
        name: "Ramya Naidu",
        testimonial:
          "The quiz was quick and insightful! I found the perfect major that aligns with my interests.",
        profileImg:
          "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/webp-course-landing/student-testimonials/girl1.webp",
        designation: "",
        color: "#FFD540",
      },
      {
        name: "Ananya Sharma",
        testimonial:
          "Thanks to College Mentor, I now have a clear path for my future. The course exceeded my expectations!",
        profileImg:
          "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/testimonial/girl2.svg",
        designation: "",
        color: "#173CBA",
      },
      {
        name: "Arjun Reddy",
        testimonial:
          "The quiz guided me to options I never considered. It was a fun and enlightening experience!",
        profileImg:
          "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/Course%20Landing%20others/testimonial/boy2.svg",
        designation: "",
        color: "#00CC99",
      },
    ],
  },
];

const isLargeDevice = window.innerWidth >= 1024;

const StudentTestimonials: React.FC = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const activeTestimonial =
    StudentTestimonialsContent[0].studentTestimonials[activeItemIndex];

  const radius = 12; // radius in rem
  const startAngle = isLargeDevice ? -120 : -150; // starting angle in degrees
  const angleIncrement = isLargeDevice ? -40 : 40; // degrees between each image

  const calculateStyle = (index: number) => {
    const angle = startAngle + index * angleIncrement;
    return {
      transform: `rotate(${angle}deg) translate(${radius}rem) rotate(${-angle}deg) translate(-50%, -50%)`,
      transformOrigin: "center center",
    };
  };

  const goToPreviousItem = () => {
    setActiveItemIndex((prev) => (prev - 1 + 4) % 4);
  };

  const goToNextItem = () => {
    setActiveItemIndex((prev) => (prev + 1 + 4) % 4);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoPlay) setActiveItemIndex((prev) => (prev + 1) % 4);
    }, 5000);

    return () => clearInterval(interval);
  }, [autoPlay, setActiveItemIndex]);
  const [isPrevHovered, setIsPrevHovered] = useState(false);

  // Function to handle mouse enter event
  const handlePrevMouseEnter = () => setIsPrevHovered(true);

  // Function to handle mouse leave event
  const handlePrevMouseLeave = () => setIsPrevHovered(false);
  return (
    <div className="relative bg-orange-light2 text-black font-open-sans py-12 lg:py-24 max-sm:py-6">
      <div className="container max-w-screen-2xl flex flex-col lg:flex-row-reverse justify-end items-center gap-16 lg:gap-6">
        <div className="w-full lg:w-1/2 flex gap-8 items-start">
          <svg
            width="48"
            height="36"
            viewBox="0 0 48 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hidden lg:block h-12 w-auto mt-0.5"
          >
            <path
              d="M18.0469 0.311523C8.35313 0.311523 0.421875 8.24277 0.421875 17.9365V35.5615H18.0469V17.9365H6.29688C6.29688 11.4153 11.5256 6.18652 18.0469 6.18652V0.311523ZM47.4219 0.311523C37.7281 0.311523 29.7969 8.24277 29.7969 17.9365V35.5615H47.4219V17.9365H35.6719C35.6719 11.4153 40.9006 6.18652 47.4219 6.18652V0.311523Z"
              fill={activeTestimonial.color}
            />
          </svg>

          <div className="flex flex-col">
            <div className="flex gap-4 items-start">
              <svg
                width="48"
                height="36"
                viewBox="0 0 48 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="lg:hidden h-6 w-auto mt-1"
              >
                <path
                  d="M18.0469 0.311523C8.35313 0.311523 0.421875 8.24277 0.421875 17.9365V35.5615H18.0469V17.9365H6.29688C6.29688 11.4153 11.5256 6.18652 18.0469 6.18652V0.311523ZM47.4219 0.311523C37.7281 0.311523 29.7969 8.24277 29.7969 17.9365V35.5615H47.4219V17.9365H35.6719C35.6719 11.4153 40.9006 6.18652 47.4219 6.18652V0.311523Z"
                  fill={activeTestimonial.color}
                />
              </svg>
              <h2 className="text-3xl lg:text-5xl font-bold font-roboto">
                Student Testimonial
              </h2>
            </div>
            <p className="text-base lg:text-xl text-grey mt-4">
              {activeTestimonial.testimonial}
            </p>
            <div className="flex flex-col items-center self-end mt-6">
              <div className="text-sm">{activeTestimonial.name}</div>
              <div className="text-xs">{activeTestimonial.designation}</div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3 flex items-center justify-center relative">
          <img
            src={activeTestimonial.profileImg}
            className="w-56 lg:w-64 my-12 border-[0.5rem] border-white rounded-full"
            alt="profileImg"
          />
          <div
            className="absolute w-96 h-96 rounded-full border-[3px] border-green border-dashed"
            style={{
              clipPath: isLargeDevice ? "inset(0 75% 0 0)" : "inset(0 0 75% 0)",
            }}
          ></div>
          {StudentTestimonialsContent[0].studentTestimonials.map(
            (student, index) => (
              <img
                src={student.profileImg}
                alt="profileImg"
                className={`h-12 w-12 lg:h-15 lg:w-15 absolute top-1/2 left-1/2 ${
                  index === activeItemIndex
                    ? "border-4 border-green rounded-full"
                    : ""
                }`}
                style={calculateStyle(index)}
              />
            )
          )}
        </div>
      </div>
      <div
        className={`flex gap-10 absolute bottom-0 left-1/2 -translate-x-1/2 -translate-y-6 lg:-translate-y-12`}
        onMouseEnter={() => setAutoPlay(false)}
        onMouseLeave={() => setAutoPlay(true)}
      >
        <button
          className="p-4 border border-black hover:border-blue rounded-full bg-white hover:bg-blue group"
          onClick={goToPreviousItem}
          onMouseEnter={handlePrevMouseEnter}
          onMouseLeave={handlePrevMouseLeave}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 11H9L12.29 7.71C12.3837 7.61703 12.4581 7.50643 12.5089 7.38457C12.5597 7.26272 12.5858 7.13201 12.5858 7C12.5858 6.86799 12.5597 6.73728 12.5089 6.61542C12.4581 6.49356 12.3837 6.38296 12.29 6.29C12.1026 6.10375 11.8492 5.99921 11.585 5.99921C11.3208 5.99921 11.0674 6.10375 10.88 6.29L6.59 10.59C6.21441 10.9633 6.00223 11.4704 6 12C6.00487 12.5261 6.21684 13.0291 6.59 13.4L10.88 17.7C10.9732 17.7926 11.0838 17.8659 11.2054 17.9157C11.3269 17.9656 11.4571 17.991 11.5885 17.9905C11.7199 17.9901 11.8499 17.9637 11.9712 17.913C12.0924 17.8623 12.2024 17.7882 12.295 17.695C12.3876 17.6017 12.4609 17.4912 12.5107 17.3696C12.5606 17.248 12.586 17.1178 12.5856 16.9864C12.5851 16.8551 12.5588 16.725 12.508 16.6038C12.4573 16.4826 12.3832 16.3726 12.29 16.28L9 13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
              fill="#333333"
              className="fill-current text-black group-hover:text-white"
            />
          </svg>
        </button>
        <button
          className={`p-4 border rounded-full ${
            isPrevHovered ? "bg-white border-black" : "bg-blue border-blue"
          }`}
          onClick={goToNextItem}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5 11H15L11.71 7.71C11.6163 7.61703 11.5419 7.50643 11.4911 7.38457C11.4403 7.26272 11.4142 7.13201 11.4142 7C11.4142 6.86799 11.4403 6.73728 11.4911 6.61542C11.5419 6.49356 11.6163 6.38296 11.71 6.29C11.8974 6.10375 12.1508 5.99921 12.415 5.99921C12.6792 5.99921 12.9326 6.10375 13.12 6.29L17.41 10.59C17.7856 10.9633 17.9978 11.4704 18 12C17.9951 12.5261 17.7832 13.0291 17.41 13.4L13.12 17.7C13.0268 17.7926 12.9162 17.8659 12.7946 17.9157C12.6731 17.9656 12.5429 17.991 12.4115 17.9905C12.2801 17.9901 12.1501 17.9637 12.0288 17.913C11.9076 17.8623 11.7976 17.7882 11.705 17.695C11.6124 17.6017 11.5391 17.4912 11.4893 17.3696C11.4394 17.248 11.414 17.1178 11.4144 16.9864C11.4149 16.8551 11.4412 16.725 11.492 16.6038C11.5427 16.4826 11.6168 16.3726 11.71 16.28L15 13H5C4.73478 13 4.48043 12.8946 4.29289 12.7071C4.10536 12.5196 4 12.2652 4 12C4 11.7348 4.10536 11.4804 4.29289 11.2929C4.48043 11.1054 4.73478 11 5 11Z"
              fill="#333333"
              className={`${
                isPrevHovered
                  ? "fill-current text-black"
                  : "fill-current text-white"
              }`}
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default StudentTestimonials;
