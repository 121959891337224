import React from "react";
import NavigationBanner from "../../images/navbar/megamenu/NavigationBanner.webp";
import { EpRight } from "../study-abroad-landing/AboutEducationMentorsGlobally";
import { Link } from "react-router-dom";
import { serviceItems } from "./serviceItems";

const OurServiceDropdownDesktop = ({
  isVisible,
  closeDropdown,
}: {
  isVisible: boolean;
  closeDropdown: () => void;
}) => {
  return (
    <div
      className={`absolute top-full left-0 w-full rounded-md bg-white shadow-lg max-md:shadow-none max-md:border-none max-md:p-0 p-4 border gap-5 border-gray-200 transition-opacity duration-200 ${
        isVisible ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div className="max-md:block flex gap-3">
        <div className="w-full md:w-[60%]">
          <ul className="max-md:flex max-md:flex-col grid grid-cols-2 gap-4">
            {serviceItems.map((item, index) => (
              <li
                key={index}
                className="flex items-center gap-2 text-gray-800 hover:text-blue-600 cursor-pointer transition max-md:py-2"
              >
                <Link
                  to={item.url}
                  className="flex gap-2 max-md:border-b border-b-[#C2F1E6] w-full p-2"
                  onClick={closeDropdown}
                >
                  <img
                    src={item.icon}
                    alt={item.text}
                    className="w-4 h-4 object-contain"
                  />
                  <span className="max-md:text-base text-sm">{item.text}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="w-full md:w-[40%] max-md:hidden">
          <div className="relative">
            <div className="flex">
              <img
                src={NavigationBanner}
                alt="Scholarship"
                className="w-full h-full rounded-lg object-cover"
              />
            </div>
            <div className="flex flex-col gap-2 absolute -bottom-1">
              <h4 className="text-sm font-light text-[#F8EC67] text-center">
                Empowering{" "}
                <strong className="font-semibold">
                  +2 Students with <br />
                  <span className="text-sm md:text-base font-bold">
                    ₹1 Crore
                  </span>
                  <sup>*</sup> in Scholarships.
                </strong>
              </h4>
              <p className="text-white text-center text-xs">
                Currently studying in Class 12 or equivalent from recognized
                board in India.
              </p>
              {/* <div className="flex items-center justify-center">
                <Link
                  className="bg-[#173CBA] text-white py-1.5 px-2 text-sm rounded-full hover:bg-green transition flex items-center justify-center gap-1"
                  to="https://cocubes.in/collegementor"
                  target="_blank"
                >
                  Exam Live <EpRight />
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServiceDropdownDesktop;
