import React from "react";
import RegulatoryProfessionalBanner from "./components/RegulatoryProfessionalBanner";
import RegulatoryProfessional from "./components/RegulatoryProfessional";
import UGCDetailedInfo from "./components/UGCDetailedInfo";
import AIUDetailedInfo from "./components/AIUDetailedInfo";
import RegulatoryCouncilCards from "./components/RegulatoryCouncilCards";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";
import config from "../../util/config";


const RegulatoryProfessionalCouncil = () => {
  return (
    <>
    <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>Regulatory Prof. Councils: Roles, Importance & Guidance | College Mentor</title>
          <meta name="description" content="Explore the role of regulatory professional councils in education and careers. Get expert guidance and insights at College Mentor to succeed." />
          <meta name="keywords" content="regulatory professional councils, professional regulation, career guidance, education councils, college mentor, mentorship" />
          <link rel="canonical" href={`${config.apiUrl}/regulatory-professional-councils`} />

          <meta
            property="og:title"
            content="Explore the role of regulatory professional councils in education and careers. Get expert guidance and insights at College Mentor to succeed."
          />
          <meta
            property="og:description"
            content="regulatory professional councils, professional regulation, career guidance, education councils, college mentor, mentorship"
          />
          <meta property="og:url" content={`${config.apiUrl}/regulatory-professional-councils`} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Regulatory Prof. Councils: Roles, Importance & Guidance | College Mentor"
          />
          <meta
            name="twitter:description"
            content="Explore the role of regulatory professional councils in education and careers. Get expert guidance and insights at College Mentor to succeed."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
    <div>
      <RegulatoryProfessionalBanner />
      <RegulatoryProfessional />
      <UGCDetailedInfo />
      <AIUDetailedInfo />
      <RegulatoryCouncilCards />
    </div>
    </>
  );
};

export default RegulatoryProfessionalCouncil;
