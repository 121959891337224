import React from "react";
import buldIcon from "../../images/courses/building-icon.svg";
import "./TakeAFreetest.scss";
import { Link } from "react-router-dom";
import config from "../../util/config";

const takeAFreetestContent = [
  {
    title:
      "Your Dream Course & College, Our Promise to Make It Happen Connect with Admission Mentor at +91-8989595918",
  },
];

const TakeAFreetest: React.FC = () => {
  return (
    <div className="bg-gradient-to-r from-blue-900 to-teal-500 flex py-8 my-6 max-sm:my-1">
      <div className="container mx-auto px-4">
        <div className="flex flex-row w-full">
          {takeAFreetestContent.map((content) => (
            <div className="flex  md:flex-row gap-4 justify-between items-center w-full">
              <div className="flex takefree-heading flex-row gap-3 items-center">
                <img
                  src={buldIcon}
                  alt="Building Icon"
                  className="w-11 h-11 "
                />
                <h3 className="text-xl  lg:text-2xl font-bold text-white m-0 xs:text-xs sm:text-xl capitalize">
                  {content.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TakeAFreetest;
