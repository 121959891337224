import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Snackbar,
   Alert,
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs, { Dayjs } from "dayjs";
import apiService from "../../services/appService";

// Define interfaces
interface Slot {
  slotId: string | number;
  slotDate: string;
  startTime: string;
  endTime: string;
}

interface Booking {
  bookingId: string | number;
  studentName: string;
  studentEmail: string;
  slotDate: string;
  startTime: string;
  endTime: string;
  meetingLink?: string;
}

interface BookingsByStatus {
  pending: Booking[];
  approved: Booking[];
  canceled: Booking[];
}

interface SnackbarState {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
}

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

const MentorDashboard: React.FC = () => {
  const [slots, setSlots] = useState<Slot[]>([]);
  const [newSlotStart, setNewSlotStart] = useState<Dayjs | null>(null);
  const [newSlotEnd, setNewSlotEnd] = useState<Dayjs | null>(null);
  const [bookings, setBookings] = useState<BookingsByStatus>({
    pending: [],
    approved: [],
    canceled: [],
  });
  const [tabValue, setTabValue] = useState<number>(0);
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: "",
    severity: "error",
  });
  const token = localStorage.getItem("authToken") as string | null;
  const mentorId = 20;
  const [isAddSlotDisabled, setIsAddSlotDisabled] = useState<boolean>(true);

  useEffect(() => {
    fetchSlots();
    fetchBookings();
  }, []);

  useEffect(() => {
    if (!newSlotStart || !newSlotEnd) {
      setIsAddSlotDisabled(true);
      return;
    }

    const start = newSlotStart;
    const end = newSlotEnd;
    const today = dayjs().startOf("day");

    const sameDate = start.isSame(end, "day");
    const notPast = start.isSame(today, "day") || start.isAfter(today);
    const validRange = start.isBefore(end);

    setIsAddSlotDisabled(!(sameDate && notPast && validRange));
  }, [newSlotStart, newSlotEnd]);

  const fetchSlots = async (): Promise<void> => {
    try {
      const data = await apiService.getMentorSlots(mentorId, token);
      setSlots(data as Slot[]);
    } catch (error: any) {
      setSnackbar({
        open: true,
        message:
          "Failed to load slots: " +
          (error.response?.data?.message || error.message),
        severity: "error",
      });
    }
  };

  const handleAddSlot = async (): Promise<void> => {
    if (!newSlotStart || !newSlotEnd) {
      setSnackbar({
        open: true,
        message: "Please select start and end times",
        severity: "warning",
      });
      return;
    }
    const slotData = {
      slotDate: newSlotStart.format("YYYY-MM-DD"),
      startTime: newSlotStart.format("HH:mm:ss"),
      endTime: newSlotEnd.format("HH:mm:ss"),
    };
    try {
      await apiService.createMentorSlot(mentorId, slotData, token);
      await fetchSlots();
      setNewSlotStart(null);
      setNewSlotEnd(null);
      setSnackbar({
        open: true,
        message: "Slot added successfully",
        severity: "success",
      });
    } catch (error: any) {
      setSnackbar({
        open: true,
        message:
          "Failed to add slot: " +
          (error.response?.data?.message || error.message),
        severity: "error",
      });
    }
  };

  const handleDeleteSlot = async (slotId: string | number): Promise<void> => {
    try {
      await apiService.deleteMentorSlot(mentorId, slotId, token);
      setSlots(slots.filter((slot) => slot.slotId !== slotId));
      setSnackbar({
        open: true,
        message: "Slot deleted successfully",
        severity: "success",
      });
    } catch (error: any) {
      setSnackbar({
        open: true,
        message:
          "Failed to delete slot: " +
          (error.response?.data?.message || error.message),
        severity: "error",
      });
    }
  };

  const fetchBookings = async (): Promise<void> => {
    try {
      const [pending, approved, canceled] = await Promise.all([
        apiService.getMentorBookings(mentorId, "PENDING", token),
        apiService.getMentorBookings(mentorId, "APPROVED", token),
        apiService.getMentorBookings(mentorId, "CANCELLED", token),
      ]);
      setBookings({
        pending: pending as Booking[],
        approved: approved as Booking[],
        canceled: canceled as Booking[],
      });
    } catch (error: any) {
      setSnackbar({
        open: true,
        message:
          "Failed to load bookings: " +
          (error.response?.data?.message || error.message),
        severity: "error",
      });
    }
  };

  const handleApproveBooking = async (
    bookingId: string | number
  ): Promise<void> => {
    try {
      const updatedBooking = (await apiService.approveBooking(
        bookingId,
        token
      )) as Booking;
      setBookings({
        pending: bookings.pending.filter((b) => b.bookingId !== bookingId),
        approved: [...bookings.approved, updatedBooking],
        canceled: bookings.canceled,
      });
      setSnackbar({
        open: true,
        message: "Booking approved successfully",
        severity: "success",
      });
    } catch (error: any) {
      setSnackbar({
        open: true,
        message:
          "Failed to approve booking: " +
          (error.response?.data?.message || error.message),
        severity: "error",
      });
    }
  };

  const handleCancelBooking = async (
    bookingId: string | number
  ): Promise<void> => {
    try {
      const updatedBooking = (await apiService.cancelBooking(
        bookingId,
        token
      )) as Booking;
      setBookings({
        pending: bookings.pending.filter((b) => b.bookingId !== bookingId),
        approved: bookings.approved,
        canceled: [...bookings.canceled, updatedBooking],
      });
      setSnackbar({
        open: true,
        message: "Booking canceled successfully",
        severity: "success",
      });
    } catch (error: any) {
      setSnackbar({
        open: true,
        message:
          "Failed to cancel booking: " +
          (error.response?.data?.message || error.message),
        severity: "error",
      });
    }
  };

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setTabValue(newValue);
  };

  const handleSnackbarClose = (): void => {
    setSnackbar({ ...snackbar, open: false });
  };

  const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Mentor Dashboard
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6">Manage Slots</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <DateTimePicker
              label="Start Time"
              value={newSlotStart}
              onChange={(newValue: Dayjs | null) => setNewSlotStart(newValue)}
              slotProps={{ textField: { variant: "outlined" } }}
              minDateTime={dayjs()}
            />
            <DateTimePicker
              label="End Time"
              value={newSlotEnd}
              onChange={(newValue: Dayjs | null) => setNewSlotEnd(newValue)}
              slotProps={{ textField: { variant: "outlined" } }}
              minDateTime={newSlotStart || dayjs()}
            />
            <Button
              variant="contained"
              onClick={handleAddSlot}
              disabled={isAddSlotDisabled}
            >
              Add Slot
            </Button>
          </Box>
        </LocalizationProvider>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {slots.map((slot) => (
            <Grid item xs={12} sm={6} md={4} key={slot.slotId}>
              <Card>
                <CardContent>
                  <Typography>
                    {dayjs(`${slot.slotDate} ${slot.startTime}`).format(
                      "ddd MMM DD, YYYY hh:mmA"
                    )}{" "}
                    -
                    {dayjs(`${slot.slotDate} ${slot.endTime}`).format("hh:mmA")}
                  </Typography>
                </CardContent>
                <CardActions>
                  <IconButton onClick={() => handleDeleteSlot(slot.slotId)}>
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box>
        <Typography variant="h6">Student Requests</Typography>
        <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label={`Pending (${bookings.pending.length})`} />
          <Tab label={`Approved (${bookings.approved.length})`} />
          <Tab label={`Canceled (${bookings.canceled.length})`} />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            {bookings.pending.map((booking) => (
              <Grid item xs={12} sm={6} key={booking.bookingId}>
                <Card>
                  <CardContent>
                    <Typography>
                      <strong>Student:</strong> {booking.studentName} (
                      {booking.studentEmail})
                    </Typography>
                    <Typography>
                      <strong>Slot:</strong>{" "}
                      {dayjs(`${booking.slotDate} ${booking.startTime}`).format(
                        "ddd MMM DD, YYYY hh:mmA"
                      )}{" "}
                      -{" "}
                      {dayjs(`${booking.slotDate} ${booking.endTime}`).format(
                        "hh:mmA"
                      )}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <IconButton
                      onClick={() => handleApproveBooking(booking.bookingId)}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleCancelBooking(booking.bookingId)}
                    >
                      <CancelIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={2}>
            {bookings.approved.map((booking) => (
              <Grid item xs={12} sm={6} key={booking.bookingId}>
                <Card>
                  <CardContent>
                    <Typography>
                      <strong>Student:</strong> {booking.studentName} (
                      {booking.studentEmail})
                    </Typography>
                    <Typography>
                      <strong>Slot:</strong>{" "}
                      {dayjs(`${booking.slotDate} ${booking.startTime}`).format(
                        "ddd MMM DD, YYYY hh:mmA"
                      )}{" "}
                      -{" "}
                      {dayjs(`${booking.slotDate} ${booking.endTime}`).format(
                        "hh:mmA"
                      )}
                    </Typography>
                    {booking.meetingLink && (
                      <Typography>
                        <strong>Meeting Link:</strong>{" "}
                        <a href={booking.meetingLink}>{booking.meetingLink}</a>
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Grid container spacing={2}>
            {bookings.canceled.map((booking) => (
              <Grid item xs={12} sm={6} key={booking.bookingId}>
                <Card>
                  <CardContent>
                    <Typography>
                      <strong>Student:</strong> {booking.studentName} (
                      {booking.studentEmail})
                    </Typography>
                    <Typography>
                      <strong>Slot:</strong>{" "}
                      {dayjs(`${booking.slotDate} ${booking.startTime}`).format(
                        "ddd MMM DD, YYYY hh:mmA"
                      )}{" "}
                      -{" "}
                      {dayjs(`${booking.slotDate} ${booking.endTime}`).format(
                        "hh:mmA"
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MentorDashboard;
