import React from "react";

const CollegeMentorAchievers = () => {
  // Mock Data for Stats
  const statsData = [
    {
      id: 1,
      imageSrc: "/images/student.webp",
      altText: "Successfully Admitted Students",
      value: "10k",
      description: "Successfully Admitted Students",
    },
    {
      id: 2,
      imageSrc: "/images/success.webp",
      altText: "Success Rate",
      value: "95%",
      description: "Success Rate",
    },
    {
      id: 3,
      imageSrc: "/images/awarded.webp",
      altText: "Scholarships Awarded",
      value: "1CR",
      description: "in Scholarships Awarded",
    },
    {
      id: 4,
      imageSrc: "/images/partnerships.webp",
      altText: "University Partnerships",
      value: "100+",
      description: "Prestigious University Partnerships",
    },
  ];

  return (
    <div className="w-full  bg-[#F4F4F4] flex flex-col items-center py-6">
      <div className="container flex flex-col items-center text-center mx-auto gap-6">
        <div className="xl:w-[1064px] space-y-2 max-xl:w-full">
          <h2 className="text-[36px] font-bold text-[#0C9] leading-[45px]">
            Our Winning Record
          </h2>
          <h3 className="relative text-[30px] font-normal text-[#173CBA] leading-[45px]">
            College Mentor Achievers
          </h3>
          <p className="text-[30px] font-normal text-[#383838] leading-[45px] font-roboto">
            No tall claims, just proven success. Check out our impressive stats
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-16 mt-8 w-full">
          {statsData.map((stat, index) => (
            <div
              key={stat.id}
              className={`flex flex-col items-center ${
                index !== 0 ? "max-md:hidden" : ""
              }`}
            >
              <img
                src={stat.imageSrc}
                alt={stat.altText}
                className="w-[155px] h-[170px]"
              />
              <p className="text-[20px] font-normal text-[#383838] leading-[30px] font-roboto text-center">
                <span className="font-bold text-[32px] leading-[45px]">
                  {stat.value}
                </span>
                <br />
                {stat.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CollegeMentorAchievers;
