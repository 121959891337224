import React, { useState } from "react";

interface FAQ {
  question: string;
  answer: string;
}

const mockFAQs: FAQ[] = [
  {
    question: "What is the College Mentor Scholarship Test?",
    answer:
      "The College Mentor Scholarship Test is a competitive exam designed to identify talented students who will receive scholarships, cash prizes, and other academic opportunities. The test evaluates your knowledge, critical thinking, and overall academic potential.",
  },
  {
    question: "What prizes can I win in the College Mentor Scholarship Test?",
    answer:
      "Winners of the College Mentor Scholarship Test receive scholarships, cash prizes, and various academic benefits, depending on their performance in the test.",
  },
  {
    question:
      "What universities are affiliated with College Mentor for scholarships?",
    answer:
      "A variety of universities collaborate with College Mentor to offer scholarships to eligible students. The specific universities vary each year.",
  },
  {
    question: "Can I use the scholarship at any university of my choice?",
    answer:
      "Scholarships are typically valid at participating universities. Please check the terms and conditions of the specific scholarship you receive.",
  },
  {
    question: "What is the value of the scholarships provided?",
    answer:
      "Scholarship amounts vary based on test performance and available funding. Details will be provided upon qualification.",
  },
  {
    question: "How do I claim my cash prize and scholarship?",
    answer:
      "Winners will be notified with instructions on how to claim their awards. Ensure all required documentation is submitted in time.",
  },
  {
    question: "How will the cash prize be distributed?",
    answer:
      "Cash prizes are typically transferred via bank transfer or check, depending on the winner's preference.",
  },
  {
    question:
      "Can I apply for the College Mentor Scholarship Test if I am already enrolled in a college or university?",
    answer:
      "Yes, currently enrolled students may apply as long as they meet the eligibility criteria.",
  },
  {
    question: "How do I know if I have won a scholarship or cash prize?",
    answer:
      "Results will be announced on the official website, and winners will be notified via email or phone.",
  },
  {
    question: "What is the next step after winning a scholarship?",
    answer:
      "Winners should follow the provided instructions to complete verification and claim their scholarships.",
  },
  {
    question:
      "Are there any additional benefits of being a College Mentor scholarship recipient?",
    answer:
      "Yes! Recipients may get access to mentorship programs, internships, and networking opportunities with academic professionals.",
  },
  {
    question: "How do I contact College Mentor for any queries?",
    answer:
      "You can reach out through the official website’s contact form or email support at support@collegementor.com.",
  },
];

const KnowledgeHubFAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-[#FFF9E5] py-6 px-6 lg:px-20">
      <div className="max-w-4xl max-md:w-full">
        <h3 className="text-[40px] font-bold text-[#2B2B2B] mb-6">
          Knowledge Hub (FAQ's)
        </h3>
        <div className="flex flex-col gap-3">
          {mockFAQs.map((faq, index) => (
            <div key={index} className=" py-2">
              <button
                onClick={() => toggleFAQ(index)}
                className="w-full flex items-center justify-between text-left text-[#2B2B2B] text-lg font-semibold"
              >
                <div className="flex items-center gap-4">
                  <span className="text-sm bg-[#004AAD] text-white rounded-md flex items-center justify-center w-8 h-8">
                    {openIndex === index ? "−" : "+"}
                  </span>
                  <span className="max-md:w-[300px]">{faq.question}</span>
                </div>
              </button>
              {openIndex === index && (
                <div className="mt-2 text-[#4F4F4F] text-sm pl-12 ">
                  <p className="leading-6">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KnowledgeHubFAQ;
