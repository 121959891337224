import mechanicalEngineeringIcon from "../images/mechanical-engineering-icon.svg";
import clockIcon from "../images/clock-icon.png";
import fullTimeIcon from "../images/full-time-icon.png";
import React, { useState, useEffect } from "react";
import { ICollege } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faHeart } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import SlideTrack from "../../../components/Slider/SlideTrack";
import config from "../../../util/config";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import PopupInfo from "../../../components/popupInfo/popup";
import { BaseURLAuth } from "../../../services/api";
interface IProps {
  data: ICollege;
  isWishlisted: boolean;
}
interface Shortlist {
  image: string;
  title: string;
  category: string;
  collegeId?: number;
  location: string;
  courseId?: number;
}
const CourseCard: React.FC<IProps> = ({
  data,
  isWishlisted: initialIsWishlisted,
}) => {
  const {
    courseTitle,
    courseIcon,
    duration,
    offeredCourses,
    courseName,
    description,
    eligibility,
    averageSalary,
    collegesCount,
    popularJobRoles,
    entranceExams,
    featuredColleges,
    averageFee,
    offeredDegrees,
    courseId,
    isDataAvailable,
  } = data;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  const handlePopupOpen = () => setShowPopup(true);
  const handlePopupClose = () => setShowPopup(false);

  const [isWishlisted, setIsWishlisted] = useState(initialIsWishlisted);

  const [userId, setUserId] = useState<number | null>(null);

  const handleLeadGeneration = () => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/secure/login", { state: { sourcePagePath: pathname } });
    } else {
      const toggleWishlist = async () => {
        try {
          if (userId) {
            console.log("im inside");
            const apiUrl = isWishlisted
              ? "/api/wishlist/remove"
              : "/api/wishlist/add";
            console.log("data ve dhan", data, userId, courseId);
            const response = await BaseURLAuth.post(
              apiUrl,
              {
                categoryName: "course",
                categoryId: courseId,
                userId: userId,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("accessToken"),
                },
              }
            );
            console.log("response", response);
            if (response.data) {
              setIsWishlisted(!isWishlisted);
            } else {
              console.error("Error toggling wishlist:", response.statusText);
            }
          }
        } catch (error) {
          console.error("Error toggling wishlist:", error);
        }
      };
      toggleWishlist();
    }
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserId(parsedUser.userId);
    }
  }, []);

  var descriptionContent = null;
  if (description && description.length > 200)
    descriptionContent = <>{description.slice(0, 250) + "..."}</>;
  else descriptionContent = description;

  var entranceExamsContent = null;
  if (entranceExams && entranceExams.length > 2)
    entranceExamsContent = `${entranceExams[0]}, ${entranceExams[1]} +${
      entranceExams.length - 2
    }`;
  else entranceExamsContent = entranceExams.join(", ");

  return (
    <div className="pl-2 pr-4 py-6 max-sm:py-3 flex gap-2 bg-white text-grey">
      <div className="w-[12%] md:w-1/12">
        <img
          src={courseIcon || mechanicalEngineeringIcon}
          className="w-full"
          alt="courseIcon"
        />
      </div>
      <div className="w-[88%] md:w-11/12 flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <h3 className="text-lg md:text-2xl text-black font-semibold">
            {isDataAvailable ? (
              <Link to={`/course-details/${courseName}`}>{courseTitle}</Link>
            ) : (
              <>
                <button
                  onClick={handlePopupOpen}
                  className="text-blue underline"
                >
                  {courseTitle}
                </button>
                {showPopup && (
                  <PopupInfo
                    isOpen={showPopup}
                    onClose={() => setShowPopup(false)}
                  />
                )}
              </>
            )}
          </h3>
          {/* <button>
            <FontAwesomeIcon
              icon={faHeart}
              color="white"
              stroke="#EC1A3B"
              size="lg"
              strokeWidth={40}
            />
          </button> */}
          <button
            onClick={handleLeadGeneration}
            className={`p-2 rounded transition-colors duration-300 ${
              true ? "" : ""
            }`}
          >
            <FontAwesomeIcon
              icon={faHeart}
              color={isWishlisted ? "#EC1A3B" : "#FFF"}
              stroke="#EC1A3B"
              size="lg"
              strokeWidth={40}
            />
          </button>
        </div>
        <div className="flex gap-2 flex-wrap">
          {duration.map((item) => (
            <>
              <DetailChip
                text={`${item.degreeType} ${item.durationOf}`}
                icon={clockIcon}
              />
              <span className="text-pink">|</span>
            </>
          ))}
          <DetailChip text="FULL-TIME" icon={fullTimeIcon} />
        </div>
        <p className="text-sm md:text-base">
          <span className="text-blue font-medium">AVG Fee:</span> {averageFee}{" "}
          <span className="text-pink mx-[7.5px]">|</span>{" "}
          <Link
            to={`/course-details/${courseName}#subjects`}
            className="text-blue font-medium duration-75"
          >
            Syllabus & Subjects
          </Link>{" "}
          <span className="text-pink mx-[7.5px]">|</span>{" "}
          <Link
            to={`/course-details/${courseName}#job-profiles`}
            className="text-blue font-medium"
          >
            Job Scope & Salary
          </Link>
        </p>
        <p className="text-sm md:text-base flex flex-1 gap-2">
          <span className="text-blue font-medium">Course Eligiblity:</span>{" "}
          {eligibility}{" "}
          {entranceExams && entranceExams.length > -1 && (
            <>
              <span className="text-pink">|</span>{" "}
              <span className="text-blue font-medium">Entrance Exam: </span>
              {entranceExamsContent}
            </>
          )}
        </p>
        <p className="text-sm md:text-base">
          {descriptionContent}
          <Link
            to={`/course-details/${courseName}`}
            className="text-blue font-medium"
          >
            {" "}
            Read More
          </Link>
        </p>
        <div className="border-t border-t-grey-3"></div>
        {popularJobRoles && popularJobRoles.length > 0 && (
          <>
            <p className="text-sm md:text-base">
              <span className="text-blue font-medium">Popular Job Roles:</span>{" "}
              {popularJobRoles.map((role, index) => (
                <>
                  {index > 0 && <span className="text-pink"> | </span>}
                  {role}
                </>
              ))}
            </p>
            <div className="border-t border-t-grey-3"></div>
          </>
        )}
        <div className="flex flex-col md:flex-row gap-4 md:justify-between">
          <div className="max-w-full flex flex-1 flex-col gap-2">
            <p className="text-sm md:text-base">
              <span className="text-blue font-medium">Featured Colleges:</span>
            </p>
            <SlideTrack
              items={[...featuredColleges].map((college) => (
                <img
                  src={
                    college.iconUrl ||
                    config.apiUrl +
                      "/images/careers/dreamscareer/enginnering.jpg"
                  }
                  className="w-full h-auto"
                  alt="enginneringIcon"
                />
              ))}
              speed={2}
              itemWidth={48}
              gap={16}
              windowSize={3}
            />
          </div>
          <Link
            to={isDataAvailable ? `/course-details/${courseName}` : "#"}
            onClick={(e) => {
              if (!isDataAvailable) {
                e.preventDefault();
                setShowPopup(true);
              }
            }}
            className="self-end py-2.5 w-fit px-6 blue-color button-styles text-sm font-semibold rounded-full duration-200 group"
          >
            View more
            <FontAwesomeIcon
              icon={faArrowRight}
              size="lg"
              className="transition-transform group-hover:translate-x-2"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

const DetailChip: React.FC<{ icon: string; text: string }> = ({
  icon,
  text,
}) => {
  return (
    <div className="flex items-center gap-1">
      <img src={icon} className="h-[1.2rem]" alt="icon" />
      <span className="text-xs font-semibold text-blue">{text}</span>
    </div>
  );
};

export default CourseCard;
