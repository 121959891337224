import React, { useEffect } from "react";
import ScholorshipBanner from "./components/ScholorshipBanner";
import ScholarshipFAQ from "./components/ScholorshipFAQ";
import SclorshipObjective from "./components/SclorshipObjective";
import ScholarshipDisbursement from "./components/ScholarshipDisbursement";
import CollegeMentorServices from "./components/CollegeMentorServices";
import ScholorshipApply from "./components/ScholorshipApply";
import ScholorshipEligibility from "./components/ScholorshipEligibility";
import IndiasTopInstitutions from "./components/IndiasTopInstitutions";
import ScholorshipCategoryAward from "./components/ScholorshipCategoryAwards";
import { Helmet, HelmetProvider } from "react-helmet-async";
import config from "../../util/config";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";

const ScholarshipTest = () => {
  const projectName = "College Mentors";

  useEffect(() => {
    document.title = "College Mentor | Scholarship Test for 10+2 students";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Apply for College Mentor scholarship test. Check eligibility, minority scholarships, school scholarships, education tour, tuition discounts, cash prizes for top ranks"
      );
  }, []);

  return (
    <div className="text-black">
      <HelmetProvider>
        <Helmet>
          {/* <!-- Google Tag Manager --> */}
          <script>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${config.GTM_CODE}');
            `}
          </script>
          {/* <!-- End Google Tag Manager --> */}

          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentor Scholarship Test 2025 for Plus 12 Students | Online
            Registration
          </title>
          <meta
            name="description"
            content="The College Mentor Scholarship Test 2025 is a nationwide FREE examination designed to recognize and reward academic excellence among students in Class 12."
          ></meta>

          <meta
            name="keywords"
            content="Scholarship status, Scholarship check, Scholarship eligibility, Scholarship last date, National scholarship portal, Scholarship portal, College Mentor Scholarship Test, Merit-based scholarships, Europe education tour scholarship, Tuition discounts at IIT, NIT, AIIMS, Cash prizes for students, Academic excellence rewards, Scholarship opportunities for students, National scholarship eligibility, Top scholarships for students, College Mentor"
          />
          <link
            rel="canonical"
            href={`${config.apiUrl}/college-mentor-scholarship`}
          />

          <meta
            property="og:title"
            content="Scholarship Test 2025 | College Mentor | Free Register Online"
          />
          <meta
            property="og:description"
            content="Register for the College Mentor Scholarship Test and stand a chance to win scholarships worth ₹1 crore. Apply now before the deadline!"
          />
          <meta
            property="og:image"
            content="https://cdncollegementor.blob.core.windows.net/scholarship/scholarshipTest.jpg"
          />
          <meta
            property="og:url"
            content="https://collegementor.com/college-mentor-scholarship"
          />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="collegementor.com" />
          <meta
            property="twitter:url"
            content="https://www.collegementor.com/college-mentor-scholarship"
          />
          <meta
            name="twitter:title"
            content="College Mentor Scholarship Test 2025 for Plus 12 Students | Online Registration"
          />
          <meta
            name="twitter:description"
            content="The College Mentor Scholarship Test 2025 is a nationwide FREE examination designed to recognize and reward academic excellence among students in Class 12."
          />
          <meta
            name="twitter:image"
            content="https://cdncollegementor.blob.core.windows.net/scholarship/scholarshipTest.jpg"
          />

          <meta name="robots" content="index, follow" />

          {/* Schema.org markup for Scholarship Test page */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "EducationEvent",
              name: "College Mentor Scholarship Test",
              url: `${config.apiUrl}/college-mentor-scholarship`,
              description:
                "College Mentor Scholarship Test 2025 designed to empowering +2 students with Rs 1 Crore in scholarships.",
              eventStatus: "https://schema.org/EventScheduled",
              eventAttendanceMode:
                "https://schema.org/OnlineEventAttendanceMode",
              location: {
                "@type": "VirtualLocation",
                url: `${config.apiUrl}/college-mentor-scholarship`,
              },
              organizer: {
                "@type": "EducationalOrganization",
                name: "College Mentor",
                url: `${config.apiUrl}`,
                contactPoint: {
                  "@type": "ContactPoint",
                  telephone: "+91-7997166666",
                  contactType: "customer support",
                  availableLanguage: ["English", "Hindi", "Telugu"],
                },
              },
              offers: {
                "@type": "Offer",
                url: `${config.apiUrl}/college-mentor-scholarship`,
                price: "0",
                priceCurrency: "INR",
                availability: "https://schema.org/InStock",
                validFrom: "2024-11-15T00:00:00+05:30",
              },
              eligibilityCriteria: {
                academicQualification: "Minimum 75% in last qualifying exam",
                courseLevel: "Undergraduate",
                category: "General, SC, ST, OBC",
                familyIncome: "Below 8 LPA",
                ageLimit: "18-25 years",
              },
              applicationDates: {
                startDate: "2024-11-15",
                lastDate: "2025-03-30",
                applicationLink: `${config.apiUrl}/college-mentor-scholarship/application`,
              },
              testPattern: {
                examFormat: "Online, MCQ Based",
                syllabus: "Physics, Chemistry, Mathematics, General Knwoledge",
                duration: "120 minutes",
                markingScheme: "+4 for correct, -1 for incorrect",
              },
              importantInstructions: {
                registrationProcess:
                  "Fill out the online form and upload required documents.",
                requiredDocuments:
                  "Aadhaar Card, Marksheet, Income Certificate",
                examCenterSelection: "Available during registration",
                admitCardDownload: "Available from March 15, 2025",
                examDayGuidelines:
                  "Reach online test portal 15 minutes before the test starts.",
              },
              contactDetails: {
                email: "support@collegementor.com",
                phoneNumber: "+91-7997166666",
                website: `${config.apiUrl}`,
              },
              subEvent: [
                {
                  "@type": "EducationEvent",
                  name: "Registration Start",
                  startDate: "2024-11-15",
                },
                {
                  "@type": "EducationEvent",
                  name: "Registration End",
                  startDate: "2025-03-30",
                },
                {
                  "@type": "EducationEvent",
                  name: "Test Dates",
                  startDate: "2025-04-01",
                  endDate: "2025-04-10",
                },
                {
                  "@type": "EducationEvent",
                  name: "Results Announcement",
                  startDate: "2025-04-15",
                },
              ],
            })}
          </script>
        </Helmet>
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${config.GTM_CODE}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          />
        </noscript>
        <ScholorshipBanner />
        <IndiasTopInstitutions />
        <SclorshipObjective />
        <ScholorshipEligibility />
        <ScholorshipCategoryAward />
        <ScholarshipDisbursement />
        <CollegeMentorServices />
        <ScholorshipApply />
        <ScholarshipFAQ />
      </HelmetProvider>
    </div>
  );
};

export default ScholarshipTest;
