
import { BaseURL, BaseURLAuth } from "./api";
// Service for API calls
const apiService = {
   
    // Get available mentors (requires auth)
    async getMentors(token: any) {
    return BaseURL.get('/api/studentMentorShip/mentors/available', {
        headers: { Authorization: `${token}` }
      });
    },
  
    // Get slots for a mentor (requires auth)
    async getSlots(token: any, mentorId: any) {
      return BaseURL.get(`/api/studentMentorShip/mentors/${mentorId}/slots`);
    },
  
    // Add slot (mentor, requires auth)
    async addSlot(token: any, mentorId: any, slotData: any) {
      return BaseURL.post(`/api/studentMentorShip/mentors/${mentorId}/slots`, slotData);
    },
  
    // Delete slot (mentor, requires auth)
    async deleteSlot(token: any, mentorId: any, slotId: any) {
      return BaseURL.delete(`/api/studentMentorShip/mentors/${mentorId}/slots/${slotId}`);
    },
  
    // Update slot config (mentor, requires auth)
    async updateSlotConfig(token: any, mentorId: any, maxSlotDays: any, isAvailable: any) {
      const params: any = {};
      if (maxSlotDays !== undefined) params.maxSlotDays = maxSlotDays;
      if (isAvailable !== undefined) params.isAvailable = isAvailable;
      return BaseURL.patch(`/api/studentMentorShip/mentors/${mentorId}/slot-config`, null, {
        headers: { Authorization: `${token}` },
        params
      });
    },
  
    // Book a slot (requires auth)
    async bookSlot(token: any, bookingData: any) {
      return BaseURL.post('/api/studentMentorShip/bookings', bookingData, {
        headers: { Authorization: `${token}` }
      });
    },
  
    // Get student bookings (no auth as per previous request)
    async getStudentBookings(token: any) {
      return BaseURL.get(`/api/studentMentorShip/bookings/student`, {
        headers: { Authorization: `${token}` }
      });
    },
  
    // Update booking status (requires auth)
    async updateBookingStatus(token: string, bookingId: string | number, status: string) {
      return BaseURL.patch(
          `/api/studentMentorShip/bookings/${bookingId}?status=${encodeURIComponent(status)}`,
          {
            headers: { Authorization: `${token}` } // Assuming token needs "Bearer " prefix
          }
        );
      },
  
    // Complete a booking (requires auth)
    async completeBooking(token: any, bookingId: any) {
      return BaseURL.patch(`/api/studentMentorShip/bookings/${bookingId}/complete`, null, {
        headers: { Authorization: `${token}` }
      });
    },
  
    // // Cancel a booking (requires auth)
    // async cancelBooking(token: any, bookingId: any) {
    //   return BaseURLAuth.patch(`/api/studentMentorShip/bookings/${bookingId}/cancel`, null, {
    //     headers: { Authorization: `Bearer ${token}` }
    //   });
    // },
  
    // Get recording (requires auth)
    async getRecording(token: any, bookingId: any) {
      return BaseURL.get(`/api/studentMentorShip/bookings/${bookingId}/recording`, {
      // headers: { Authorization: `${token}` }
      });
    },
    getMentorSlots: async (mentorId:any, token:any) => {
      try {
        const response = await BaseURL.get(`/api/studentMentorShip/mentors/${mentorId}/slots`, {
          headers: {
            'Accept': '*/*',
            // 'Authorization': `${token}` // Commented out for now; enable later when auth is required
          }
        });
        return response.data; // Axios automatically parses JSON and returns data
      } catch (error) {
        console.error('Error in getMentorSlots:', error);
        throw error; // Re-throw for component handling
      }
    },
    createMentorSlot: async (mentorId:any, slotData:any, token:any) => {
      try {
        const response = await BaseURL.post(`/api/studentMentorShip/mentors/${mentorId}/slots`, slotData, {
          headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json',
            // 'Authorization': `${token}` // Commented out for now
          }
        });
        return response.data;
      } catch (error) {
        console.error('Error in createMentorSlot:', error);
        throw error;
      }
    },
    // Delete a slot
  deleteMentorSlot: async (mentorId:any, slotId:any, token:any) => {
    try {
      const response = await BaseURL.delete(`/api/studentMentorShip/mentors/${mentorId}/slots/${slotId}`, {
        headers: {
          'Accept': '*/*',
          // 'Authorization': `${token}`
        }
      });
      return response.status === 204; // Return true for success (204 No Content)
    } catch (error) {
      console.error('Error in deleteMentorSlot:', error);
      throw error;
    }
  },
  getMentorBookings: async (mentorId:any, status:any, token:any) => {
    try {
      const response = await BaseURL.get(`/api/studentMentorShip/mentor/bookings`, {
        params: { status, mentorId },
        headers: { 'Accept': '*/*' }
      });
      return response.data;
    } catch (error) {
      console.error('Error in getMentorBookings:', error);
      throw error;
    }
  },approveBooking: async (bookingId:any, token:any) => {
    try {
      const response = await BaseURL.patch(`/api/studentMentorShip/bookings/${bookingId}/approve`, null, {
        headers: { 'Accept': '*/*' }
      });
      return response.data;
    } catch (error) {
      console.error('Error in approveBooking:', error);
      throw error;
    }
  },// Cancel a booking
  cancelBooking: async (bookingId:any, token:any) => {
    try {
      const response = await BaseURL.patch(`/api/studentMentorShip/bookings/${bookingId}/cancel`, null, {
        headers: { 'Accept': '*/*' }
      });
      return response.data;
    } catch (error) {
      console.error('Error in cancelBooking:', error);
      throw error;
    }
  }
  
    // Note: Removed getMentor as it wasn’t in the updated endpoints list
  };

export default apiService;