import React from "react";

const AdmissionsOpen = () => {
  return (
    <div className="w-full bg-[#173CBA] text-white text-center py-5 ">
      <div className="container mx-auto flex max-md:flex-col items-center justify-between px-6">
        <div className="text-center xl:pl-[350px]">
          <p className="text-[24px] max-md:text-[20px] max-md:font-semibold font-bold leading-[36px] font-roboto">
            Admission Open for 2025
          </p>
          <p className="text-[24px] max-md:text-[20px] font-normal leading-[36px] font-roboto">
            Confused About College Admissions? <br />
            Our Mentors Have the Answers Talk to the Admission Mentors Now
          </p>
        </div>
        <button className=" flex px-4 py-1 items-center gap-2 bg-[#0C9] text-white rounded-[46px] shadow-md  ">
          Talk Now
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            className="items-center "
          >
            <path
              d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default AdmissionsOpen;
