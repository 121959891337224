import React from "react";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";

const ScholarshipAdmissions = () => {
  return (
    <div className="w-full bg-[#173CBA] text-white text-center py-5 ">
      <div className="container mx-auto flex max-md:flex-col max-md:gap-4 items-center justify-between px-6">
        <div className="text-center">
          <p className="text-[24px] font-normal leading-[36px] ">
            Help your friends by sharing this FREE tool in your friend and
            coaching group
          </p>
        </div>
        <LeadGenCtaBtn
          pageName="college-scholarship-result-page"
          ctaName="college-scholarship-apply-now"
          className="bg-green text-white py-2 px-4 rounded-md hover:bg-white hover:text-black transition-all"
        >
          Apply Now
        </LeadGenCtaBtn>
      </div>
    </div>
  );
};

export default ScholarshipAdmissions;
