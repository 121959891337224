import React, { useState, useEffect } from "react";
import axios from "axios";
import Banner from "../../components/study-abroad-landing/Banner/Banner";
import Loader from "../../components/Loader/Loader";
import CarosalLogos from "../../components/study-abroad-landing/CarosalLogos";
import AboutEducationMentorsGlobally from "../../components/study-abroad-landing/AboutEducationMentorsGlobally";
import WhyStudyAbroadSec from "../../components/study-abroad-landing/WhyStudyAbroad";
import ChooseYourDreamCountry from "../../components/study-abroad-landing/ChooseYourDreamCountry";
import SuccessStories from "../../components/study-abroad-landing/SuccessStories";
import UniversitiesWorldwideMentors from "../../components/study-abroad-landing/UniversitiesWorldwideMentors";
import TopRankedUniversitiesQS from "../../components/study-abroad-landing/TopRankedUniversitiesQS";
import KnowledgeHub from "../../components/study-abroad-landing/KnowledgeHub";
import Guides from "../../components/study-abroad-landing/Guides";
import ExploreKnowledgeHub from "../../components/study-abroad-landing/ExploreKnowledgeHub/ExploreKnowledgeHub";
import config from "../../util/config";
import { useLocation } from "react-router-dom";
import WorkingOfficeImg from "../../images/study-abroad/woman-working-office.jpg";
import { image } from "html2canvas/dist/types/css/types/image";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";

export const StudyAbroadLanding = () => {
  const [studyAbroadData, setStudyAbroadData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const successStories = [
    {
      courseName: "MS in Data Science - Maryland University",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/studyabroad/success stories/main.webp",
      description:
        "I was exploring to excel in data science field and thank you college mentor for helping in securing admission at Maryland University. I am currently pursing my dream course at Maryland University",
      storyBy: "Ms. Manasa K",
    },
    {
      courseName: "MS in Robotics Engineering",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/studyabroad/success stories/2nd.webp",
      description:
        " The Team College Mentor played a pivotal role in curating my SOP and in the selection of a university. With their help and mentorship, I am now a student in MS in Robotics at George Mason University",
      storyBy: "Mr. Vamsi Krishna Ch",
    },
    {
      courseName: "B.Tech Software Engineering",
      imageUrl:
        "https://cdncollegementor.blob.core.windows.net/studyabroad/success stories/1st.webp",
      description:
        "Thank you team College Mentor for guiding me right from attempting my GMAT exam to preparing and applying for colleges. With the able guidance of Mr. Rajasekhar, I secured my admission to Melbourne Business School ",
      storyBy: "Ms. Merlin M",
    },
  ];
  const rankedByQsData = [
    {
      collegeName: "London, United Kingdom",
      courseName: "Kings College London",
      scholarshipType: "POSTGRADUATE SCHOLARSHIP",
      courseDuration: 356,
      fee: "GBP 27,800",
      rank: 40,
      scholarshipPercentage: "INR 50,000 Exclusive Guaranteed Scholarship",
      image:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Qsrankings/King-College.webp",
    },
    {
      collegeName: "Leeds, United Kingdom",
      courseName: "University of Leeds",
      scholarshipType: "POSTGRADUATE SCHOLARSHIP",
      courseDuration: 686,
      fee: "AED 27,300",
      rank: 75,
      scholarshipPercentage: "INR 50,000 Exclusive Guaranteed Scholarship",
      image:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Qsrankings/universityofleads.webp",
    },
    {
      collegeName: "Bristol, United Kingdom",
      courseName: "University of Bristol",
      scholarshipType: "POSTGRADUATE SCHOLARSHIP",
      courseDuration: 647,
      fee: "AED 25,500",
      rank: 55,
      scholarshipPercentage: "INR 50,000 Exclusive Guaranteed Scholarship",
      image:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Qsrankings/University-of-bristol.webp",
    },
    {
      collegeName: "Lake Havasu City, U.S.A",
      courseName: "Arizona State University U.S.A",
      scholarshipType: "POSTGRADUATE SCHOLARSHIP",
      courseDuration: 16,
      fee: "AED 11,200",
      rank: 179,
      scholarshipPercentage: "INR 50,000 Exclusive Guaranteed Scholarship",
      image:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Qsrankings/arizonastageuniversity.webp",
    },
    {
      collegeName: "Dubai, United Arab Emirates",
      courseName: "Heriot -watt University duba",
      scholarshipType: "POSTGRADUATE SCHOLARSHIP",
      courseDuration: 96,
      fee: "AED 78,600",
      rank: 235,
      scholarshipPercentage: "Plus additional AED 3,000 fee waiver",
      image:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Qsrankings/Heriot.webp",
    },
    {
      collegeName: "Dubai, United Arab Emirates",
      courseName: "Murdoch University Dubai",
      scholarshipType: "POSTGRADUATE SCHOLARSHIP",
      courseDuration: 21,
      fee: "AED 58,100",
      rank: 431,
      scholarshipPercentage: "Plus additional AED 2,000 fee waiver",
      image:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Qsrankings/Murdhoch.webp",
    },
    {
      collegeName: "Dubai, United Arab Emirates",
      courseName: "De Montfort university Dubai",
      scholarshipType: "POSTGRADUATE SCHOLARSHIP",
      courseDuration: 1,
      fee: "AED 78,400",
      rank: 825,
      scholarshipPercentage: "Plus additional AED 2,000 fee waiver",
      image:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Qsrankings/Demortfort.webp",
    },
    {
      collegeName: "Dubai, United Arab Emirates",
      courseName: "Rochester Institute of Technology - RIT Dubai",
      scholarshipType: "POSTGRADUATE SCHOLARSHIP",
      courseDuration: 17,
      fee: "AED 91,300",
      rank: 1001,
      scholarshipPercentage: "Plus additional AED 2,000 fee waiver",
      image:
        "https://cdncollegementor.blob.core.windows.net/study-abroad/Study-Abroad/Qsrankings/Recherts.webp",
    },
  ];

  const params = new URLSearchParams(location.search);
  const selectedCountry = params.get("country");

  const [blogsAndArticlesData, setBlogsAndArticlesData] = useState<any>([]);
  const [StudyAbroadarticlesData, setStudyAbroadarticlesData] = useState<any>(
    []
  );

  useEffect(() => {
    const fetchStudyAbroadData = async () => {
      try {
        const response = await axios.get(
          config.apiUrl + "/api/studyAbroad/landing-page"
        );
        setStudyAbroadData(response.data);
        getBlogsandNews();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchStudyAbroadData();
    document.title = "Study Abroad Made Easy | Mentorship for Top Universities";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Plan your future with College Mentor! Get expert guidance on studying abroad, choosing the Top Universities, securing scholarships, financial aid, and visa support. Start your journey with confidence today!"
      );
  }, []);

  useEffect(() => {
    if (!loading) {
      const searchCountryParam = new URLSearchParams(window.location.search);
      const hasCountryParam = searchCountryParam.has("country");

      if (hasCountryParam) {
        const dreamCountrySection = document.getElementById("dream-country");
        if (dreamCountrySection) {
          dreamCountrySection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [loading]);

  const getBlogsandNews = () => {
    axios
      .get(config.apiUrl + `/api/blogs/blogsandnews`)
      .then((response) => {
        setStudyAbroadarticlesData(
          response.data.sections[0]["Study Abroad"].subcategories[
            "Articles & Blogs"
          ].results
        );
        prepareCareersData(
          response.data.sections[0]["Study Abroad"].subcategories[
            "Articles & Blogs"
          ].results
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const prepareCareersData = (data: any) => {
    const filteredData = data.filter((item: any) =>
      item.subTopic.toLowerCase().includes("".toLowerCase())
    );
    setBlogsAndArticlesData(filteredData);
  };

  if (loading) {
    return <Loader />;
  }

  const { faqs, guides } = studyAbroadData?.data || {};
  const {
    careerInterest,
    careerbulleyes,
    careerVisualStories,
    careerspotlights,
    blogsAndArticles,
  } = studyAbroadData.knowledgeHub || {};

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            Study Abroad Made Easy | Mentorship for Top Universities
          </title>
          <meta
            name="description"
            content="Plan your future with College Mentor! Get expert guidance on studying abroad, choosing the Top Universities, securing scholarships, financial aid, and visa support. Start your journey with confidence today!"
          />
          <meta
            name="keywords"
            content="Expert Mentorship in India, Best Application Assistance Pre-Departure and Post-Arrival Services, Visa Support Mentorship for Indian Students, Scholarship and Financial Aid, Best High-quality teaching Universities"
          />
          <link rel="canonical" href={`${config.apiUrl}/study-abroad`} />

          <meta
            property="og:title"
            content="Study Abroad Made Easy | Mentorship for Top Universities"
          />
          <meta
            property="og:description"
            content="Plan your future with College Mentor! Get expert guidance on studying abroad, choosing the Top Universities, securing scholarships, financial aid, and visa support. Start your journey with confidence today!"
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Study Abroad Made Easy | Mentorship for Top Universities "
          />
          <meta
            name="twitter:description"
            content="Plan your future with College Mentor! Get expert guidance on studying abroad, choosing the Top Universities, securing scholarships, financial aid, and visa support. Start your journey with confidence today!"
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
          
          {/* Schema.org markup for Study Abroad page */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "CollectionPage",
              "name": "College Mentor Study Abroad",
              "url": `${config.apiUrl}/study-abroad`,
              "description": "College Mentor – Study Abroad Opportunities: Find comprehensive guidance for students and universities on studying abroad, including application processes, visa requirements, and scholarship opportunities.",
              "about": [
                {
                  "@type": "Thing",
                  "name": "Top Countries to Study Abroad for Students"
                },
                {
                  "@type": "Thing",
                  "name": "Student Visas to Study Abroad"
                },
                {
                  "@type": "Thing",
                  "name": "International Scholarships Guidance"
                },
                {
                  "@type": "Thing",
                  "name": "International Exams"
                }
              ],
              "publisher": {
                "@type": "EducationalOrganization",
                "name": "College Mentor",
                "url": `${config.apiUrl}`,
                "logo": `${config.apiUrl}/static/media/College%20Mentor_Final_Logo-01.ae0ff9c1ffa9fc9fac3e.png`,
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+91-7997166666",
                  "contactType": "customer support",
                  "availableLanguage": ["English", "Hindi", "Telugu"]
                }
              }
            })}
          </script>
        </Helmet>
      </HelmetProvider>
      <div>
        <Banner />
        <CarosalLogos />
        <AboutEducationMentorsGlobally />
        <WhyStudyAbroadSec />
        <ChooseYourDreamCountry selectedCountry={selectedCountry || ""} />
        <UniversitiesWorldwideMentors />
        <TopRankedUniversitiesQS rankedByQs={rankedByQsData} />
        <SuccessStories successStories={successStories} />
        <ExploreKnowledgeHub
          careerInterest={careerInterest || []}
          careerbullseye={careerbulleyes || []}
          careerVisualStories={careerVisualStories || []}
          careerspotlights={careerspotlights || []}
          blogsAndArticles={blogsAndArticlesData || []}
        />
        <KnowledgeHub />
        <Guides />
      </div>
    </>
  );
};
