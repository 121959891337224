import { useEffect, useRef, useState } from "react";
import useDebouncedSearch from "../../components/filter/customHooks/useDebouncedSearch";
import { useAxios } from "../../components/useAxios";
import { ICollege } from "./types";
import useQueryParamPagination from "../../components/filter/customHooks/useQueryParamPagination";
import CourseCard from "./components/CourseCard";
import useSelectedFilter, {
  ICategory,
  IGetFilterDataFromPath,
  IGetPathFromFilterData,
} from "../../components/filter/customHooks/useSelectedFilter";
import Banner from "./components/Banner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader/Loader";
import {
  FilterActions,
  FilterCategory,
} from "../../components/filter/FilterEnum";
import Pagination from "../../components/filter/components/Pagination";
import DesktopFilterMenu, {
  IFilterDataItem,
} from "../../components/filter/components/DesktopFilterMenu";
import FilterChips from "../../components/filter/components/FilterChips";
import SortByDropdown from "../../components/filter/components/SortByDropdown";
import MobileFilterMenu from "../../components/filter/components/MobileFilterMenu";
import TabSelector from "../../components/filter/components/TabSelector";
import config from "../../util/config";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {BaseURL} from "../../services/api";
interface ICourseApiResponse {
  list: ICollege[];
  totalCourseCount: number;
  streamDescription: string;
  availableStreams: {
    courseCount: number;
    streamTitle: string;
    streamName: string;
  }[];
}

interface IDegreeApiResponse {
  courseCount: number;
  degreeName: string;
  degreeTitle :string;
}

const PAGE_SIZE = 10;

const STREAM_FILTER_CATEGORY = "stream";
const SORT_BY_CATEGORY = "sort-by";
const DEGREE_FILTER_CATEGORY = "degrees";
const PASSION_FILTER_CATEGORY = "passion";
const POPULAR_FILTER_CATEGORY = "popular";
const PRIVILIGED_FILTER_CATEGORY = "privilaged";
const COURSE_CATEGORY_FILTER_CATEGORY = "degreeCategory";

const FILTER_CATEGORIES: ICategory[] = [
  {
    key: STREAM_FILTER_CATEGORY,
    type: FilterCategory.SingleSelect,
    showinPath: true,
  },
  {
    key: DEGREE_FILTER_CATEGORY,
    type: FilterCategory.MultiSelect,
    showinPath: true,
  },
  {
    key: SORT_BY_CATEGORY,
    type: FilterCategory.Radio,
    showinPath: false,
  },
  {
    key: PASSION_FILTER_CATEGORY,
    type: FilterCategory.SingleSelect,
    showinPath: false,
  },
  {
    key: POPULAR_FILTER_CATEGORY,
    type: FilterCategory.SingleSelect,
    showinPath: false,
  },
  {
    key: PRIVILIGED_FILTER_CATEGORY,
    type: FilterCategory.SingleSelect,
    showinPath: false,
  },
  {
    key: COURSE_CATEGORY_FILTER_CATEGORY,
    type: FilterCategory.SingleSelect,
    showinPath: false,
  },
];

const sortByData: IFilterDataItem = {
  categoryName: "Sort by",
  categoryKey: SORT_BY_CATEGORY,
  type: FilterCategory.Radio,
  options: [
    { name: "Popularity", key: "popularity" },
    { name: "Alphabetically", key: "alphabetically" },
  ],
};

const passionFilterData: IFilterDataItem = {
  categoryName: "PASSIONAL INTEREST",
  categoryKey: PASSION_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "Creative", key: "creative" },
    { name: "Realistic", key: "realistic" },
    { name: "Investigative", key: "investigative" },
    { name: "Social", key: "social" },
    { name: "Enterprising", key: "enterprising" },
    { name: "Conventional", key: "conventional" },
  ],
};

const popularFilterData: IFilterDataItem = {
  categoryName: "POPULAR INTEREST",
  categoryKey: POPULAR_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "Space Science and Airline", key: "space_science_airline" },
    {
      name: "Sports and Physical Education",
      key: "sports_physical_education",
    },
    { name: "Beauty, Wellness and Health", key: "beauty_wellness_health" },
    {
      name: "Hospitality, Travel and Tourism",
      key: "hospitality_travel_tourism",
    },
    { name: "Forestry and Wildlife", key: "forestry_wildlife" },
    {
      name: "Special Education and Rehabilitation",
      key: "special_education_rehabilitation",
    },
    { name: "Media and Entertainment", key: "media_entertainment" },
    { name: "Astrology and Languages", key: "astrology_languages" },
  ],
};

const privilegedFilterData: IFilterDataItem = {
  categoryName: "PRIVILEGED INTEREST",
  categoryKey: PRIVILIGED_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "Indian AIRFORCE", key: "indian_airforce" },
    {
      name: "Indian NAVY",
      key: "indian_navy",
    },
    { name: "Indian ARMY", key: "indian_army" },
    {
      name: "National Cadet Corps (NCC)",
      key: "national_cadet_corps",
    },
  ],
};

const courseCategoryFilterData: IFilterDataItem = {
  categoryName: "Course Category",
  categoryKey: COURSE_CATEGORY_FILTER_CATEGORY,
  type: FilterCategory.SingleSelect,
  options: [
    { name: "Bachelors", key: "bachelors" },
    { name: "Courses", key: "courses" },
    { name: "Diploma", key: "diploma" },
    { name: "Foundation", key: "foundation" },
    { name: "Masters", key: "masters" },
    { name: "PhD", key: "phd" },
    { name: "Short", key: "short" },
  ],
};

export const getPathName: IGetPathFromFilterData = (filterData) => {
  if (filterData[STREAM_FILTER_CATEGORY] && filterData[DEGREE_FILTER_CATEGORY])
    return `list-of-${filterData[STREAM_FILTER_CATEGORY]}-courses-offering-${filterData[DEGREE_FILTER_CATEGORY]}-degree-in-india`;
  else if (filterData[STREAM_FILTER_CATEGORY])
    return `list-of-${filterData[STREAM_FILTER_CATEGORY]}-courses-in-india`;
  else return "list-of-courses-in-india";
};

const getPathData: IGetFilterDataFromPath = (path) => {
  if (!path) return {};

  const pathPattern =
    /^list-of-(.*?)-courses(?:-offering-(.*?)-degree)?-in-india$/;
  const match = path.match(pathPattern);

  if (!match) {
    return {};
  }

  const result: { [key: string]: string } = {};
  if (match[1]) result[STREAM_FILTER_CATEGORY] = match[1];
  if (match[2]) result[DEGREE_FILTER_CATEGORY] = match[2];

  return result;
};

const CourseIntermediate = () => {
  const listingContainerRef = useRef<HTMLDivElement>(null);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const isMediumDevice = window.innerWidth >= 768;
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);

  const {
    selectedFilter,
    updateSelectedFilter,
    clearFilter,
    filterDataForApiBody,
  } = useSelectedFilter(FILTER_CATEGORIES, "courses", getPathName, getPathData);

  const { searchKeyword, handleKeywordChange, deboundedSearchKeyword } =
    useDebouncedSearch();

  const {
    data: courseData,
    loading: courseDataLoading,
    error: courseDataError,
    fetchData: fetchcourseData,
  } = useAxios<ICourseApiResponse>({
    url: config.apiUrl + "/api/course/course-intermediate-list",
    method: "POST",
  });

  // TODO - remove stream name from the api url, also remove this fetch itself by combining degree data with main API
  const {
    data: degreeFilterData,
    loading: degreeFilterDataLoading,
    error: degreeFilterDataError,
    fetchData: fetchdegreeFilterData,
  } = useAxios<IDegreeApiResponse[]>({
    url:
      config.apiUrl +
      "/api/course/get-course-list/engineering-and-architecture/bachelor",
    method: "GET",
  });

  const {
    totalPages,
    currentPage,
    updateTotalCount,
    handlePrevPage,
    handleNextPage,
    handleResetPagination,
  } = useQueryParamPagination(PAGE_SIZE);

  useEffect(() => {
    if (!selectedFilter.radioFilters[sortByData.categoryKey]) {
      updateSelectedFilter(
        sortByData.categoryKey,
        FilterActions.ChangeRadio,
        sortByData.options[0].key
      );
    }
  }, []);

  useEffect(() => {
    if (filterDataForApiBody)
      fetchcourseData(
        {},
        {
          ...filterDataForApiBody,
          sortDirection: "asc",
          offset: currentPage - 1,
          fetchCount: PAGE_SIZE,
          search: deboundedSearchKeyword,
        }
      );
  }, [currentPage, deboundedSearchKeyword, filterDataForApiBody]);

  useEffect(() => {
    // reset page whenever filter changes
    handleResetPagination();
    if (
      !(
        filterDataForApiBody &&
        filterDataForApiBody[STREAM_FILTER_CATEGORY] &&
        filterDataForApiBody[STREAM_FILTER_CATEGORY][0]
      )
    )
      return;

    fetchdegreeFilterData({
      url:
        config.apiUrl +
        `/api/course/get-course-list/${
          filterDataForApiBody &&
          filterDataForApiBody[STREAM_FILTER_CATEGORY] &&
          filterDataForApiBody[STREAM_FILTER_CATEGORY][0]
        }/${
          (filterDataForApiBody &&
            filterDataForApiBody[COURSE_CATEGORY_FILTER_CATEGORY] &&
            filterDataForApiBody[COURSE_CATEGORY_FILTER_CATEGORY][0]) ||
          "all"
        }`,
    });
  }, [filterDataForApiBody]);

  useEffect(() => {
    updateTotalCount(courseData?.totalCourseCount || 0);
  }, [courseData]);

  useEffect(() => {
    if (!isFirstLoad && listingContainerRef.current) {
      listingContainerRef.current.scrollIntoView();
      console.log("change scroll");
    }
  }, [currentPage]);

  const courseList: ICollege[] | null = courseData?.list || null;

  const filterData: IFilterDataItem[] = [];

  if (!isMediumDevice) filterData.push(sortByData);

  if (courseData && courseData.availableStreams)
    filterData.push({
      categoryName: "EDUCATIONAL INTEREST",
      categoryKey: STREAM_FILTER_CATEGORY,
      type: FilterCategory.SingleSelect,
      options: courseData.availableStreams.map((item) => ({
        count: item.courseCount,
        name: item.streamTitle,
        key: item.streamName,
      })),
    });

  filterData.push(passionFilterData);
  filterData.push(popularFilterData);
  filterData.push(privilegedFilterData);
  filterData.push(courseCategoryFilterData);

  // TODO - remove this temporary fix
  if (
    degreeFilterData &&
    filterDataForApiBody &&
    filterDataForApiBody[STREAM_FILTER_CATEGORY]
  )
    filterData.push({
      categoryName: "DEGREE OFFERED",
      categoryKey: DEGREE_FILTER_CATEGORY,
      type: FilterCategory.MultiSelect,
      options: degreeFilterData.map((item) => ({
        count: item.courseCount,
        name: item.degreeTitle,
        key: item.degreeName,
      })),
    });

  // TODO - remove this temporary fix
  const streamTitle =
    filterDataForApiBody &&
    filterDataForApiBody[STREAM_FILTER_CATEGORY] &&
    courseData &&
    courseData.availableStreams.find(
      (item) =>
        item.streamName == filterDataForApiBody[STREAM_FILTER_CATEGORY][0]
    )?.streamTitle;

  const bannerTitle = streamTitle && `List of ${streamTitle} courses in India`;
  const bannerDescription = courseData && courseData.streamDescription;
  const colour = "blue";

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [wishlistData, setWishlistData] = useState<{ courseId: number }[]>([]);
  console.log(wishlistData);

  useEffect(() => {
    // const userId = JSON.parse(localStorage.getItem("user") ?? "{}")?.userId;
    // const accessToken = localStorage.getItem("accessToken");
    // BaseURL.get(`/api/wishlist/category/${userId}?category=course`, {
    //   headers: {
    //     Authorization: accessToken ? `${accessToken}` : "",
    //   },
    // })
    //   .then((response) => {
    //     setWishlistData(response.data.data);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching wishlist data:", error);
    //   });
  }, []);

  return (
    <div className="bg-grey-2 text-black font-inter">
      <Banner title={bannerTitle} description={bannerDescription} />
      <div ref={listingContainerRef} className="container max-w-screen-2xl ">
        <div className="flex gap-8 justify-center pt-12">
          <TabSelector
            filterData={courseCategoryFilterData}
            selectedFilter={selectedFilter}
            updateSelectedFilter={updateSelectedFilter}
            clearFilter={clearFilter}
            color={colour}
          />
        </div>
        <div className="flex gap-6 py-12 max-sm:py-6">
          {isMediumDevice && (
            <div className="w-[30%]">
              <DesktopFilterMenu
                filterData={filterData.filter(
                  (filterItem) =>
                    filterItem.categoryKey != COURSE_CATEGORY_FILTER_CATEGORY
                )}
                selectedFilter={selectedFilter}
                updateSelectedFilter={updateSelectedFilter}
                clearFilter={clearFilter}
              />
            </div>
          )}
          {!isMediumDevice && showMobileFilter && (
            <MobileFilterMenu
              filterData={filterData}
              selectedFilter={selectedFilter}
              updateSelectedFilter={updateSelectedFilter}
              clearFilter={clearFilter}
              closeFilter={() => setShowMobileFilter(false)}
            />
          )}
          <div className="w-full md:w-[70%] flex flex-col gap-6">
            <FilterChips
              filterData={filterData}
              selectedFilter={selectedFilter}
              updateSelectedFilter={updateSelectedFilter}
            />
            <div className="flex items-center justify-between gap-4">
              {!isMediumDevice && (
                <button
                  className="bg-white rounded-md p-3"
                  onClick={() => setShowMobileFilter(true)}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    color="white"
                    stroke="#7D7B89"
                    size="lg"
                    strokeWidth={40}
                  />
                </button>
              )}
              {isMediumDevice && (
                <div className="text-base md:text-lg text-grey shrink-0 min-w-48">
                  {!courseDataLoading &&
                  !courseDataError &&
                  courseData?.totalCourseCount
                    ? `Showing ${courseData?.totalCourseCount} Courses`
                    : "Loading..."}
                </div>
              )}
              <div className="flex-1 flex items-center bg-white pr-4 rounded-md overflow-hidden">
                <input
                  className="p-2 text-base placeholder-grey w-full"
                  value={searchKeyword}
                  onChange={(e) => handleKeywordChange(e.target.value)}
                  placeholder={
                    isMediumDevice ? "Search your dream course" : "Search"
                  }
                />
                {searchKeyword && searchKeyword.length > 0 ? (
                  <button onClick={() => handleKeywordChange("")}>
                    <FontAwesomeIcon
                      icon={faX}
                      size="sm"
                      className="text-grey"
                    />
                  </button>
                ) : (
                  <FontAwesomeIcon
                    icon={faSearch}
                    size="sm"
                    className="text-grey"
                  />
                )}
              </div>
              {isMediumDevice && (
                <SortByDropdown
                  selectedFilter={selectedFilter}
                  updateSelectedFilter={updateSelectedFilter}
                  filterDropdownData={sortByData}
                />
              )}
            </div>
            {courseDataLoading && <Loader />}
            {!courseDataLoading && !courseDataError && courseList && (
              <>
                {!isMediumDevice && (
                  <div className="text-base md:text-lg text-grey shrink-0 min-w-48">
                    {!courseDataLoading &&
                    !courseDataError &&
                    courseData?.totalCourseCount
                      ? `Showing ${courseData?.totalCourseCount} Courses`
                      : "Loading..."}
                  </div>
                )}
                {courseList.map((item) => (
                  <CourseCard
                    data={item}
                    isWishlisted={
                      wishlistData.find(
                        (course) => course.courseId == item.courseId
                      ) != undefined
                    }
                  />
                ))}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handleNextPage={handleNextPage}
                  handlePrevPage={handlePrevPage}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseIntermediate;
