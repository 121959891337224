import React from "react";

const mentorshipData = [
  {
    title: "Career Assessment Test",
    description: "Uncover your potential with our advanced test.",
    icon: "/images/career-assesment-icon.webp",
  },
  {
    title: "Personalized Career Counseling",
    description: "Get one-on-one guidance from certified experts.",
    icon: "/images/personlized-counselling.webp",
  },
  {
    title: "College Counseling & Admission Help",
    description: "Find the best college based on your preferences.",
    icon: "/images/admission-help.webp",
  },
  {
    title: "Scholarship & Financial Aid Support",
    description: "Access guidance on scholarships and education loans.",
    icon: "/images/financial-support.webp",
  },
  {
    title: "Mentorship from Experts",
    description: "Learn from industry leaders and top alumni.",
    icon: "/images/mentorship-experts.webp",
  },
  {
    title: "Internship Opportunities",
    description: "Gain hands-on experience with reputed organizations.",
    icon: "/images/internship-oppurtunities.webp",
  },
  {
    title: "International Education",
    description:
      "Assistance with university selection, applications, and visas.",
    icon: "/images/international-education.webp",
  },
  {
    title: "Entrepreneurship Guidance",
    description: "Support for business planning and funding for startups.",
    icon: "/images/enterprenuiship-guidence.webp",
  },
  {
    title: "Competitive Exam Coaching",
    description: "Expert coaching for UPSC, SSC, banking, and more.",
    icon: "/images/exam-coaching.webp",
  },
  {
    title: "24x7 College Mentor Support",
    description: "Lifetime support for students and parents.",
    icon: "/images/mentor-support.webp",
  },
];

const HandHoldingMentorship = () => {
  return (
    <div className="w-full bg-[#E9F3EE] flex flex-col items-center py-10">
      <div className="container flex flex-col">
        <h3 className="text-[#0C9] text-center  text-[36px] font-bold">
          College Mentor Hand-Holding Mentorship
        </h3>
        <p className="text-[#173CBA] text-center  text-[32px] font-normal leading-[50px]">
          Inspiring Minds | Shaping Futures
        </p>

        <div className="flex flex-wrap justify-center gap-x-[158px]  gap-y-6 mt-6">
          {mentorshipData.map((item, index) => (
            <div
              key={index}
              className="flex w-[560px] p-[10px] pl-[18px] pr-[10px] items-center gap-[15px] border border-[#0C9] bg-white shadow-md rounded-lg"
            >
              <img
                src={item.icon}
                alt={item.title}
                className="w-10 h-10 object-contain"
                loading="lazy"
              />
              <div>
                <h3 className="font-bold">{item.title}</h3>
                <p className="text-black">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HandHoldingMentorship;
