import React, { ChangeEvent, useRef, useState } from "react";
import "./ScholarshipResultBanner.scss";
import axios from "axios";
import config from "../../../util/config";
import { useReactToPrint } from "react-to-print";
import Certificate from "../ParticipationCertificate";
import CertificateGenerator from "../Certificates";
import html2canvas from "html2canvas";
import moment from "moment";
import ParticipationCertificate from "../ParticipationCertificate";
import { BaseURLAuth } from "../../../services/api";

const logos = [
  { imageUrl: "/images/bennet-university.webp", slug: "bennet-university" },
  {
    imageUrl: "/images/malla-reddy-university.webp",
    slug: "malla-reddy-university",
  },
  { imageUrl: "/images/aditya-university.webp", slug: "aditya-university" },
  { imageUrl: "/images/gd-goenka.webp", slug: "gd-goenka" },
  { imageUrl: "/images/bennet-university.webp", slug: "bennet-university" },
  {
    imageUrl: "/images/chandigarh-university.webp",
    slug: "chandigarh-university",
  },
  {
    imageUrl: "/images/malla-reddy-university.webp",
    slug: "malla-reddy-university",
  },
  { imageUrl: "/images/aditya-university.webp", slug: "aditya-university" },
  { imageUrl: "/images/gd-goenka.webp", slug: "gd-goenka" },
  { imageUrl: "/images/bennet-university.webp", slug: "bennet-university" },
  {
    imageUrl: "/images/chandigarh-university.webp",
    slug: "chandigarh-university",
  },
  {
    imageUrl: "/images/malla-reddy-university.webp",
    slug: "malla-reddy-university",
  },
  { imageUrl: "/images/aditya-university.webp", slug: "aditya-university" },
  { imageUrl: "/images/gd-goenka.webp", slug: "gd-goenka" },
  { imageUrl: "/images/bennet-university.webp", slug: "bennet-university" },
  {
    imageUrl: "/images/chandigarh-university.webp",
    slug: "chandigarh-university",
  },
];

interface OtpResponse {
  message: string;
  data: {
    headers: Record<string, string>;
    body: {
      accessToken?: string;
      statusCode?: number;
      message?: string;
    };
  };
}

interface ScholarshipResult {
  isWinner?: boolean;
  cash_prize_scholarship_worth?: string;
  cash_prize?: string;
  college_mentor_worth_services?: string;
  name?: string;
  CategoryName?: string;
  rank?: number;
  applicationId?: number;
  prize?: string;
  statusCode?: number;
  message?: string;
}

const ScholarshipResultBanner = () => {
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<ScholarshipResult | null>(null);
  const [otpError, setOtpError] = useState<string>(""); // New state for OTP error
  const certificateRef = useRef<HTMLDivElement>(null);
  const [showExamNotTakenPopup, setShowExamNotTakenPopup] =
    useState<boolean>(false);
  const handleDownload = () => {
    if (certificateRef.current) {
      html2canvas(certificateRef.current, { scale: 2 }).then((canvas) => {
        const link = document.createElement("a");
        link.download = `${result?.name}_Certificate.png`;
        link.href = canvas.toDataURL("image/png");
        link.click();
      });
    }
  };
  const renderResultText = () => {
    if (!result) return null;

    const awards = [
      result.cash_prize_scholarship_worth &&
        `the Partnered Universities Scholarship worth ${result.cash_prize_scholarship_worth}`,
      result.cash_prize && `a cash prize of ${result.cash_prize}`,
      result.prize && `a special prize of ${result.prize}`,
      `College Mentor Worth Services valued at ${result.college_mentor_worth_services}`,
    ].filter(Boolean);

    const text = result.isWinner
      ? `You have been awarded ${
          awards.length > 1
            ? awards.slice(0, -1).join(", ") + " along with " + awards.slice(-1)
            : awards[0]
        }.`
      : `You have been awarded ${
          awards.length > 1
            ? awards.slice(0, -1).join(", ") + " along with " + awards.slice(-1)
            : awards[0]
        } for your participation in the ${result.CategoryName}.`;

    return (
      <p
        className="text-gray-800 mb-4 text-center text-[16px] max-md:text-[12px] leading-relaxed"
        dangerouslySetInnerHTML={{
          __html: text.replace(
            /(the Partnered Universities Scholarship worth .*?|a cash prize of .*?|a special prize of .*?|College Mentor Worth Services valued at .*?)/g,
            '<span class="text-[#173CBA] font-semibold">$1</span>'
          ),
        }}
      />
    );
  };

  // Request OTP
  const handleVerify = async (): Promise<void> => {
    if (mobileNumber.length !== 10 || !/^\d{10}$/.test(mobileNumber)) {
      setOtpError("Please enter a valid 10-digit mobile number.");
      return;
    }
    setOtp("");
    setIsLoading(true);
    setOtpError(""); // Clear any existing error
    try {
      const response = await axios.post(
        `${config.apiUrl}/api/scholarship/initiate`,
        {
          phoneNumber: mobileNumber,
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            "X-Auth-Token":
              "e711724f44c71abf0c2794572472ed6edcea55a351f6428fe67060a692aa9316",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setIsVerified(true);
      }
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data?.message) {
        setOtpError(e.response.data.message);
      } else {
        console.error("Error sending OTP:", e);
        setOtpError("An error occurred while sending OTP. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Verify OTP and get results
  const handleSubmit = async (): Promise<void> => {
    if (otp.length === 0) {
      setOtpError("Please enter the OTP.");
      return;
    }

    setIsLoading(true);
    setShowExamNotTakenPopup(false);
    setOtpError(""); // Clear any existing error
    try {
      // Validate OTP
      const otpResponse = await axios.post(
        `${config.apiUrl}/api/scholarship/validate-otp`,
        {
          mobileNumber: mobileNumber,
          otp: otp,
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );

      const otpData: OtpResponse = otpResponse.data;
      const innerBody = otpData?.data?.body;

      // Check inner statusCode even if HTTP status is 200
      if (innerBody?.statusCode && innerBody.statusCode >= 400) {
        if (
          innerBody.statusCode === 400 &&
          innerBody.message === "Invalid OTP"
        ) {
          setOtpError("Invalid OTP. Please try again.");
          return;
        }
        setOtpError(
          innerBody.message ||
            `OTP validation failed (Code: ${innerBody.statusCode})`
        );
        return;
      }

      const accessToken = innerBody?.accessToken;
      if (!accessToken) {
        setOtpError("No access token received. Please try again.");
        return;
      }

      localStorage.setItem("accessToken", accessToken);

      // Fetch scholarship result
      const resultResponse = await BaseURLAuth.get(
        `${config.apiUrl}/api/scholarship-result`,
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );

      const resultData: ScholarshipResult = resultResponse.data;
      if (resultResponse.status >= 200 && resultResponse.status < 300) {
        if (!resultData || !resultData.name) {
          setOtpError("Invalid scholarship result data received.");
          return;
        }
        if (resultData.statusCode === 404) {
          if (
            resultData?.message === "Student record not found or exam not taken"
          )
            setShowExamNotTakenPopup(true);
          else
            setOtpError(
              resultData?.message ||
                "Student record not found or exam not taken."
            );
          return;
        }
        setResult(resultData);
      } else {
        setOtpError(
          `Failed to fetch results (Status: ${resultResponse.status})`
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const status = error.response?.status;
        const responseData = error.response?.data;
        const innerBody = responseData?.data?.body;

        if (status === 200 && innerBody?.statusCode >= 400) {
          if (
            innerBody.statusCode === 400 &&
            innerBody.message === "Invalid OTP"
          ) {
            setOtpError("Invalid OTP. Please try again.");
          } else {
            setOtpError(
              innerBody.message || `Error (Code: ${innerBody.statusCode})`
            );
          }
        } else if (status === 400) {
          setOtpError(responseData?.message || "Invalid request data.");
        } else if (status === 401) {
          setOtpError("Unauthorized: Please check your credentials.");
        } else if (status === 404) {
          if (
            responseData?.message ===
            "Student record not found or exam not taken"
          )
            setShowExamNotTakenPopup(true);
          else
            setOtpError(
              responseData?.message ||
                "Student record not found or exam not taken."
            );
        } else if (status === 503) {
          setOtpError("Service unavailable. Please try again later.");
        } else if (status === 500) {
          setOtpError("Server error. Please try again later.");
        } else {
          setOtpError(
            responseData?.message || "An error occurred. Please try again."
          );
          console.error("Axios error:", error.response?.data || error.message);
        }
      } else {
        console.error("Unexpected error in handleSubmit:", error);
        setOtpError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input changes
  const handleMobileNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setMobileNumber(e.target.value.replace(/\D/g, ""));
    setOtpError(""); // Clear error when user types
    setShowExamNotTakenPopup(false);
  };

  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setOtp(e.target.value.replace(/\D/g, ""));
    setOtpError(""); // Clear error when user types
    setShowExamNotTakenPopup(false);
  };
  const closePopup = () => setShowExamNotTakenPopup(false);

  return (
    <div className="w-full lg:min-h-[540px] min-max-md:min-h-[600px] overflow-hidden flex-shrink-0 bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED] flex justify-center items-end relative max-md:pt-5">
      {/* Certificate for printing (hidden off-screen) */}
      {result && (
        <div
          style={{
            position: "absolute",
            left: "-9999px",
            isolation: "isolate", // Prevents parent CSS from affecting this
          }}
        >
          <div ref={certificateRef}>
            {result?.isWinner ? (
              <CertificateGenerator
                defaultName={result?.name}
                defaultPresentedTo={result?.name}
                defaultContent={`Category : ${result?.CategoryName}`}
                defaultFinancialAward={`Rs ${result?.cash_prize} + 7500 Scholarship at partner universities + CM Services`}
                defaultCertificateId={`CMST-${result?.applicationId}`}
                defaultDate={moment().format("MMMM D, YYYY")}
              />
            ) : (
              <ParticipationCertificate
                defaultName={result?.name}
                defaultPresentedTo={result?.name}
                defaultContent="Talent Category : Arts & Sports"
                defaultFinancialAward="Rs 75000 + 7500 Scholarship at partner universities + CM Services"
                defaultCertificateId={`CMST-${result?.applicationId}`}
                defaultDate={moment().format("MMMM D, YYYY")}
              />
            )}
          </div>
        </div>
      )}

      {showExamNotTakenPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
            <div className="flex items-center justify-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-yellow-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h3 className="text-xl font-bold text-gray-800 text-center">
              Exam Not Taken Yet!
            </h3>
            <p className="text-gray-600 text-center mt-2">
              It looks like you haven’t participated in the College Mentor
              Scholarship Test yet. Don’t miss out on amazing scholarship
              opportunities!
            </p>

            <button
              onClick={closePopup}
              className="mt-4 text-gray-500 hover:text-gray-700 text-sm w-full text-center"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="container flex justify-between relative max-md:flex-col">
        <div className="w-[327px] max-md:w-full md:absolute lg:-top-12 min-max-md:-top-[37%] lg:left-0 min-max-md:left-[5%]">
          <h3 className="text-[32px] md:h-[150px] font-bold text-[#173CBA] leading-[40px] font-roboto text-left max-md:text-center">
            India's Top NIRF & QS Ranked Universities
          </h3>
        </div>

        <div className="w-[300px] min-max-md:w-[300] md:hidden mt-4 text-center flex flex-col mx-auto leading-6 mb-4">
          <p className="text-[18px] min-max-md:text-[14px] font-bold text-[#173CBA] font-roboto text-center">
            Open Admissions for 2025
          </p>
          <p className="text-[18px]  min-max-md:text-[14px] font-normal text-[#173CBA] font-roboto text-center">
            Through College Mentor Scholarship Test and +2 Marks.
          </p>
        </div>

        <div
          className="flex flex-col stats-bg w-[925px] min-max-md:mt-10 min-max-md:h-[413px] h-[462.5px] min-max-xs:w-[371px] min-max-xss:w-[93%] max-md:h-[184px] bg-[#FFF9E5] rounded-b-full justify-start mx-auto items-center relative"
          style={{ borderRadius: "925px 925px 0 0" }}
        >
          <div className="animate max-lg:hidden ">
            <ul className="flex flex-wrap justify-center gap-6">
              {logos.map((logo, index) => (
                <li
                  key={index}
                  className="w-24 h-24 bg-white p-2 shadow-md rounded-full flex items-center justify-center"
                >
                  <a href={`/college-details/${logo.slug}`}>
                    <img
                      src={logo.imageUrl}
                      alt="Company Logo"
                      className="w-full h-auto object-contain"
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {result ? (
            <h3 className="text-center font-roboto mt-16 max-md:mt-7">
              <span className="text-[#22C55E] text-[36px] max-md:text-[14px] special-word font-bold max-md:leading-4 leading-[48px]">
                Congratulations
              </span>
              <br />
              <span className="text-[#173CBA] text-[24px] max-md:text-[10px] font-normal max-md:leading-3 leading-[36px]">
                {result.name}!
              </span>
            </h3>
          ) : (
            <h3 className="text-[#173CBA] text-center font-roboto text-[24px] max-md:text-[10px] font-normal max-md:leading-3 leading-[36px] mt-16 max-md:mt-7">
              College Mentor Scholarship Test <br /> Result 2025
            </h3>
          )}

          <div className="mt-6 max-md:mt-1 flex flex-col items-center gap-1 w-[467px] max-md:w-[187px] z-10">
            {!result ? (
              <>
                <label className="text-[#747474] text-left max-md:text-[10px] max-md:leading-3 text-[20px] font-normal leading-[36px] w-full">
                  Mobile Number
                </label>
                <div className="w-full flex gap-2">
                  <input
                    type="text"
                    placeholder="Your registered mobile number"
                    value={mobileNumber}
                    onChange={handleMobileNumberChange}
                    maxLength={10}
                    className="w-full h-[42px] border max-md:h-[18px] border-[#89C9ED] max-md:text-[10px] max-md:leading-3 focus:outline-none focus:border-[#89C9ED] bg-white px-4 rounded-md text-[#A3A2AC] text-center"
                  />
                  <button
                    onClick={handleVerify}
                    disabled={isLoading}
                    className="px-3 py-2 bg-[#0C9] text-white font-bold rounded-md max-md:text-[10px] disabled:opacity-50"
                  >
                    {isLoading && !isVerified ? "Sending..." : "Verify"}
                  </button>
                </div>

                <div className="w-full relative">
                  <input
                    type="text"
                    placeholder="OTP"
                    value={otp}
                    onChange={handleOtpChange}
                    disabled={!isVerified || isLoading}
                    className={`w-full h-[42px] border max-md:h-[18px] max-md:text-[10px] max-md:leading-3 focus:outline-none focus:border-[#89C9ED] border-[#89C9ED] bg-white px-4 rounded-md text-[#A3A2AC] text-center mt-4 max-md:mt-1 disabled:opacity-50 ${
                      otpError ? "border-red-500" : ""
                    }`}
                  />
                  {otpError && (
                    <p className="text-red-500 text-sm max-md:text-[10px] mt-1 text-left">
                      {otpError}
                    </p>
                  )}
                </div>

                <button
                  onClick={handleSubmit}
                  disabled={!isVerified || otp.length === 0 || isLoading}
                  className="p-3 max-md:py-1 max-md:px-2 bg-[#0C9] max-md:text-[10px] flex justify-center text-white font-bold rounded-md mt-2 max-md:mt-1 items-center disabled:opacity-50"
                >
                  {isLoading && isVerified ? "Submitting..." : "SUBMIT"}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    className="ml-2"
                  >
                    <path
                      d="M1.5 5.25C1.08579 5.25 0.75 5.58579 0.75 6C0.75 6.41421 1.08579 6.75 1.5 6.75L1.5 5.25ZM15.0303 6.53033C15.3232 6.23744 15.3232 5.76256 15.0303 5.46967L10.2574 0.6967C9.96447 0.403806 9.48959 0.403806 9.1967 0.6967C8.90381 0.989593 8.90381 1.46447 9.1967 1.75736L13.4393 6L9.1967 10.2426C8.90381 10.5355 8.90381 11.0104 9.1967 11.3033C9.48959 11.5962 9.96447 11.5962 10.2574 11.3033L15.0303 6.53033ZM1.5 6.75L14.5 6.75L14.5 5.25L1.5 5.25L1.5 6.75Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </>
            ) : (
              <div className="w-full">
                {renderResultText()}
                <div className="border-t border-b border-gray-300 py-2 my-4">
                  <p className="text-gray-700 text-center">
                    Category:{" "}
                    <span className="font-semibold">{result.CategoryName}</span>{" "}
                    <button
                      onClick={handleDownload}
                      className="mt-2 px-4 py-2 bg-[#173CBA] text-white rounded-md hover:bg-blue-700 transition-colors"
                    >
                      Download Certificate
                    </button>
                  </p>
                </div>
                <p className="text-gray-800 text-center">
                  For more information, please call:
                  <br />
                  Telangana:{" "}
                  <span className="text-[#173CBA] font-semibold">
                    8959591818 , Andhra Pradesh: 9176407555
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="w-[299px] md:absolute lg:-top-12 min-max-md:-top-[37%]  md:right-0 min-max-md:right-[5%] max-md:hidden">
          <p className="text-[24px] font-bold text-[#173CBA] leading-[36px] font-roboto text-right">
            Open Admissions for 2025
          </p>
          <p className="text-[20px] font-normal text-[#173CBA] leading-[30px] font-roboto text-right">
            Through College Mentor Scholarship Test and <br />
            +2 Marks.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipResultBanner;
