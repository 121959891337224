import React from "react";

export const BoardTabs = () => {
  return (
    <div className="bg-white p-4 flex justify-center container ">
      <div className="bg-[#173CBA] border border-[#7C7C7C] rounded-md px-6 py-3 text-center w-full">
        <h3 className="text-white text-2xl font-normal leading-normal ">
          Start Board / National Board / International Board
        </h3>
      </div>
    </div>
  );
};
