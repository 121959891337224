import React, { useState, useRef, useEffect } from "react";
import "./TopCollegeStyles.scss";

const slugify = (text: string) =>
  text
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "");

const collegeData = [
  {
    name: "Aditya University",
    location: "Surampalem, Andhra Pradesh",
    logo: "/images/aditya-university.webp",
    coords: { x: (420 / 1098) * 100, y: (900 / 1200) * 100 },
  },
  {
    name: "Bennett University",
    location: "Greater Noida, Uttar Pradesh",
    logo: "/images/bennet-university.webp",
    coords: { x: (350 / 1098) * 100, y: (340 / 1142) * 100 },
  },
  {
    name: "Chandigarh University",
    location: "Chandigarh State, Punjab",
    logo: "/images/chandigarh-university.webp",
    coords: { x: (330 / 1098) * 100, y: (265 / 1142) * 100 },
  },
  {
    name: "GD Goenka University",
    location: "Gurugram, Haryana",
    logo: "/images/gd-goenka.webp",
    coords: { x: (320 / 1098) * 100, y: (330 / 1142) * 100 },
  },
  {
    name: "Malla Reddy University",
    location: "Hyderabad, Telangana",
    logo: "/images/malla-reddy-university.webp",
    coords: { x: (420 / 1098) * 100, y: (790 / 1200) * 100 },
  },
  {
    name: "Sai University",
    location: "Paiyanur, Tamil Nadu",
    logo: "/images/sai-university.webp",
    coords: { x: (400 / 1098) * 100, y: (1010 / 1142) * 100 },
  },
  {
    name: "S-VYASA University",
    location: "Bangalore, Giddenahalli, Karnataka",
    logo: "/images/s-vyasa.webp",
    coords: { x: (280 / 1098) * 100, y: (890 / 1142) * 100 },
  },
];

const TopCollegesUniversities: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  const handleDotClick = (index: number) => {
    setActiveIndex(index);
    const slug = slugify(collegeData[index].name);
    window.history.pushState(null, "", `#campus-${slug}`);

    cardRefs.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash.startsWith("#campus-")) {
      const slug = hash.replace("#campus-", "");
      const index = collegeData.findIndex(
        (college) => slugify(college.name) === slug
      );
      if (index !== -1) {
        setActiveIndex(index);
        setTimeout(() => {
          cardRefs.current[index]?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }, 100);
      }
    }
  }, []);

  return (
    <div className="w-full bg-[#E2F3FF] flex flex-col items-center pt-4 pb-16 mt-1">
      <div className="container flex flex-col">
        <div className="text-center">
          <h2 className="text-[#0C9] text-[36px] max-md:text-[32px] font-bold leading-[50px]">
            Top 100+ Partner Colleges and Universities
          </h2>
          <p className="text-[#173CBA] text-[32px] max-md:text-[24px] font-normal leading-[50px]">
            Associated with College Mentor
          </p>
        </div>

        <div className="flex flex-col max-md:gap-10 lg:flex-row items-center md:mt-6 justify-between xl:mx-20">
          <div className="relative w-[501px] h-[574px] max-md:w-[313px] max-md:h-[347px]">
            <img
              src="/images/india-map.webp"
              alt="India Map"
              className="w-full h-full object-contain"
            />
            {collegeData.map((college, index) =>
              college.coords ? (
                <div
                  key={index}
                  className="absolute"
                  style={{
                    left: `${college.coords.x}%`,
                    top: `${college.coords.y}%`,
                    transform: "translate(-50%, -50%)",
                    zIndex:
                      hoveredIndex === index || activeIndex === index ? 10 : 1,
                  }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <div
                    className={`w-2 h-2 rounded-full transition-all duration-200 cursor-pointer ${
                      index === activeIndex
                        ? "bg-blue hover:bg-blue pulse-blue-dot"
                        : "bg-red hover:bg-red pulse-red-dot"
                    }`}
                    onClick={() => handleDotClick(index)}
                  />

                  {hoveredIndex === index && (
                    <span
                      onClick={() => handleDotClick(index)}
                      className="absolute top-2 left-1/2 -translate-x-1/2 whitespace-nowrap bg-white text-black text-xs px-2 py-1 border border-gray-300 rounded shadow-md z-10 cursor-pointer hover:bg-blue-100"
                    >
                      {college.name}
                    </span>
                  )}
                </div>
              ) : null
            )}
          </div>

          <div className="flex flex-col gap-[15px] h-[574px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 pr-4 max-md:pr-1">
            {collegeData.map((college, index) => (
              <div
                key={index}
                ref={(el) => (cardRefs.current[index] = el)}
                onClick={() => handleDotClick(index)}
                className={`w-[460px] max-md:w-[371px] flex items-center gap-4 border ${
                  index === activeIndex
                    ? "border-blue border-2 shadow-xl"
                    : "border-transparent border-2"
                } bg-white shadow-md rounded-lg p-[10px] cursor-pointer`}
              >
                <div className="w-[140px] max-md:w-[118px] h-[80px] flex-shrink-0 border-[0.5px] border-[#A3A2AC] bg-white flex items-center justify-center">
                  <img
                    src={college.logo}
                    alt={college.name}
                    className="w-[116px] max-md:w-[97px] h-[37px] object-contain"
                  />
                </div>

                <div>
                  <h3 className="text-[#383838] text-[18px] font-bold leading-[28px]">
                    {college.name}
                  </h3>
                  <p className="text-[#383838] text-[18px] font-normal leading-[28px]">
                    {college.location}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCollegesUniversities;
