import React from "react";
import educationDetailsBanner from "../../../images/education-details-banner.webp";
import { EpRight } from "../../../components/study-abroad-landing/WhyStudyAbroad";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";

interface EducationBannerProps {
  title: string;
  description: string;
}

const EducationBanner: React.FC<EducationBannerProps> = ({
  title,
  description,
}) => {
  return (
    <div
      className="w-full text-white pt-10"
      style={{
        background:
          "linear-gradient(90.13deg, #0B3D8C 0.11%, #107CC4 54.49%, rgba(6, 175, 169, 0.745055) 83.43%)",
      }}
    >
      <div className="container mx-auto flex flex-col gap-6 md:pr-0">
        <div className="flex flex-col md:flex-row gap-5">
          <div className="flex flex-col w-full md:w-1/2 py-7 md:py-20 justify-content-center">
            <div className="max-w-full sm:max-w-[497px]">
              <h1 className="text-2xl md:text-4xl font-semibold leading-snug">
                {title}
              </h1>

              <p className="text-lg md:text-xl font-normal leading-relaxed">
                {description}
              </p>

              <div className="flex gap-4 mt-10">
              <LeadGenCtaBtn
                pageName="home-landing"
                ctaName="dream-colleges-apply-now"
                className="bg-green hover:bg-green text-white px-6 py-3 rounded font-semibold shadow flex items-center gap-2"
              >
                Apply Now
              </LeadGenCtaBtn>
                <button className="bg-[#FF7BCD] hover:bg-[#FF7BCD] text-white px-6 py-3 rounded font-semibold shadow flex items-center gap-2">
                  Sample Papers{" "}
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.71181 4.38641H7.28792V0.194951C7.28792 0.084479 7.19694 0 7.08646 0H4.19793C4.08746 0 3.99648 0.084479 3.99648 0.194951V4.38966H1.57259C1.41663 4.38966 1.33215 4.57811 1.43612 4.69833L5.50086 9.28619C5.57234 9.36417 5.70231 9.36417 5.77379 9.28619L9.84503 4.69833C9.949 4.57486 9.86452 4.38966 9.70856 4.38966L9.71181 4.38641Z"
                      fill="white"
                    />
                    <path
                      d="M11.2844 9.76704V12.2364C11.2844 12.6556 10.9433 13 10.5144 13H0.76356C0.344414 13 0 12.6588 0 12.2364V9.76704C0 9.34789 0.341165 9.00348 0.76356 9.00348C1.18595 9.00348 1.52712 9.34464 1.52712 9.76704V11.4729H9.75407V9.76704C9.75407 9.34789 10.0952 9.00348 10.5176 9.00348C10.94 9.00348 11.2877 9.34464 11.2877 9.76704H11.2844Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex items-end">
            <img
              src={educationDetailsBanner}
              alt="education details banner"
              className="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationBanner;
