import React from "react";
import { Link } from "react-router-dom";

interface Board {
  id: number;
  boardName: string;
  boardTitle: string;
}

interface StateBoard {
  stateBoardName: string;
  boards: Board[];
}

export interface EducationBoard {
  boardLevel: string;
  stateBoards: StateBoard[];
}

interface BoardSelectionProps {
  boards: EducationBoard[];
}

const BoardSelection: React.FC<BoardSelectionProps> = ({ boards }) => {
  return (
    <div className="w-full items-center mb-3 ">
      <div className="flex flex-col">
        {boards.map((boardCategory, index) => (
          <div
            key={index}
            className=" bg-[#F4F4F4] py-6 mb-4 lg:px-20 max-lg:px-7 "
          >
            <h3 className="w-full py-[18px] px-6 text-lg border border-gray-300 text-[#7C7C7C] bg-white rounded-md mb-5">
              {boardCategory.boardLevel}
            </h3>
            {/* --- CHANGE IS HERE --- */}
            {/* Use Grid for explicit column control */}
            {/* grid-cols-1: Default (mobile) - 1 column */}
            {/* md:grid-cols-2: Medium screens and up - 2 columns */}
            {/* lg:grid-cols-3: Large screens and up - 3 columns */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {/* --- END OF CHANGE --- */}
              {boardCategory.stateBoards.map((stateBoard, stateIdx) => (
                <div
                  key={stateIdx}
                  // --- CHANGE IS HERE ---
                  // Remove fixed width (md:w-[414px]) and full width (max-md:w-full)
                  // Grid columns will handle the width automatically
                  className="h-[182px] bg-white border border-gray-400 rounded-md p-4"
                  // --- END OF CHANGE ---
                >
                  <h3 className="text-[#173CBA] text-lg font-semibold mb-2">
                    {stateBoard.stateBoardName}
                  </h3>
                  {/* Keep the fixed width for the divider if desired, or make it w-full */}
                  <div className="w-full h-[0.5px] bg-[#A3A2AC] mb-4" />
                  <ul className="text-[#7C7C7C] text-sm space-y-2 overflow-y-auto max-h-28 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
                    {stateBoard.boards.map((board, boardIdx) => (
                      <Link
                        key={board.id} // Use board.id for key if available and unique
                        to={`/education-board/${board.boardName}`}
                        className="block"
                      >
                        <li /* removed key={boardIdx} as it's on the parent Link now */
                          className="flex items-center gap-2 hover:text-[#173CBA] transition-colors duration-150" // Added hover effect
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            className="flex-shrink-0" // Prevent icon shrinking if text is long
                          >
                            <path
                              d="M6.1575 4.46758C6.08721 4.5373 6.03141 4.62025 5.99333 4.71164C5.95526 4.80304 5.93565 4.90107 5.93565 5.00008C5.93565 5.09908 5.95526 5.19711 5.99333 5.28851C6.03141 5.3799 6.08721 5.46285 6.1575 5.53258L9.5925 8.96758C9.66279 9.0373 9.71859 9.12025 9.75666 9.21164C9.79474 9.30304 9.81434 9.40107 9.81434 9.50008C9.81434 9.59909 9.79474 9.69711 9.75666 9.78851C9.71859 9.8799 9.66279 9.96285 9.5925 10.0326L6.1575 13.4676C6.08721 13.5373 6.03141 13.6202 5.99333 13.7116C5.95526 13.803 5.93565 13.9011 5.93565 14.0001C5.93565 14.0991 5.95526 14.1971 5.99333 14.2885C6.03141 14.3799 6.08721 14.4629 6.1575 14.5326C6.29802 14.6723 6.48811 14.7507 6.68625 14.7507C6.88439 14.7507 7.07448 14.6723 7.215 14.5326L10.6575 11.0901C11.0788 10.6682 11.3155 10.0963 11.3155 9.50008C11.3155 8.90382 11.0788 8.33195 10.6575 7.91008L7.215 4.46758C7.07448 4.32789 6.88439 4.24948 6.68625 4.24948C6.48811 4.24948 6.29802 4.32789 6.1575 4.46758Z"
                              fill="currentColor" // Inherit color for hover effect
                            />
                          </svg>
                          {board.boardTitle}
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BoardSelection;