import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../../services/appService";
import "./BookingForm.css";

// Define interfaces
interface Mentor {
  mentorId: string | number;
  name: string;
}

interface Slot {
  slotId: string | number;
  slotDate: string;
  startTime: string;
  endTime: string;
}

interface BookingFormProps {
  token: string | null; // Allow null for token check
  mentors: Mentor[];
  fetchBookings: () => Promise<void>;
  hasActiveBooking: boolean;
}

function BookingForm({
  token,
  mentors,
  fetchBookings,
  hasActiveBooking,
}: BookingFormProps) {
  const [selectedMentor, setSelectedMentor] = useState<string>("");
  const [slots, setSlots] = useState<Slot[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate(); // Hook for navigation

  // Check token on mount and redirect if not present
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const fetchSlots = useCallback(
    async (mentorId: string | number) => {
      if (!token) return; // Prevent API call if no token
      setLoading(true);
      setError("");
      try {
        const response = await apiService.getSlots(token, mentorId);
        setSlots(response.data as Slot[]);
      } catch (err) {
        setError("Failed to load slots");
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  useEffect(() => {
    if (selectedMentor && token) {
      fetchSlots(selectedMentor);
    } else {
      setSlots([]);
      setSelectedSlot("");
    }
  }, [selectedMentor, fetchSlots, token]);

  const handleBookSlot = async (): Promise<void> => {
    if (!token) return; // Prevent booking if no token
    if (!selectedSlot) {
      setError("Please select a slot");
      return;
    }

    const bookingData = {
      studentEmail: "student1@example.com",
      mentorId: parseInt(selectedMentor),
      slotId: parseInt(selectedSlot),
      bookingDate: new Date().toISOString(),
    };

    setLoading(true);
    setError("");
    try {
      await apiService.bookSlot(token, bookingData);
      await fetchBookings();
      setSelectedMentor("");
      setSelectedSlot("");
      setSlots([]);
    } catch (err: any) {
      setError("Failed to book slot: " + (err.response?.data || err.message));
    } finally {
      setLoading(false);
    }
  };

  // If no token, component will redirect before rendering
  return (
    <div className="booking-form">
      <h3>Book a Session</h3>
      {loading && <p className="loading">Loading...</p>}
      {error && <p className="error">{error}</p>}
      {hasActiveBooking && (
        <p className="info">
          You have an active booking (PENDING or APPROVED).
        </p>
      )}
      <div className="form-group">
        <label>Select Mentor:</label>
        <select
          value={selectedMentor}
          onChange={(e) => setSelectedMentor(e.target.value)}
          disabled={loading || hasActiveBooking}
        >
          <option value="">-- Select Mentor --</option>
          {mentors.map((mentor) => (
            <option key={mentor.mentorId} value={mentor.mentorId}>
              {mentor.name}
            </option>
          ))}
        </select>
      </div>
      {selectedMentor && (
        <div className="form-group">
          <label>Select Slot:</label>
          <select
            value={selectedSlot}
            onChange={(e) => setSelectedSlot(e.target.value)}
            disabled={loading || !slots.length}
          >
            <option value="">-- Select Slot --</option>
            {slots.map((slot) => (
              <option key={slot.slotId} value={slot.slotId}>
                {slot.slotDate} {slot.startTime} - {slot.endTime}
              </option>
            ))}
          </select>
        </div>
      )}
      <button
        onClick={handleBookSlot}
        disabled={loading || !selectedSlot || hasActiveBooking}
      >
        {loading ? "Booking..." : "Book Slot"}
      </button>
    </div>
  );
}

export default BookingForm;
