import React, { useEffect, useState } from "react";
import EducationBanner from "./components/EducationBanner";
import KnowledgeHub from "./components/KnowledgeHub";
import EducationSubsribeNow from "./components/EducationSubsribeNow";
import { BoardTabs } from "./components/BoardTabs";
import axios from "axios";
import BoardSelection, { EducationBoard } from "./components/BoardSelection";
import { BaseURL } from "../../services/api";

export const EducationBoards = () => {
  const [educationBoards, setEducationBoards] = useState<EducationBoard[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEducationBoards = async () => {
      try {
        const response = await BaseURL.get("/api/educationBoard/landing-page");
        console.log("API Response:", response.data);
        setEducationBoards(response.data.data);
      } catch (err) {
        console.error("API Fetch Error:", err);
        setError("Failed to load education boards");
      } finally {
        setLoading(false);
      }
    };
    fetchEducationBoards();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <EducationBanner />
      <BoardTabs />
      <BoardSelection boards={educationBoards} />
      <KnowledgeHub />
      <EducationSubsribeNow />
    </div>
  );
};