import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import NavigationBanner from "../../images/navbar/megamenu/NavigationBanner.webp";
import type { SVGProps } from "react";
import { EpRight } from "../college-details/components/NavigationTabs";
import { categories } from "./NavCategories";

export function MingcuteDownLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="currentColor"
          d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z"
        ></path>
      </g>
    </svg>
  );
}

const NavBarBottom = () => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setActiveCategory(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full bg-blue gap-5 hidden md:block">
      <div className="container mx-auto">
        <div className="flex gap-2 justify-center w-full">
          <div className="flex justify-center relative">
            <ul className="flex items-center list-none gap-1">
              {categories.map((category, index) => (
                <li key={index}>
                  <Link
                    to="#"
                    className={`flex gap-1 items-center text-sm text-white px-4 py-3 transition-all ${
                      activeCategory === category.title
                        ? "bg-green"
                        : "bg-transparent"
                    }`}
                    onClick={() =>
                      setActiveCategory(
                        activeCategory === category.title
                          ? null
                          : category.title
                      )
                    }
                  >
                    {category.title}
                    {activeCategory === category.title ? (
                      <MingcuteDownLine className="rotate-180" />
                    ) : (
                      <MingcuteDownLine />
                    )}
                  </Link>
                </li>
              ))}
            </ul>
            {activeCategory && (
              <div
                ref={dropdownRef}
                className="absolute top-full left-0 w-full bg-white shadow-lg p-4 border border-gray-200 rounded-md z-[111]"
              >
                {categories.map((category, index) =>
                  activeCategory === category.title ? (
                    <div key={index} className="flex gap-3">
                      <div className="w-full md:w-[60%]">
                        <ul className="grid grid-cols-2 gap-4 list-none">
                          {category.items.map((item, idx) => (
                            <li
                              key={idx}
                              className="text-gray-800 hover:text-blue-600 cursor-pointer transition"
                            >
                              <Link
                                to={item.url}
                                className="text-sm"
                                onClick={() => setActiveCategory(null)}
                              >
                                {item.text}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="w-full md:w-[40%]">
                        <div className="relative">
                          <img
                            src={NavigationBanner}
                            alt="Scholarship"
                            className="w-full h-full rounded-lg object-cover"
                          />
                          <div className="flex flex-col gap-2 absolute -bottom-1">
                            <h4 className="text-sm font-light text-[#F8EC67] text-center">
                              Empowering{" "}
                              <strong className="font-semibold">
                                +2 Students with <br />
                                <span className="text-sm md:text-base font-bold">
                                  ₹1 Crore
                                </span>
                                <sup>*</sup> in Scholarships.
                              </strong>
                            </h4>
                            <p className="text-white text-center text-xs">
                              Currently studying in Class 12 or equivalent from
                              a recognized board in India.
                            </p>
                            {/* <div className="flex items-center justify-center">
                              <Link
                                className="bg-[#173CBA] text-white py-1.5 px-2 text-sm rounded-full hover:bg-green transition flex items-center justify-center gap-1"
                                to="https://cocubes.in/collegementor"
                                target="_blank"
                              >
                                Exam Live <EpRight />
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            )}
          </div>
          <div className="flex">
            <ul className="list-none">
              <li>
                <Link
                  to="/college-mentor-scholarship"
                  className="text-sm text-white flex items-center gap-[4px] px-4 py-3"
                >
                  College Mentor Scholarship Test - 1Cr
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBarBottom;
