import React, { useState } from "react";

import mentor1 from "../../images/courses/mentors/shekar-mentor.png";
import mentorPlaceholderMale from "../../images/courses/mentors/mentor-placeholder-male.png";
import whiteGradCapIcon from "../../images/courses/white-graduation-cap-icon.svg";
import CMlogo from "../../images/cm-logo-color.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

interface IMentor {
  title: string;
  image: string;
  nameTitle: string;
  name: string;
  field: string;
  experience: string;
  about: string;
}

const ScholarshipGuidanceContent = [
  {
    title: "Dream universities and scholarships with mentor guidance",
    description:
      "Our mentors assist parents and students in discovering ideal universities and scholarships, guiding them through each step of the process to ensure the best fit",
    adImages: [
      "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/webp-course-landing/scholarship-guidance/Dream-Univ.webp",
      "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/webp-course-landing/scholarship-guidance/Dream-Univ.webp",
    ],
    mentors: [
      {
        title: "Admission Mentor",
        image:
          "https://cdncollegementor.blob.core.windows.net/courses/courses-landing/webp-course-landing/scholarship-guidance/shekar-mentor.webp",
        nameTitle: "Mr",
        name: "Rajashekar",
        field: "Computer Science",
        experience: "15 + Year Experience",
        about:
          "Hello, I'm M. Rajasekhar, your mentor here to support your academic and personal development journey. Let's work together to achieve your goals and unlock your potential.",
      },
      {
        title: "Admission Mentor",
        image: mentorPlaceholderMale,
        nameTitle: "Mr",
        name: "Barath J",
        field: "Mechanical Engineering",
        experience: "15 + Year Experience",
        about:
          "Hello, I'm Barath J, your mentor here to support your academic and personal development journey. Let's work together to achieve your goals and unlock your potential.",
      },
    ],
  },
];

const ScholarshipGuidance: React.FC = () => {
  const [isPrevHovered, setIsPrevHovered] = useState(false);

  // Function to handle mouse enter event
  const handlePrevMouseEnter = () => setIsPrevHovered(true);

  // Function to handle mouse leave event
  const handlePrevMouseLeave = () => setIsPrevHovered(false);
  return (
    <div className="bg-aqua-light text-black font-open-sans pt-12 pb-12 max-sm:py-6 md:py-14 relative flex flex-col gap-5">
      <div className="container flex flex-col md:flex-row gap-6 md:gap-20 space-between">
        <div className="w-full md:w-1/4">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            navigation={{
              prevEl: ".scholarship-prev-btn",
              nextEl: ".scholarship-next-btn",
            }}
            mousewheel={true}
            keyboard={true}
            modules={[Autoplay, Navigation]}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            className="h-full w-full"
          >
            {ScholarshipGuidanceContent[0].adImages.map((img, index) => (
              <SwiperSlide key={index}>
                <img src={img} className="w-full" alt="Scholarship  Guidance" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex-1 flex flex-col justify-center">
          <h2 className="w-full text-3xl md:text-5xl font-bold font-roboto">
            {ScholarshipGuidanceContent[0].title}
          </h2>
          <p className="text-base md:text-xl text-grey mt-4">
            {ScholarshipGuidanceContent[0].description}
          </p>
        </div>
        <div className="w-full md:w-1/4">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            navigation={{
              prevEl: ".scholarship-prev-btn",
              nextEl: ".scholarship-next-btn",
            }}
            mousewheel={true}
            keyboard={true}
            modules={[Autoplay, Navigation]}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            className="h-full w-full"
          >
            {ScholarshipGuidanceContent[0].mentors.map((data, index) => (
              <SwiperSlide key={index}>
                <MentorCard {...data} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="flex gap-10 relative justify-center items-center">
        <button
          className="scholarship-prev-btn p-4 border border-black hover:border-blue rounded-full bg-white hover:bg-blue group"
          onMouseEnter={handlePrevMouseEnter}
          onMouseLeave={handlePrevMouseLeave}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 11H9L12.29 7.71C12.3837 7.61703 12.4581 7.50643 12.5089 7.38457C12.5597 7.26272 12.5858 7.13201 12.5858 7C12.5858 6.86799 12.5597 6.73728 12.5089 6.61542C12.4581 6.49356 12.3837 6.38296 12.29 6.29C12.1026 6.10375 11.8492 5.99921 11.585 5.99921C11.3208 5.99921 11.0674 6.10375 10.88 6.29L6.59 10.59C6.21441 10.9633 6.00223 11.4704 6 12C6.00487 12.5261 6.21684 13.0291 6.59 13.4L10.88 17.7C10.9732 17.7926 11.0838 17.8659 11.2054 17.9157C11.3269 17.9656 11.4571 17.991 11.5885 17.9905C11.7199 17.9901 11.8499 17.9637 11.9712 17.913C12.0924 17.8623 12.2024 17.7882 12.295 17.695C12.3876 17.6017 12.4609 17.4912 12.5107 17.3696C12.5606 17.248 12.586 17.1178 12.5856 16.9864C12.5851 16.8551 12.5588 16.725 12.508 16.6038C12.4573 16.4826 12.3832 16.3726 12.29 16.28L9 13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z"
              fill="#333333"
              className="fill-current text-black group-hover:text-white"
            />
          </svg>
        </button>
        <button
          className={`scholarship-next-btn p-4 border rounded-full ${
            isPrevHovered ? "bg-white border-black" : "bg-blue border-blue"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5 11H15L11.71 7.71C11.6163 7.61703 11.5419 7.50643 11.4911 7.38457C11.4403 7.26272 11.4142 7.13201 11.4142 7C11.4142 6.86799 11.4403 6.73728 11.4911 6.61542C11.5419 6.49356 11.6163 6.38296 11.71 6.29C11.8974 6.10375 12.1508 5.99921 12.415 5.99921C12.6792 5.99921 12.9326 6.10375 13.12 6.29L17.41 10.59C17.7856 10.9633 17.9978 11.4704 18 12C17.9951 12.5261 17.7832 13.0291 17.41 13.4L13.12 17.7C13.0268 17.7926 12.9162 17.8659 12.7946 17.9157C12.6731 17.9656 12.5429 17.991 12.4115 17.9905C12.2801 17.9901 12.1501 17.9637 12.0288 17.913C11.9076 17.8623 11.7976 17.7882 11.705 17.695C11.6124 17.6017 11.5391 17.4912 11.4893 17.3696C11.4394 17.248 11.414 17.1178 11.4144 16.9864C11.4149 16.8551 11.4412 16.725 11.492 16.6038C11.5427 16.4826 11.6168 16.3726 11.71 16.28L15 13H5C4.73478 13 4.48043 12.8946 4.29289 12.7071C4.10536 12.5196 4 12.2652 4 12C4 11.7348 4.10536 11.4804 4.29289 11.2929C4.48043 11.1054 4.73478 11 5 11Z"
              fill="#333333"
              className={`${
                isPrevHovered
                  ? "fill-current text-black"
                  : "fill-current text-white"
              }`}
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ScholarshipGuidance;

const MentorCard: React.FC<IMentor> = ({
  title,
  image,
  nameTitle,
  name,
  field,
  experience,
  about,
}) => {
  return (
    <div className="rounded-md border border-green overflow-hidden">
      <div className="bg-green flex items-center justify-end gap-2 p-2">
        <span className="text-xs text-white font-bold">{title}</span>
        <img src={whiteGradCapIcon} alt="whiteGradCapIcon" />
      </div>
      <div className="bg-white p-4 flex flex-col gap-2">
        <div className="flex flex-col items-end relative">
          <img src={CMlogo} className="h-6" alt="CMlogo" />
          <span className="text-sm text-grey mt-2">{field}</span>
          <span className="text-sm text-grey">{experience}</span>
          <img
            src={image}
            alt="mentor"
            className="absolute left-0 top-0 translate-x-4 -translate-y-1/3 w-24 h-24 object-cover border-4 border-white rounded-full"
          />
        </div>
        <h5 className="text-sm font-bold">{`${nameTitle}. ${name}`}</h5>
        <div className="border-t border-t-grey-3"></div>
        <h6 className="text-xs font-semibold">About Me</h6>
        <p className="text-xs text-grey">{about}</p>
        <a className="text-xs text-blue">{`Read more about ${name}`}</a>
        <Link
          to={"https://wa.me/918959591818"}
          target="blank"
          className="flex gap-2 items-center px-4 py-2 bg-blue rounded-md mt-6 w-fit self-center text-xs text-white font-semibold"
        >
          <FontAwesomeIcon icon={faWhatsapp} size="lg" />
          {`Connect with Mentor`}
        </Link>
      </div>
    </div>
  );
};
