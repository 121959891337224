import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MdiHeartOutline,
  MdiHeart,
  MingcuteQuestionLine,
} from "../../screens/college-details/CollegeDetails";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import LeadGenCtaBtn from "../../features/lead-generation/LeadGenCtaBtn";
import config from "../../util/config";
import axios from "axios";
import {BaseURL} from "../../services/api";

interface Button {
  text: string;
  link: string;
  type: "primary" | "secondary";
}

interface BannerProps {
  data: {
    examId: number;
    examTitle: string;
    examAbout: string;
    buttons: Button[];
    examBanner: string;
  };
}

const Banner: React.FC<BannerProps> = ({ data }) => {
  const breadcrumbLinks = [
    { name: "Home", path: "/" },
    {
      name: "List of Exams in India",
      path: "/list-of-exams-in-india",
    },
    {
      name: data.examTitle,
      path: `/course-details/${data.examTitle}`,
    },
  ];
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserId(parsedUser.userId);
      const token = localStorage.getItem("accessToken");

      if (!token) {
        console.error("No access token found.");
        return;
      }

      BaseURL.get(
        `${config.apiUrl}/api/wishlist/category/${parsedUser.userId}?category=exam`,
        {
          headers: { Authorization: token },
        }
      )
        .then((response) => {
          console.log("Wishlist Data:", response.data);
          const wishlistItems = response.data.data || [];
          setIsWishlisted(
            wishlistItems.some(
              (item: { examTitle: string }) => item.examTitle === data.examTitle
            )
          );
        })
        .catch((error) => {
          console.error("Error fetching wishlist data:", error);
        });
    }
  }, [userId, data.examTitle]);

  const handleWishList = () => {
    console.log("Wishlist button clicked");

    if (!userId) {
      console.warn("User not logged in, redirecting...");
      navigate("/secure/login", { state: { sourcePagePath: pathname } });
      return;
    }

    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error("Authorization token missing!");
      return;
    }

    const apiUrl = isWishlisted
      ? `${config.apiUrl}/api/wishlist/remove`
      : `${config.apiUrl}/api/wishlist/add`;

    BaseURL.post(
      apiUrl,
      { categoryName: "exam", categoryId: data.examTitle, userId },
      { headers: { Authorization: token } }
    )
      .then((response) => {
        console.log("Wishlist Toggle Response:", response.data);
        setIsWishlisted((prev) => !prev);
      })
      .catch((error) => {
        console.error(
          "Error toggling wishlist:",
          error.response?.data || error
        );
      });
  };

  return (
    <div
      className="flex flex-col lg:flex-row items-center bg-[#0a0a5c] text-white py-16 max-sm:py-8 px-4 lg:px-10 relative"
      style={{
        backgroundImage:
          window.innerWidth >= 767 ? `url(${data.examBanner})` : "none",
        backgroundSize: "contain",
        backgroundPosition: "right center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="lg:w-1/2 space-y-4">
        <div className="relative lg:absolute lg:top-10 lg:mb-7 text-[#00CC99] font-open-sans text-sm">
          <Breadcrumbs links={breadcrumbLinks} />
        </div>
        <h1 className="text-4xl font-bold">{data.examTitle}</h1>
        <p className="text-xl">{data.examAbout}</p>

        <div className="flex flex-col gap-7">
          <div className="flex items-center space-x-6">
            <div className="flex gap-4 mt-8 max-sm:mt-2">
              <button
                onClick={handleWishList}
                className="border-none bg-transparent text-base font-bold flex gap-1.5 items-center"
              >
                {isWishlisted ? (
                  <MdiHeart className="text-[#DC0D0D] duration-200 w-5 h-5" />
                ) : (
                  <MdiHeartOutline className="text-green-500 duration-200 w-5 h-5" />
                )}
                <span className="text-white">
                  {isWishlisted ? "Saved" : "Save"}
                </span>
              </button>

              <Link
                to={"/college-compare"}
                className="border-none bg-transparent text-base font-bold text-green flex gap-1.5 items-center"
              >
                <MingcuteQuestionLine />
                <span className="text-white">Compare College</span>
              </Link>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <LeadGenCtaBtn
              pageName="exam-landing"
              ctaName={`excel-in-comp-exam-${data.examTitle}`}
              className="button-styles green-bg items-center rounded-none hover:bg-blue"
            >
              Apply Now
            </LeadGenCtaBtn>
            <Link
              to={"/mentors"}
              className="button-styles green-bg items-center rounded-none hover:bg-blue"
            >
              Talk to Mentor
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
