import React, { useState } from "react";
import JobsProfiles from "./jobs/JobsProfiles";
import AverageSalary from "./jobs/AverageSalary";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import VisualStory from "../../VisualStory";

const CourseJobs: React.FC = () => {
  const [visualStoryIndex, setVisualStoryIndex] = useState<number>(-1);
  const [isOpenVisualStory, setIsOpenVisualStory] = useState(false);

  const sliders = [
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/visual-stories/Bachlor%20of%20Surgery/MBBS(Bachlor-of-Medicine-and-Bachlor-of-Surgery.png",
      description: `<p><span data-sheets-root="1"><strong>Description :</strong> Students learn to diagnose and treat medical conditions, gaining hands-on experience through clinical practice.<br><strong>Fees :</strong> ₹ 10,000 to ₹ 1,500,000 annually.<br><strong>Eligibility :</strong> 10+2 with Physics, Chemistry, Biology, NEET Qualification.<br><strong>Top NIRF Colleges (2024):</strong><br></span></p>
  <ul>
    <li><span data-sheets-root="1">All India Institute of Medical Sciences (AIIMS), New Delhi</span></li>
    <li><span data-sheets-root="1">Post Graduate Institute of Medical Education and Research (PGIMER), Chandigarh</span></li>
    <li><span data-sheets-root="1">King George's Medical University, Lucknow</span></li>
    <li><span data-sheets-root="1">Banaras Hindu University (BHU), Varanasi</span></li>
    <li><span data-sheets-root="1">Christian Medical College (CMC), Vellore</span></li>
  </ul>`,
      title: "MBBS (Bachelor of Medicine, Bachelor of Surgery)",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/visual-stories/Bachlor%20of%20Surgery/BDS(bachlore%20of%20dental%20surgery).png",
      description: `<p><span data-sheets-root="1"><strong>Description :</strong> Focuses on oral health, including diagnosis and treatment of dental issues, with practical training in dental clinics.<br><strong>Fees :</strong> ₹ 40,000 to ₹ 1,200,000 annually.<br><strong>Eligibility :</strong> 10+2 with Physics, Chemistry, Biology; NEET Qualification.<br><strong>Top NIRF Colleges (2024):</strong><br></span></p>
  <ul>
    <li><span data-sheets-root="1">Manipal College of Dental Sciences, Manipal</span></li>
    <li><span data-sheets-root="1">A.B. Shetty Memorial Institute of Dental Sciences, Mangalore</span></li>
    <li><span data-sheets-root="1">Dr. D.Y. Patil Vidyapeeth, Pune</span></li>
    <li><span data-sheets-root="1">Saveetha Institute of Medical and Technical Sciences, Chennai</span></li>
    <li><span data-sheets-root="1">Nair Hospital Dental College, Mumbai</span></li>
  </ul>`,
      title: "BDS (Bachelor of Dental Surgery)",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/visual-stories/Bachlor%20of%20Surgery/BAMS(Bachlor%20of%20Ayurvedic%20Medicine%20and%20Surgery).png",
      description: `<p><span data-sheets-root="1"><strong>Description :</strong> Covers Ayurvedic principles and practices, preparing students to diagnose and treat ailments using traditional methods.<br><strong>Fees :</strong> ₹ 20,000 to ₹ 100,000 annually.<br><strong>Eligibility :</strong> 10+2 with Physics, Chemistry, Biology; NEET qualification.<br><strong>Top NIRF Colleges (2024):</strong><br></span></p>
  <ul>
    <li><span data-sheets-root="1">All India Institute of Medical Sciences (AIIMS), New Delhi</span></li>
    <li><span data-sheets-root="1">Post Graduate Institute of Medical Education and Research (PGIMER), Chandigarh</span></li>
    <li><span data-sheets-root="1">King George's Medical University, Lucknow</span></li>
    <li><span data-sheets-root="1">Banaras Hindu University (BHU), Varanasi</span></li>
    <li><span data-sheets-root="1">Christian Medical College (CMC), Vellore</span></li>
  </ul>`,
      title: "BAMS (Bachelor of Ayurvedic Medicine and Surgery)",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/visual-stories/Bachlor%20of%20Surgery/BHMS(Bachlor%20of%20homeopathic%20medicine%20and%20Surgery).png",
      description: `<p><span data-sheets-root="1"><strong>Description :</strong> Focuses on homeopathic medicine, diagnosing and treating patients using homeopathic principles.<br><strong>Fees :</strong> ₹ 40,000 to ₹ 1,200,000 annually.<br><strong>Eligibility :</strong> 10+2 with Physics, Chemistry, Biology; NEET Qualification.<br><strong>Top NIRF Colleges (2024):</strong><br></span></p>
  <ul>
    <li><span data-sheets-root="1">Manipal College of Dental Sciences, Manipal</span></li>
    <li><span data-sheets-root="1">A.B. Shetty Memorial Institute of Dental Sciences, Mangalore</span></li>
    <li><span data-sheets-root="1">Dr. D.Y. Patil Vidyapeeth, Pune</span></li>
    <li><span data-sheets-root="1">Saveetha Institute of Medical and Technical Sciences, Chennai</span></li>
    <li><span data-sheets-root="1">Nair Hospital Dental College, Mumbai</span></li>
  </ul>`,
      title: "BHMS (Bachelor of Homeopathic Medicine and Surgery)",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/visual-stories/Bachlor%20of%20Surgery/BUMS(Bachlor%20of%20Unani%20medicine%20and%20surgery).png",
      description: `<p><span data-sheets-root="1"><strong>Description :</strong> Trains students in Unani treatments and philosophy, emphasizing patient-centered care and holistic approaches.<br><strong>Fees :</strong> ₹ 30,000 to ₹ 100,000 annually.<br><strong>Eligibility :</strong> 10+2 with Physics, Chemistry, Biology; NEET Qualification.<br><strong>Top NIRF Colleges (2024):</strong><br></span></p>
  <ul>
    <li><span data-sheets-root="1">Smt. Chandaben Homeopathic Medical College, Mumbai</span></li>
    <li><span data-sheets-root="1">Dr. B.R. Sur Homeopathic Medical College, Hospital and Research Centre, Pune</span></li>
    <li><span data-sheets-root="1">Homoeopathy Medical College, Kolkata</span></li>
    <li><span data-sheets-root="1">Bharati Vidyapeeth Homeopathic Medical College, Pune</span></li>
    <li><span data-sheets-root="1">Sardar Patel University, Gujarat</span></li>
  </ul>`,
      title: "BUMS (Bachelor of Unani Medicine and Surgery)",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/visual-stories/Bachlor%20of%20Surgery/BNYS(Bachlor%20of%20Naturopathy%20and%20surgery.png",
      description: `<p><span data-sheets-root="1"><strong>Description :</strong> Combines principles of naturopathy and yoga, preparing students to promote health through natural healing methods.<br><strong>Fees :</strong> ₹ 25,000 to ₹ 100,000 annually.<br><strong>Eligibility :</strong> 10+2 with Physics, Chemistry, Biology; NEET Qualification.<br><strong>Top NIRF Colleges (2024):</strong><br></span></p>
  <ul>
    <li><span data-sheets-root="1">S-Vyasa University, Bangalore</span></li>
    <li><span data-sheets-root="1">Gujarat Ayurved University, Jamnagar</span></li>
    <li><span data-sheets-root="1">National Institute of Naturopathy, Pune</span></li>
    <li><span data-sheets-root="1">Swami Vivekananda Yoga Anusandhana Samsthana, Bangalore</span></li>
    <li><span data-sheets-root="1">Yogic Sciences Institute, Kolkata</span></li>
  </ul>`,
      title: "BNYS (Bachelor of Naturopathy and Yogic Sciences)",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/visual-stories/Bachlor%20of%20Surgery/BPT(Bachlor%20of%20physiontherapy).png",
      description: `<p><span data-sheets-root="1"><strong>Description :</strong> Teaches assessment and rehabilitation of physical impairments, focusing on therapeutic exercises and techniques for recovery.<br><strong>Fees :</strong> ₹ 20,000 to ₹ 100,000 annually.<br><strong>Eligibility :</strong> 10+2 with Physics, Chemistry, Biology, NEET Qualification.<br><strong>Top NIRF Colleges (2024):</strong><br></span></p>
  <ul>
    <li><span data-sheets-root="1">All India Institute of Medical Sciences (AIIMS), New Delhi</span></li>
    <li><span data-sheets-root="1">Christian Medical College (CMC), Vellore</span></li>
    <li><span data-sheets-root="1">Jamia Millia Islamia, New Delhi</span></li>
    <li><span data-sheets-root="1">KLE University, Karnataka</span></li>
    <li><span data-sheets-root="1">Sri Ramachandra Institute of Higher Education and Research, Chennai</span></li>
  </ul>`,
      title: "BPT (Bachelor of Physiotherapy)",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/visual-stories/Bachlor%20of%20Surgery/Bsc-Nursing(Bachlorof%20science%20in%20nursing).png",
      description: `<p><span data-sheets-root="1"><strong>Description :</strong> Prepares students for nursing practice, focusing on patient care, health promotion, and critical thinking in healthcare settings.<br><strong>Fees :</strong> ₹ 40,000 to ₹ 100,000 annually.<br><strong>Eligibility :</strong> 10+2 with Physics, Chemistry, Biology, NEET qualification.<br><strong>Top NIRF Colleges (2024):</strong><br></span></p>
  <ul>
    <li><span data-sheets-root="1">All India Institute of Medical Sciences (AIIMS), New Delhi</span></li>
    <li><span data-sheets-root="1">Christian Medical College (CMC), Vellore</span></li>
    <li><span data-sheets-root="1">Armed Forces Medical College (AFMC), Pune</span></li>
    <li><span data-sheets-root="1">King Edward Memorial Hospital, Mumbai</span></li>
    <li><span data-sheets-root="1">Indira Gandhi Institute of Medical Sciences, Patna</span></li>
  </ul>`,
      title: "BSc Nursing (Bachelor of Science in Nursing)",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/visual-stories/Bachlor%20of%20Surgery/BVSc(Bachlor%20of%20veterinary%20science).png",
      description: `<p><span data-sheets-root="1"><strong>Description :</strong> Trains students to diagnose and treat animal diseases, emphasizing veterinary medicine, surgery, and animal welfare.<br><strong>Fees :</strong> ₹ 40,000 to ₹ 150,000 annually.<br><strong>Eligibility :</strong> 10+2 with Physics, Chemistry, Biology, NEET Qualification.<br><strong>Top NIRF Colleges (2024):</strong><br></span></p>
  <ul>
    <li><span data-sheets-root="1">Indian Veterinary Research Institute (IVRI), Bareilly</span></li>
    <li><span data-sheets-root="1">Punjab Agricultural University, Ludhiana</span></li>
    <li><span data-sheets-root="1">Maharashtra Animal and Fishery Sciences University, Nagpur</span></li>
    <li><span data-sheets-root="1">Guru Angad Dev Veterinary and Animal Sciences University, Ludhiana</span></li>
    <li><span data-sheets-root="1">Tamil Nadu Veterinary and Animal Sciences University, Chennai</span></li>
  </ul>`,
      title: "BVSc (Bachelor of Veterinary Science)",
    },
  ];

  const handleOpenVisualStory = (index: number) => {
    setVisualStoryIndex(index);
    setIsOpenVisualStory(true);
  };

  const getVisualStorySlides = (
    slides: {
      title: string;
      description: string;
      image: string;
    }[]
  ) => {
    return slides.map((slide) => ({
      image: slide.image,
      content: (
        <div>
          <h1 className="text-xl font-bold text-center text-white">
            {slide.title}
          </h1>
          <p className="mt-2 text-base text-justify text-white">
            {parse(slide.description)}
          </p>
        </div>
      ),
    }));
  };

  return (
    <div>
      <div className="bg-[#ECECEC] py-6">
        <div className="container">
          <div className="flex lg:flex-row flex-col">
            <div className="w-full lg:w-9/12 flex flex-col">
              <JobsProfiles />
              <AverageSalary />
            </div>
            <div className="w-full lg:w-3/12 hidden md:block">
              <div className="bg-[#FFF1FA] w-full h-full flex flex-col justify-start items-center py-10 ">
                <div className="py-4 bg-white ">
                  <img
                    src="/images/course-details/TopMedical Courses_071.png"
                    alt="TopMedicalCourses"
                  />
                  <button
                    className="flex mx-auto text-white py-1 text-sm px-3 bg-[#173CBA] rounded-full group w-[125px]"
                    onClick={() => handleOpenVisualStory(0)}
                  >
                    <span className="mr-2">Read more</span>
                    <div className="bg-[#0C9] text-[#fff] rounded-full p-1 group-hover:translate-x-2 transition-transform duration-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </button>
                </div>

                <div className="py-4 mt-4 bg-white sticky top-0">
                  <div className="relative">
                    <img
                      src="/images/course-details/medical-course.png"
                      alt="medical-course"
                    />
                    <div
                      className="w-[189px] h-[75px] bg-[#fff] absolute bottom-0 right-0"
                      style={{ borderRadius: "25px 0px 0px 0px" }}
                    >
                      <h3 className="text-[#383838] text-base font-normal p-3">
                        Top Medical Courses Without <strong>NEET</strong>
                      </h3>
                    </div>
                  </div>
                  <button
                    className="flex mx-auto text-white py-1 text-sm px-3 bg-[#173CBA] rounded-full group w-[125px] mt-2"
                    onClick={() => handleOpenVisualStory(0)}
                  >
                    <span className="mr-2">Read more</span>
                    <div className="bg-[#0C9] text-[#fff] rounded-full p-1 group-hover:translate-x-2 transition-transform duration-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.293 4.293a1 1 0 011.414 0L16 9.586a1 1 0 010 1.414l-5.293 5.293a1 1 0 01-1.414-1.414L13.586 11H4a1 1 0 110-2h9.586L9.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* VisualStory Modal */}
      <VisualStory
        isOpen={isOpenVisualStory}
        slides={getVisualStorySlides(sliders)}
        handleClose={() => setIsOpenVisualStory(false)}
      />
    </div>
  );
};

export default CourseJobs;
