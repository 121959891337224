import React from "react";
import DidYouKnow from "./DidYouKnow";

// Define the type for the slide data
interface SlideData {
  mainText: string;
  subText?: string;
  subItems?: string[]; // Corrected type to be an array of strings
}

// Define the data as an array
const didYouKnowData: SlideData[] = [
  {
    mainText:
      "On average, students tend to apply to five engineering entrance exams",
    subText:
      "JEE Main, JEE Advanced, BITS Pilani, VIT, Domicile State Entrance Exam",
  },
  {
    mainText:
      "For 2025 session I of JEE mains Registered Candidates - 13,11,544  and nearly 12.5 lakh appeared for the exam  ",
    subText:
      "There are only 17740 seats in 23 Indian Institute of Technologies with 73 candidates applying for each seat",
  },
  {
    mainText:
      "NEET 2025, There are around 2.3 Million registration nearly 1 lakh less than NEET 2024",
    subText:
      "There are only about 92,250 MBBS seats, with 23 candidates per seat.",
  },
  {
    mainText: "Institutions of Eminence (IoE) Scheme",
    subText:
      "Only 8 government universities and 4 private universities have been granted the status, enabling them to develop into world-class teaching and research institutions.",
  },
  {
    mainText:
      "Institutes of National Importance (INIs): 165, Including IITs, IIMs, NITs, and AIIMS",
    subItems: [
      "Central Universities - 57",
      "State Universities - 494",
      "Deemed-to-be Universities - 138",
      "Open Universities - 18",
      "Fake Universities - 21",
      "State private universities-505",
    ],
  },
];

function DidYouKnowData() {
  return (
    <div className="App">
      <DidYouKnow slidesData={didYouKnowData} />
    </div>
  );
}

export default DidYouKnowData;
