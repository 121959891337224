import React from "react";
import ScholarshipResultBanner from "./components/ScholarshipResultBanner";
import CollegeMentorAchievers from "./components/CollegeMentorAchievers";
import ScholarshipAdmissions from "./components/ScholarshipAdmissions";
import HandHoldingMentorship from "./components/HandHoldingMentorship";
import TopCollegesUniversities from "./components/TopCollegesUniversities";
import ExploreYourFuture from "./components/ExploreYourFuture";
import AdmissionsOpen from "./components/AdmissionsOpen";
import ArticlesBlogs from "./components/ArticlesBlogs";
import KnowledgeHubFAQ from "./components/KnowledgeHubFAQ";

const ScholarshipResults = () => {
  return (
    <div>
      <ScholarshipResultBanner />
      <CollegeMentorAchievers />
      <ScholarshipAdmissions />
      <HandHoldingMentorship />
      <TopCollegesUniversities />
      <ExploreYourFuture />
      <AdmissionsOpen />
      <ArticlesBlogs />
      <KnowledgeHubFAQ />
    </div>
  );
};

export default ScholarshipResults;
