import axios from "axios";
import config from "../util/config";

// Axios instance without authentication
const BaseURL = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Axios instance with authentication
const BaseURLAuth = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// ✅ Request Interceptor: Attach Token if Available
BaseURLAuth.interceptors.request.use(
  async (configu) => {
    let accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const isTokenExpired = checkTokenExpiry(accessToken);
      if (isTokenExpired) {
        try {
          const refreshToken = localStorage.getItem("refreshToken");
          if (!refreshToken) throw new Error("No refresh token found");

          // Refresh Token API Call
          const refreshResponse = await axios.post(
            `${config.apiUrl}/api/auth/accessToken`,
            `Bearer ${refreshToken}`
          );

          accessToken = refreshResponse.data.accessToken;
          localStorage.setItem("accessToken", refreshResponse.data.accessToken);
          localStorage.setItem("refreshToken", refreshResponse.data.refreshToken);

        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.href = "/secure/login"; // Redirect to login
          return Promise.reject(refreshError);
        }
      }
      // ✅ Always attach Authorization header
      configu.headers.Authorization = `Bearer ${accessToken}`;
    }

    return configu;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// ✅ Response Interceptor: Handle Unauthorized Errors
BaseURLAuth.interceptors.response.use(
  (response) => response, // Pass successful responses
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        if (!refreshToken) throw new Error("No refresh token found");

        // Refresh the token
        const refreshResponse = await axios.post(
          `${config.apiUrl}/api/auth/accessToken`,
          `Bearer ${ refreshToken }`
        );

        const newAccessToken = refreshResponse.data.accessToken;
        localStorage.setItem("accessToken", newAccessToken);
        localStorage.setItem("refreshToken", refreshResponse.data.refreshToken);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/secure/login"; // Redirect to login
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

// ✅ Function to Check Token Expiry
const checkTokenExpiry = (token: any) => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
    const expiry = payload.exp * 1000; // Convert expiry time to milliseconds
    return Date.now() >= expiry;
  } catch (error) {
    return true; // Treat as expired if there's an issue decoding
  }
};

export { BaseURL, BaseURLAuth };
