import configData from "./configData.json";

const config = {
  apiUrl: configData.environments.url,
  searchApiKey: configData.environments.searchApiKey,
  GA_TRACKING_ID: configData.environments.GA_TRACKING_ID,
  GTM_CODE: configData.environments.GTM_CODE,
  mobileNumber: 8959591818,
  developerUsername: configData.environments.developerUsername,
  developerPassword: configData.environments.developerPassword,
  GTM_ID: configData.environments.GTM_ID,
};

export default config;
