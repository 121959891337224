import React, { useEffect, useState } from "react";
import "./App.css";
import useCopyWithMessage from "./hooks/clipboard/useCopyWithMessage";
import theme from "./components/ThemeComponent";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  Navigate,
} from "react-router-dom";
import TermsAndConditions from "./screens/blogs-articles/components/TermsAndConditions";
import PrivacyPolicy from "./screens/blogs-articles/components/PrivacyPolicy";
import Disclaimer from "./screens/blogs-articles/components/Disclaimer";
import OldLogin from "./screens/sign-in-form/Login";
import LandingPage from "./screens/Landing/Landing";
import { ThemeProvider } from "@mui/material/styles";
import ForgotPassword from "./screens/sign-in-form/ForgotPassword";
import OTPVerification from "./screens/sign-in-form/OTPVerification";
import PasswordReset from "./screens/sign-in-form/PasswordReset";
import Registration from "./screens/sign-in-form/Registration";
import PersonalInfo from "./screens/sign-in-form/PersonInfo";
import StandardInfo from "./screens/sign-in-form/StandardInfo";
import ForgotEmail from "./screens/sign-in-form/ForgotEmail";
import DreamCareerInfo from "./screens/sign-in-form/DreamCareerInfo";
import Layout from "./components/Layout";
import ResetPassword from "./screens/sign-in-form/ResetPassword";
import StateInfo from "./screens/sign-in-form/StateInfo";
import SignInOutlet from "./screens/sign-in-form/SignInOutlet";
import PlansToFund from "./screens/sign-in-form/PlansToFund";
import Careers from "./screens/careers/careers";
import { Exams } from "./screens/exams/Exams";
import Courses from "./screens/courses/courses";
import CareerDetails from "./screens/career-details/CareerDetails";
import CollegeDetails from "./screens/college-details/CollegeDetails";
import ExamDetails from "./screens/exam-details/ExamDetails";
import EducationBoardsDetails from "./screens/education-boards-details/EducationBoardsDetails";
import CareersDD from "./components/careersDD/Careers";
import CollegeIntermediate from "./screens/colleges/CollegeIntermediate";
import CourseDetails from "./screens/course-details/CourseDetails";
import CourseIntermediate from "./screens/course-intermediate/CourseIntermediate";
import NotFoundPage from "./screens/notfound-page/NotFoundPage";
import { CourseDetailsProvider } from "./screens/course-details/CourseDetailsContext";
import BlogDetailsPage from "./screens/blogdetails-page/BlogDetailsPage";
import ReviewPage from "./screens/review-page/ReviewPage";
import Scholarships from "./screens/Landing/components/Scholarships";
import CollegeLanding from "./screens/colleges-new/landing/CollegeLanding";
import BlogsLayout from "./screens/blogs-articles/components/BlogsLayout";
import BlogsAndArticlesMain from "./screens/blogs-articles/blogs-articles-landing/BlogsAndArticlesMain";
import DownloadExams from "./screens/exams/download-exams/DownloadExams";
import NewsDetails from "./screens/news-details/NewsDetails";
import CollegeCompare from "./screens/college-compare/CollegeCompare";
import CollegeReviews from "./screens/college-reviews/CollegeReviews";
import EducationLoan from "./screens/education-loan/EducationLoan";
import CollegeAdmissions from "./screens/college-admissions/CollegeAdmissions";
import BlogsIntermediate from "./screens/blogs-articles/blogs-articles-intermediate/BlogsIntermediate";
import BlogDetails from "./screens/blogs-articles/blogs-articles-details/BlogDetails";
import Examalerts from "./screens/exams/components/Examalerts";
import TcyOnline from "./screens/tcy-online/TcyOnline";
import MentorDetails from "./screens/mentor-details/MentorDetails";
import ExamIntermediate from "./screens/exam-intermediate/ExamIntermediate";
import ScrollTop from "./components/ScrollTop";
import CourseCompare from "./screens/course-compare/CourseCompare";
import ApplicationForms from "./screens/application-forms/ApplicationForms";
import CollegePredictorLanding from "./screens/college-predictor/landing/CollegePredictorLanding";
import CollegePredictorIntermediate from "./screens/college-predictor/intermediate/CollegePredictorIntermediate";
import MapView from "./screens/colleges/components/MapView";
import ApplicationsDirectAdmissions from "./screens/applications-admissions/ApplicationsDirectAdmissions";
import DashboardLayout from "./layouts/DashboardLayout";
import DashboardHome from "./features/dashboard/DashboardHome";
import MyProfile from "./features/dashboard/my-profile/MyProfile";
import Mentor from "./features/dashboard/mentors/Mentors";
import ProtectedRoute from "./routes/ProtectedRoute";
import Admissions from "./features/dashboard/college-admission/Admissions";
import ScholarshipLanding from "./screens/scholarship/landing/ScholarshipLanding";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StudyAbroadLanding } from "./screens/study-abroad/StudyAbroadLanding";
import CollegePredictorResults from "./screens/college-predictor-result/CollegePredictorResult";
import ScholarshipIntermediate from "./screens/scholarship/intermediate/ScholarshipIntermediate";
import StudyAbroadStudent from "./screens/study-abroad/StudyAbroadStudent";
import StudyAbroadUniversity from "./screens/study-abroad/StudyAbroadUniversity";
import EducationForAllLanding from "./screens/education-for-all/EducationForAllLanding";
import EducationForAllIntermediate from "./screens/education-for-all/EducationForAllIntermediate";
import EducationLoanDetails from "./screens/scholarship/education-loan-detail/EducationLoanDetails";
import config from "./util/config";
import ApplicationsAdmissionsTwo from "./screens/applications-admissions-two/ApplicationsAdmissionsTwo";
import { Mentors } from "./screens/mentors/Mentors";
import RevampedLogin from "./features/auth/login/RevampedLogin/RevampedLogin";
import ReferAndEarn from "./screens/refer-and-earn/ReferAndEarn";
import ReferEarnTerms from "./screens/refer-earn-terms/ReferEarnTerms";
import ScholarshipDetail from "./screens/scholarship/scholarship-detail/ScholarshipDetail";
import Quiz from "./screens/quiz/Quiz";
import ScholarshipTest from "./screens/scholarship-test/ScholarshipTest";
import AuthHeader from "./features/auth/registration/RevampedRegistration/components/AuthHeader";
import CareerDiscoveryIkigai from "./screens/career-discovery/CareerDiscoveryIkigai";
import RegulatoryProfessionalCouncil from "./screens/regulatory-professional-councils/RegulatoryProfessionalCouncil";
import RevampedRegistrationV2 from "./features/v2_new_auth_ui/revampedRegistration_v2/RevampedRegistrationV2";
import RankingFramework from "./screens/ranking-framework/RankingFramework";
import RankingFrameworkResults from "./screens/ranking-framework-results/RankingFrameworkResults";
import AboutUs from "./screens/about-page/AboutUs";
import ApplicationToAdmission from "./screens/application-admission/ApplicationToAdmission";
import RegistrationForm from "./screens/scholarship-test/RegistrationForm";
import ScholarshipPopup from "./components/ScholarshipPopup";
import ContactUs from "./screens/touch-us/touch";
import DeveloperLogin from "./DeveloperLogin";
import { EducationBoards } from "./screens/education-boards/EducationBoards";
import StudentDashboard from "./components/StudentDashboard/StudentDashboard";
import MentorDashboard from "./components/MentorDashboard/MentorDashboard";
import ScholarshipResults from "./screens/scholarship-results/ScholarshipResults";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CollegePredictorDetails from "./screens/college-predictor-details/CollegePredictorDetails";

const DevProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const storedUsername = localStorage.getItem("devUsername");
  const storedPassword = localStorage.getItem("devPassword");
  const [isAuthenticated, setIsAuthenticated] = useState(
    storedUsername === config.developerUsername &&
      storedPassword === config.developerPassword
  );

  const handleLoginSuccess = (username: string, password: string) => {
    localStorage.setItem("devUsername", username);
    localStorage.setItem("devPassword", password);
    setIsAuthenticated(true);
  };
  const isProd = config.apiUrl === "https://collegementor.com";
  // Show popup if not authenticated
  if (!isProd && !isAuthenticated) {
    return (
      <>
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-40" />
        <DeveloperLogin onLogin={handleLoginSuccess} />
      </>
    );
  }

  return <>{children}</>;
};

function External() {
  window.location.href = "https://cocubes.in/collegementor";
  return null;
}

function App() {
  useCopyWithMessage();
  const [selectedCollege] = useState(null);

  return (
    <ThemeProvider theme={theme}>
      {/* <CustomizedDialogs /> */}
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${config.GTM_ID}');`}
          </script>
        </Helmet>
      </HelmetProvider>
      <div className="App">
        <Router>
          <DevProtectedRoute>
            <ScholarshipPopup />
            <ScrollTop />
            {/* auth-wrapper auth-inner*/}
            <div className="router-class router-container">
              <div className="router-class">
                <Routes>
                  {config.apiUrl === "https://collegementor1.com" ? (
                    // Only render /blogs in production
                    <Route path="/" element={<BlogsLayout />}>
                      <Route index element={<Navigate to="/blogs" />} />
                      <Route
                        path="/news/:titleSlug"
                        element={<BlogDetails />}
                      />
                      <Route path="/blogs">
                        <Route index element={<BlogsAndArticlesMain />} />
                        <Route
                          path=":topicSlug"
                          element={<BlogsIntermediate />}
                        />
                        <Route
                          path=":topic/:titleSlug"
                          element={<BlogDetails />}
                        />
                        <Route path="terms" element={<TermsAndConditions />} />
                        <Route
                          path="privacy-policy"
                          element={<PrivacyPolicy />}
                        />
                        <Route path="disclaimer" element={<Disclaimer />} />
                      </Route>
                      <Route
                        path="/college-mentor-scholarship"
                        element={<ScholarshipTest />}
                      />
                      <Route
                        path="/college-mentor-scholarship/application"
                        element={<External />}
                      />
                      <Route path="*" element={<NotFoundPage />} />
                    </Route>
                  ) : (
                    <>
                      <Route path="/colleges" element={<Layout />}>
                        <Route path="/colleges" element={<CollegeLanding />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/college-details"
                          element={
                            <CollegeDetails selectedCollege={selectedCollege} />
                          }
                        />
                        <Route
                          path="/college-details/:collegeName"
                          element={
                            <CollegeDetails selectedCollege={selectedCollege} />
                          }
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/course-details/:courseName"
                          element={<CourseDetailsWrapper />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/exam-details/:examName"
                          element={<ExamDetails />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/list-of-exams-in-india"
                          element={<ExamIntermediate />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route path="/mentors" element={<Mentors />} />
                        <Route path="/mentor/:id" element={<MentorDetails />} />
                      </Route>
                      <Route path="/careers" element={<Layout />}>
                        <Route path="/careers" element={<Careers />} />
                      </Route>

                      <Route path="/mentor-details" element={<Layout />}>
                        <Route
                          path="/mentor-details"
                          element={<MentorDetails />}
                        />
                      </Route>
                      <Route path="/college-admissions" element={<Layout />}>
                        <Route
                          path="/college-admissions"
                          element={<CollegeAdmissions />}
                        />
                      </Route>
                      <Route path="/college-compare" element={<Layout />}>
                        <Route
                          path="/college-compare"
                          element={<CollegeCompare />}
                        />
                      </Route>
                      <Route path="/college-reviews" element={<Layout />}>
                        <Route
                          path="/college-reviews"
                          element={<CollegeReviews />}
                        />
                      </Route>
                      <Route path="/education-loan" element={<Layout />}>
                        <Route
                          path="/education-loan"
                          element={<EducationLoan />}
                        />
                      </Route>
                      <Route
                        path="/career-details/:careername"
                        element={<Layout />}
                      >
                        <Route
                          path="/career-details/:careername"
                          element={<CareerDetails />}
                        />
                      </Route>
                      <Route path="/courses" element={<Layout />}>
                        <Route index element={<Courses />} />
                        <Route path="quiz/:topic" element={<Quiz />} />
                      </Route>

                      <Route element={<Layout />}>
                        <Route path="/" element={<LandingPage />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route path="/exams" element={<Exams />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route path="/exam-alerts" element={<Examalerts />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/career-details"
                          element={<CareerDetails />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route path="*" element={<NotFoundPage />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/blogdetails-page"
                          element={<BlogDetailsPage />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route path="/review-page" element={<ReviewPage />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/download-exams"
                          element={<DownloadExams />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route path="/careers-dd" element={<CareersDD />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route path="/news-details" element={<NewsDetails />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route path="/tcy-online" element={<TcyOnline />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/course-compare"
                          element={<CourseCompare />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/application-forms"
                          element={<ApplicationForms />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/applications-admissions"
                          element={<ApplicationsDirectAdmissions />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/applications-admissions-explore-college"
                          element={<ApplicationsAdmissionsTwo />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/college-direct-application"
                          element={<ApplicationToAdmission />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/refer-and-earn"
                          element={<ReferAndEarn />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/refer-earn-terms"
                          element={<ReferEarnTerms />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/ikigai-career-discovery"
                          element={<CareerDiscoveryIkigai />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/regulatory-professional-councils"
                          element={<RegulatoryProfessionalCouncil />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/rankings"
                          element={<RankingFramework />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/rankings/:overall"
                          element={<RankingFrameworkResults />}
                        />
                      </Route>

                      <Route element={<Layout />}>
                        <Route
                          path="/list-of-colleges-in-india"
                          element={<CollegeIntermediate />}
                        />
                      </Route>

                      <Route element={<Layout />}>
                        <Route
                          path="/courses/:pageTitle"
                          element={<CourseIntermediate />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route path="/contact-us" element={<ContactUs />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route path="about-us" element={<AboutUs />} />
                        <Route
                          path="/scholarships"
                          element={<ScholarshipLanding />}
                        />
                        <Route
                          path="/scholarships/:pageTitle"
                          element={<ScholarshipIntermediate />}
                        />
                        <Route
                          path="/scholarship-detail/:slug"
                          element={<ScholarshipDetail />}
                        />
                        <Route
                          path="/education-loan-details"
                          element={<EducationLoanDetails />}
                        />
                      </Route>

                      <Route path="" element={<BlogsLayout />}>
                        <Route
                          path="/news/:titleSlug"
                          element={<BlogDetails />}
                        />
                        <Route path="/blogs">
                          <Route path="" element={<BlogsAndArticlesMain />} />
                          <Route
                            path="/blogs/:topicSlug"
                            element={<BlogsIntermediate />}
                          />
                          <Route
                            path="/blogs/:topic/:titleSlug"
                            element={<BlogDetails />}
                          />
                          <Route
                            path="terms"
                            element={<TermsAndConditions />}
                          />
                          <Route
                            path="privacy-policy"
                            element={<PrivacyPolicy />}
                          />
                          <Route path="disclaimer" element={<Disclaimer />} />
                        </Route>
                        <Route
                          path="/college-mentor-scholarship"
                          element={<ScholarshipTest />}
                        />
                        <Route
                          path="/viksit-bharat-scholarship"
                          element={
                            <Navigate to="/college-mentor-scholarship" />
                          }
                        />
                        <Route
                          path="/college-mentor-scholarship/application"
                          element={<External />}
                        />
                      </Route>

                      {/* <Route path="/secure" element={<AuthLayout />}>
                      <Route index element={<Navigate to="/secure/login" />} />

                      <Route path="login" element={<Login />} />
                      <Route path="register" element={<Registeration />} />

                      <Route
                        path="*"
                        element={<Navigate to="/secure/login" />}
                      />
                    </Route> */}

                      <Route path="/secure">
                        <Route
                          index
                          element={<Navigate to="/secure/login" />}
                        />
                        {/* Currently working version */}
                        <Route path="login" element={<RevampedLogin />} />
                        {/* <Route path="loginv2" element={<Login_v2 />} /> */}
                        <Route
                          path="register"
                          element={<RevampedRegistrationV2 />}
                        />
                        {/* <Route
                        path="registerv2"
                        element={<RegistrationLayoutv2 />}
                      >
                        <Route path="step-1" element={<PersonalInfoFormv2 />} />
                        <Route
                          path="step-2"
                          element={<CurrentEducationLevel />}
                        />
                        <Route
                          path="study-location-preference"
                          element={<StudyLocationPreference />}
                        />

                        <Route
                          index
                          element={<Navigate to="personal-info" />}
                        />
                      </Route> */}
                        <Route
                          path="registerv3"
                          element={<RevampedRegistrationV2 />}
                        />
                      </Route>

                      <Route path="/dashboard" element={<ProtectedRoute />}>
                        <Route element={<DashboardLayout />}>
                          <Route path="" element={<DashboardHome />} />
                          <Route path="my-profile" element={<MyProfile />} />
                          <Route
                            path="college-admission"
                            element={<Admissions />}
                          />
                          <Route path="mentors" element={<Mentor />} />

                          {/* Add more dashboard routes here */}

                          {/* Fallback route for dashboard */}
                          <Route path="*" element={<NotFoundPage />} />
                        </Route>
                      </Route>
                      <Route path="/" element={<AuthHeader />}>
                        <Route
                          path="terms-condition"
                          element={<TermsAndConditions />}
                        />
                        <Route path="terms" element={<TermsAndConditions />} />
                        <Route
                          path="privacy-policy"
                          element={<PrivacyPolicy />}
                        />
                        <Route path="disclaimer" element={<Disclaimer />} />
                      </Route>

                      <Route element={<Layout />}>
                        <Route
                          path="/scholarships"
                          element={<Scholarships />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/college-mentor-scholarship-results"
                          element={<ScholarshipResults />}
                        />
                      </Route>
                      <Route element={<Layout />}>
                        <Route path="/map-view" element={<MapView />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/study-abroad"
                          element={<StudyAbroadLanding />}
                        ></Route>
                        <Route
                          path="/study-abroad-student"
                          element={<StudyAbroadStudent />}
                        ></Route>
                        <Route
                          path="/study-abroad-university"
                          element={<StudyAbroadUniversity />}
                        ></Route>
                      </Route>

                      <Route element={<SignInOutlet />}>
                        <Route path="/sign-in" element={<OldLogin />} />
                        <Route
                          path="/reset-password"
                          element={<ResetPassword />}
                        />
                        <Route
                          path="/forget-password"
                          element={<ForgotPassword />}
                        />
                        <Route
                          path="/otp-verification"
                          element={<OTPVerification />}
                        />
                        <Route path="/forgot-email" element={<ForgotEmail />} />
                        <Route
                          path="/password-reset"
                          element={<PasswordReset />}
                        />
                        <Route
                          path="/registration"
                          element={<Registration />}
                        />
                        <Route
                          path="/personal-info"
                          element={<PersonalInfo />}
                        />
                        <Route
                          path="/standardInfo"
                          element={<StandardInfo />}
                        />
                        <Route
                          path="/dreamCareerInfo"
                          element={<DreamCareerInfo />}
                        />
                        <Route path="/stateInfo" element={<StateInfo />} />
                        <Route path="/fundInfo" element={<PlansToFund />} />
                      </Route>
                      <Route element={<Layout />}>
                        <Route
                          path="/college-predictor"
                          element={<CollegePredictorLanding />}
                        />
                        <Route
                          path="/student-mentorship"
                          element={
                            <StudentDashboard
                              token={localStorage.getItem("accessToken") || ""}
                            />
                          }
                        />
                        <Route
                          path="/mentor-dashboard"
                          element={<MentorDashboard />}
                        />
                        <Route
                          path="/college-predictor/:exam"
                          element={<CollegePredictorIntermediate />}
                        />
                        <Route
                          path="/college-predictor-result/:exam/:rank"
                          element={<CollegePredictorResults />}
                        />
                        <Route
                          path="/college-predictor-details"
                          element={<CollegePredictorDetails />}
                        />

                        <Route
                          path="/education-for-all"
                          element={<EducationForAllLanding />}
                        />
                        <Route
                          path="/education-for-all-intermediate"
                          element={<EducationForAllIntermediate />}
                        />
                        <Route
                          path="/education-boards"
                          element={<EducationBoards />}
                        />
                        <Route
                          path="/education-board/:boardName"
                          element={<EducationBoardsDetails />}
                        />
                        <Route path="/courses/quiz/:topic" element={<Quiz />} />
                      </Route>
                    </>
                  )}
                </Routes>
                {/* </DevProtectedRoute> */}
              </div>
            </div>
          </DevProtectedRoute>
        </Router>
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
}

const CourseDetailsWrapper: React.FC = () => {
  const { courseName } = useParams<{ courseName: string }>();

  return (
    <CourseDetailsProvider courseName={courseName!}>
      <CourseDetails />
    </CourseDetailsProvider>
  );
};

export default App;
