import React, { useEffect, useRef, useState, useCallback } from "react";
import { EpRight } from "../../../components/college-details/components/NavigationTabs";
import TabContent from "./TabContent";
import EducationSidebarAdds from "./EducationSidebarAdds";
import data from "./data/educationSidebarData.json";

interface Tab {
  tabName: string;
  tabTitle: string;
}

interface NavigationTabsProps {
  tabs: Tab[];
  boardName: string;
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({ tabs, boardName }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.tabName || "");
  const [isSticky, setIsSticky] = useState(false);
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const leftContentRef = useRef<HTMLDivElement>(null);
  const [leftHeight, setLeftHeight] = useState<number>(0);
  const [visibleSections, setVisibleSections] = useState<any[]>([]);
  const [isContentAvailable, setIsContentAvailable] = useState<boolean>(true);

  const calculateLeftHeight = useCallback((hasContent: boolean) => {
    setIsContentAvailable(hasContent);
    if (leftContentRef.current) {
      setLeftHeight(leftContentRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => setIsSticky(window.scrollY >= 730);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const checkScroll = () => {
      const el = tabContainerRef.current;
      if (el) {
        setCanScrollLeft(el.scrollLeft > 0);
        setCanScrollRight(el.scrollWidth > el.scrollLeft + el.clientWidth);
      }
    };
    checkScroll();
    window.addEventListener("resize", checkScroll);
    tabContainerRef.current?.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("resize", checkScroll);
      tabContainerRef.current?.removeEventListener("scroll", checkScroll);
    };
  }, []);

  const scrollTabs = (dir: "left" | "right") => {
    const el = tabContainerRef.current;
    if (el) {
      const scrollAmount = el.clientWidth / 2;
      el.scrollBy({
        left: dir === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
    window.history.replaceState(null, "", `#${tabName}`);
  };
  useEffect(() => {
    let totalHeight = 0;
    const selectedSections: any[] = [];
    const maxHeight = leftHeight || 400;

    data.forEach((section: any) => {
      totalHeight += section.items.length * 50 + 50;
      if (totalHeight <= maxHeight) {
        selectedSections.push(section);
      }
    });

    setVisibleSections(
      selectedSections.length > 0 ? selectedSections : data.slice(0, 2)
    );
  }, [leftHeight, activeTab]);

  useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    const defaultTab = tabs.find((tab) => tab.tabName === hash) || tabs[0];
    setActiveTab(defaultTab.tabName);
  }, [tabs]);

  return (
    <div className="relative">
      <div
        className={`flex items-center justify-start space-x-4 px-4 py-2 overflow-x-auto transition-opacity duration-500 ${
          isSticky ? "fixed top-0 left-0 w-full z-50 shadow-lg bg-white" : ""
        }`}
        style={{
          background: "linear-gradient(180deg, #173CBA 0%, #06AFA9 100%)",
        }}
      >
        <div className="container mx-auto flex gap-3">
          <button
            className={`bg-[#00CC99] border border-white rounded text-white p-2 hidden sm:block ${
              canScrollLeft ? "opacity-100" : "opacity-50 cursor-not-allowed"
            }`}
            onClick={() => canScrollLeft && scrollTabs("left")}
          >
            <EpRight className="rotate-180" />
          </button>

          <div
            ref={tabContainerRef}
            className="overflow-x-scroll sm:overflow-x-auto w-full mx-auto mobile-scroll scrollbar-thumb-[#00B489]"
          >
            <ul className="list-none flex gap-4 items-center text-center w-full mx-auto xl:justify-start">
              {tabs.map((tab) => (
                <li
                  key={tab.tabName}
                  className={`text-base py-1.5 px-3.5 font-semibold rounded-sm cursor-pointer whitespace-nowrap ${
                    activeTab === tab.tabName
                      ? "bg-green text-white"
                      : "bg-white text-black hover:bg-green hover:text-white"
                  }`}
                  onClick={() => handleTabChange(tab.tabName)}
                >
                  {tab.tabTitle}
                </li>
              ))}
            </ul>
          </div>

          <button
            className={`bg-[#00CC99] border border-white rounded text-white p-2 hidden sm:block ${
              canScrollRight ? "opacity-100" : "opacity-50 cursor-not-allowed"
            }`}
            onClick={() => canScrollRight && scrollTabs("right")}
          >
            <EpRight />
          </button>
        </div>
      </div>

      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row gap-9 py-8">
          <div
            ref={leftContentRef}
            className="w-full md:w-9/12 mt-4 min-h-[500px]"
          >
            <TabContent
              boardName={boardName}
              tabName={activeTab}
              onLoad={calculateLeftHeight}
            />
          </div>

          <div className="w-full md:w-1/4 mt-4">
            {isContentAvailable && (
              <EducationSidebarAdds sections={visibleSections} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationTabs;
