// src/components/CollegeNewsAndNotifications/CollegeNewsAndNotifications.tsx

import React, { useEffect, useState } from 'react';
import config from "../../../util/config";
import Loader from '../../Loader/Loader';

// Assuming you have a config file for apiUrl

// Interface for the structure of individual news items (key_1, key_2, ...)
interface NewsItemDetail {
  imageUrl: string;
  array?: string[]; // Optional array, might contain image URLs
  description: string;
  orderIndex: string; // Used for sorting
  // Add other potential fields if needed
}

// Interface for the structure within response.data.details
interface CollegeDetails {
  similarColleges?: any[]; // Not used for news cards, but part of the structure
  key_0?: { // General info, potentially title
    description?: string;
    title?: string;
  };
  // Dynamically includes key_1, key_2, etc.
  [key: string]: any; // Allows accessing key_1, key_2 etc. dynamically
}

// Interface for the expected API response structure
interface ApiResponse {
  message: string;
  data: {
    details: CollegeDetails;
  };
  timeStamp: string;
}

// Interface for the processed news item data to be stored in state
interface ProcessedNewsItem {
  description: string;
  imageUrl: string; // Will use placeholder if not found
  order: number;
}

const CollegeNewsAndNotifications: React.FC<{ collegeName: string, collegeTitle: string }> = ({ collegeName,collegeTitle }) => {
  // Get collegeName from URL parameters
  const [newsItems, setNewsItems] = useState<ProcessedNewsItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Placeholder image URL (replace with your actual placeholder)
  const PLACEHOLDER_IMAGE_URL = ''; // Or a web URL

  useEffect(() => {
    const fetchCollegeNews = async () => {
      if (!collegeName) {
        setError('College name is missing in the URL.');
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null); // Reset error on new fetch

      try {
        const apiUrl = `${config.apiUrl}/api/college/college/collegeDetails/${collegeName}/college-news-notifications`;
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: { Accept: '*/*' },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch college news (Status: ${response.status})`);
        }

        const result: ApiResponse = await response.json();

        if (result && result.data && result.data.details) {
          const details = result.data.details;

          // --- Process News Items (key_1, key_2, ...) ---
          const processedItems: ProcessedNewsItem[] = [];
          // Iterate over keys in details, looking for 'key_N' where N > 0
          for (const key in details) {
            if (key.startsWith('key_') && key !== 'key_0') {
              const item = details[key] as NewsItemDetail;
              if (item && item.description && item.orderIndex) {
                // Check if item.array exists and has an image URL

                processedItems.push({
                  description: item.description, // Assuming plain text based on example key_1
                  imageUrl: item.imageUrl,
                  order: parseInt(item.orderIndex, 10) || 999, // Use orderIndex for sorting, fallback
                });
              }
            }
          }

          // Sort items based on the order index
          processedItems.sort((a, b) => a.order - b.order);

          setNewsItems(processedItems);
        } else {
          throw new Error('Invalid data structure received from API.');
        }
      } catch (err: any) {
        console.error('Error fetching college news:', err);
        setError(err.message || 'Unable to load college news and notifications.');
        setNewsItems([]); // Clear news items on error
      } finally {
        setLoading(false);
      }
    };

    fetchCollegeNews();
  }, [collegeName]); // Re-run effect if collegeName changes

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="bg-gray-100 py-8">
        <div className="container mx-auto text-center text-red-600 font-semibold p-4 border border-red-300 bg-red-50 rounded">
          Error: {error}
        </div>
      </div>
    );
  }

  return (
    // Using a light gray background similar to the target image's overall background
    newsItems.length > 0 ? (<div className="bg-gray-100 py-8">
      <div className="container mx-auto px-4"> {/* Added horizontal padding */}
        <div className="flex flex-col gap-5">
          {/* Title Section */}
          <h2 className="text-2xl md:text-3xl font-normal text-blue mb-4"> {/* Adjusted styling */}
            {collegeTitle} News and Notifications
          </h2>

          {/* News Cards Section */}
          {newsItems.length > 0 ? (
            // Use a responsive grid for the cards
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {newsItems.map((item, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow p-4 flex items-start gap-3 h-[140px] overflow-hidden" // Fixed height, added shadow, adjusted padding/gap
                >
                  {/* Image Placeholder Area */}
                  <div className="w-20 h-20 flex-shrink-0 bg-gray-200 rounded flex items-center justify-center overflow-hidden"> {/* Adjusted size, background */}
                    <img
                      // Use item.imageUrl which defaults to placeholder if needed
                      src={item.imageUrl}
                      className="w-full h-full object-cover" // Ensure image covers the area
                      // Add error handling for broken image URLs
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null; // Prevent infinite loop
                        target.src = PLACEHOLDER_IMAGE_URL; // Fallback to placeholder on error
                      }}
                    />
                  </div>
                  {/* Text Description Area */}
                  <div className="flex-grow">
                    <p className="text-sm text-gray-700 line-clamp-5"> {/* Limit lines */}
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // Message when no news items are available
            <div className="text-center text-gray-500 py-4">
              No news or notifications found for this college.
            </div>
          )}
        </div>
      </div>
    </div>):<></>
  );
};

export default CollegeNewsAndNotifications;
