import React from "react";

const RegulatoryProfessionalBanner = () => {
  return (
    <div>
      <div className="px-20 py-16 w-full max-md:px-5 max-md:max-w-full bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED] max-md:py-6 max-md:my-0">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-3/5 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col w-full text-[#173CBA] max-md:max-w-full my-auto">
              <h1 className="md:text-[40px] font-normal leading-normal max-md:max-w-full max-md:text-4xl">
                Guardians of Standards <br />
                Empowering Education and <br />
                Professional Excellence Across India
              </h1>
              <p className="text-black font-normal leading-6">
                In India, various professional and regulatory councils oversee
                different educational streams, ensuring quality, standards, and
                compliance for approvals and permissions. Here’s an overview of
                key councils by educational streams
              </p>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-2/5 max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src="/images/regulatory-professionals/india-banner.png"
              alt="Refer and Earn illustration"
              className="object-contain grow w-full aspect-[1.21] max-md:mt-10 max-md:max-w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegulatoryProfessionalBanner;
