import React, { useState, useEffect } from "react";
import "./Preference.scss";
import LeadGeneration from "../../../../components/LeadGeneration";
import { useNavigate, useLocation } from "react-router-dom";

const questions = [
  {
    id: 1,
    text: "What is your preferred course?",
    options: ["Engineering", "Medicine", "Liberal Arts", "Others"],
  },
  {
    id: 2,
    text: "What type of college do you prefer?",
    options: [
      "Public University",
      "Private Deemed University",
      "State Private University",
      "Private Colleges",
    ],
  },
  {
    id: 3,
    text: "What is your expected budget for tuition fees?",
    options: [
      "Below 1 Lakh",
      "1:5 Lakhs To 2 Lakhs",
      "2:5 Lakhs To 3:5 Lakhs",
      "Above 3.5 Lakhs",
    ],
  },
  {
    id: 4,
    text: "Which city do you prefer for studies?",
    options: ["Hyderabad", "Chennai", "Bangalore", "North India"],
  },
  {
    id: 5,
    text: "Which College/University among these you preferably choose?",
    options: [
      "VIT University",
      "SRM University",
      "Amrita University",
      "Bharath University",
      "GITAM University",
    ],
  },
  {
    id: 6,
    text: "Which entrance exam will You be taking?",
    options: ["JEE", "NEET", "CLAT", "NID", "Private Universities"],
  },
  {
    id: 7,
    text: "What type of scholarships are you interested in?",
    options: [
      "Merit-based Scholarships",
      "Need-based Scholarships",
      "Sports Scholarships",
      "Minority Scholarships",
    ],
  },
];

const Preference: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [leadGenerationPopupIsOpen, setLeadGenerationPopupIsOpen] =
    useState(false);
  const [currentUser, setCurrentUser] = useState<string | null>(
    localStorage.getItem("user")
  );
  const { pathname } = useLocation();
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  const [showThankYou, setShowThankYou] = useState(false);
  console.log("answers", currentQuestionIndex, selectedAnswers, currentUser);
  useEffect(() => {
    const savedAnswers = localStorage.getItem("selectedAnswers");
    const savedIndex = localStorage.getItem("currentQuestionIndex");

    if (savedAnswers && savedIndex) {
      setSelectedAnswers(JSON.parse(savedAnswers));
      setCurrentQuestionIndex(parseInt(savedIndex, 10));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedAnswers", JSON.stringify(selectedAnswers));
    localStorage.setItem(
      "currentQuestionIndex",
      currentQuestionIndex.toString()
    );
  }, [selectedAnswers, currentQuestionIndex]);

  useEffect(() => {
    if (leadGenerationPopupIsOpen) {
      if (!user) {
        navigate("/secure/login", { state: { sourcePagePath: pathname } });
      }
    }
  }, [leadGenerationPopupIsOpen]);
  const handleResponse = (answer: string) => {
    if (currentQuestionIndex === 1 && !currentUser) {
      setLeadGenerationPopupIsOpen(true);
    } else if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswers([...selectedAnswers, answer]);
    } else {
      setShowThankYou(true);
      localStorage.removeItem("selectedAnswers");
      localStorage.removeItem("currentQuestionIndex");
      setCurrentQuestionIndex(0);
    }
  };

  return (
    <div className="preference w-full relative pb-4">
      <div className="container flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 flex flex-col mt-8">
          {showThankYou ? (
            <p className="thank-you-message text-white">
              Thank you for your response, our mentors will connect with you for
              further updates based on your interest.
            </p>
          ) : (
            questions[currentQuestionIndex] && (
              <Question
                questionText={questions[currentQuestionIndex].text}
                options={questions[currentQuestionIndex].options}
                onResponse={handleResponse}
              />
            )
          )}
        </div>
        <InfoSection />
      </div>
    </div>
  );
};

export default Preference;

const InfoSection: React.FC = () => (
  <div className="w-full md:w-1/2 flex flex-col md:items-center mt-8 md:mt-4 md:ml-12 mb-8 md:mb-0 max-sm:mb-2">
    <img
      src="https://cdncollegementor.blob.core.windows.net/colleges/collegeLanding/what-is-preferred-course/school.webp"
      className="w-[180px] h-[160px]"
      alt="School"
    />
    <div className="flex flex-row items-center gap-4 md:items-start md:flex-col mt-8 md:ml-12 lg:ml-24">
      <div>
        <span className="number-in px-4 py-2 text-[#FFF]">169</span>
      </div>
      <p className="flex justify-start py-2 text-white font-sans text-sm font-semibold leading-normal">
        For early bird admissions of 2025 connect with College Mentor Phone: +91
        8959591818
      </p>
    </div>
  </div>
);

export const Question: React.FC<{
  questionText: string;
  options: string[];
  onResponse: (answer: string) => void;
}> = ({ questionText, options, onResponse }) => (
  <div className="question-container">
    <p className="para-pref pr-12 md:pr-0">{questionText}</p>
    <div className="mt-4 gap-4 grid grid-cols-2">
      {options.map((option, index) => (
        <button
          key={index}
          className="button-pref"
          onClick={() => onResponse(option)}
        >
          {option}
        </button>
      ))}
    </div>
    <p className="mt-2 parasub-pref">
      Submit your response to view opinions of other parents
    </p>
  </div>
);
