import React, { FC, useEffect, useState } from "react";
import bannerImg from "../images/banner-img.png";
import Breadcrumbs from "../../../../components/breadcrumbs/Breadcrumbs";
import config from "../../../../util/config";

interface IProps {
  title: string;
  logo: string;
}

const Banner: FC<IProps> = ({ title, logo }) => {
  const isMdDevice = window.innerWidth >= 768;
  return (
    <section
      style={{
        background: `linear-gradient(${
          isMdDevice ? 90 : 180
        }deg, #0B3D8C 0.11%, #107CC4 54.49%, rgba(6, 175, 169, 0.745055) 83.43%)`,
      }}
    >
      <div className="container max-w-screen-2xl text-white py-6">
        <Breadcrumbs
          links={[
            { name: "Home", path: "/" },
            {
              name: "List of scholarships in India",
              path: "/scholarships/list-of-scholarships-in-india",
            },
            {
              name: title,
              path: "",
            },
          ]}
        />
        <div className="flex flex-col md:flex-row gap-8">
          <div className="relative w-full md:w-1/2 flex flex-col justify-center pt-12 md:pt-0">
            {/* <img src={logo} className="w-48" alt="scholarship brand logo" /> */}
            <div>
              <h1 className="text-4xl mt-6 font-bold">{title}</h1>
            </div>
            <div className="flex flex-row  gap-2 lg:gap-10 items-center mt-6 ">
              <button className="flex flex-row text-white text-sm lg:text-base font-medium leading-8 rounded-lg whitespace-nowrap items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="22"
                  viewBox="0 0 25 22"
                  fill="none"
                  className="pr-2"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.3166 3.39256C10.054 0.867095 6.27325 0.0866171 3.43839 2.39918C0.603515 4.71175 0.204404 8.57821 2.43065 11.3133C4.28162 13.5873 9.88329 18.3835 11.7192 19.9358C11.9246 20.1095 12.0273 20.1963 12.1471 20.2304C12.2516 20.2602 12.366 20.2602 12.4707 20.2304C12.5905 20.1963 12.6931 20.1095 12.8985 19.9358C14.7345 18.3835 20.3361 13.5873 22.1871 11.3133C24.4133 8.57821 24.0629 4.68742 21.1793 2.39918C18.2957 0.110944 14.5792 0.867095 12.3166 3.39256Z"
                    stroke="#00CC99"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Save
              </button>
              <a
                href={`https://api.whatsapp.com/send?text=hi&amp;phone=91${config.mobileNumber}`}
                target="_blank"
                className="flex flex-row text-white text-sm lg:text-base font-medium leading-8  rounded-lg items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="askIcon pr-2"
                >
                  <circle
                    cx="12"
                    cy="11.9998"
                    r="11"
                    stroke="#00CC99"
                    stroke-width="2"
                  />
                  <path
                    d="M8 8.28895C8 7.64647 8.17331 6.99187 8.51993 6.32514C8.86654 5.65842 9.37628 5.11291 10.0491 4.67651C10.722 4.24011 11.4968 4.02191 12.3837 4.02191C13.2095 4.02191 13.9435 4.20375 14.5755 4.56741C15.2076 4.93108 15.697 5.4281 16.0436 6.04633C16.3902 6.67669 16.5635 7.35553 16.5635 8.08287C16.5635 8.66474 16.4616 9.16175 16.2679 9.59815C16.0742 10.0346 15.8397 10.4103 15.5644 10.7255C15.2892 11.0407 14.81 11.5741 14.1066 12.3257C13.9129 12.5317 13.76 12.7257 13.6376 12.8833C13.5255 13.0409 13.4337 13.1863 13.3726 13.3197C13.3114 13.453 13.2706 13.5864 13.24 13.7197C13.2095 13.8531 13.1585 14.0834 13.0973 14.4228C12.9852 15.1259 12.6488 15.4775 12.0779 15.4775C11.7822 15.4775 11.5273 15.3562 11.3235 15.1259C11.1196 14.8956 11.0176 14.5562 11.0176 14.0955C11.0176 13.5258 11.089 13.0288 11.2419 12.6166C11.3948 12.2044 11.5885 11.8287 11.8332 11.5135C12.0779 11.1983 12.4143 10.8225 12.8323 10.3861C13.1993 10.0103 13.4643 9.71937 13.6274 9.52542C13.7906 9.33146 13.9231 9.11326 14.0352 8.88294C14.1474 8.64049 14.1983 8.38593 14.1983 8.10712C14.1983 7.56161 14.025 7.10097 13.6886 6.7373C13.3522 6.36151 12.9138 6.17968 12.3735 6.17968C11.7414 6.17968 11.2827 6.37363 10.987 6.74942C10.6914 7.12521 10.4365 7.68284 10.2326 8.41017C10.0389 9.17387 9.67192 9.56179 9.13161 9.56179C8.81557 9.56179 8.54032 9.42844 8.32623 9.16175C8.11214 8.88294 8 8.60413 8 8.28895ZM12.1798 19.4536C11.8332 19.4536 11.5273 19.3202 11.2623 19.0535C10.9972 18.7868 10.8749 18.411 10.8749 17.9261C10.8749 17.5019 10.9972 17.1382 11.2521 16.8473C11.507 16.5563 11.8128 16.4109 12.1798 16.4109C12.5366 16.4109 12.8425 16.5563 13.0871 16.8473C13.3318 17.1382 13.4541 17.5019 13.4541 17.9261C13.4541 18.3989 13.3216 18.7747 13.0667 19.0414C12.8221 19.3202 12.5162 19.4536 12.1798 19.4536Z"
                    fill="#00CC99"
                  />
                </svg>
                Ask a Question
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex items-center justify-center">
            <img
              src={bannerImg}
              className="w-full md:w-2/3 aspect-square object-cover rounded-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
