import React from "react";
import educationDetailsBanner from "../../../images/education-details-banner.webp";

const EducationBanner: React.FC = () => {
  const isMdDevice = typeof window !== 'undefined' && window.innerWidth >= 768; // Added check for window existence for SSR safety

  return (
    <div
      style={{
        background: `linear-gradient(${
          isMdDevice ? 90 : 180
        }deg, #0B3D8C 0.11%, #107CC4 54.49%, rgba(6, 175, 169, 0.75) 83.43%)`,
      }}
      // Consider adjusting vertical padding if needed after changing margins
      className="w-full text-white py-16 max-md:py-6 flex justify-start items-start xl:min-h-[526px]"
    >
      {/* Added container max-width and margin auto for centering */}
      <div className="container mx-auto px-4"> {/* Added px-4 for padding on smaller screens */}
        {/* Reduced top margin here for potentially better vertical alignment */}
        <h3 className="text-4xl min-max-xss:text-3xl md:text-4xl font-bold text-left leading-[55px] md:w-[375px] mt-10 md:mt-16"> {/* Reduced mt-[66px] slightly */}
          Search for Boards in India
        </h3>
        {/* Increased margin-top slightly for better spacing below title */}
        <div className="relative mt-6 max-w-lg md:w-[375px] text-xs font-normal"> {/* Changed mt-3 to mt-6 */}
          <input
            type="text"
            placeholder="Start Board, National Board and International Board"
            className="w-full p-3 pl-10 text-black rounded-md shadow-md focus:outline-none"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#A3A2AC]"
          >
            <path
              d="M13.3484 12.1421C14.2435 10.878 14.7789 9.32527 14.7789 7.64201C14.7789 3.42835 11.4638 0 7.38942 0C3.31501 0 0 3.42835 0 7.64201C0 11.8557 3.31505 15.284 7.38945 15.284C9.01707 15.284 10.5186 14.7303 11.7409 13.8046L16.5819 18.811L18.1895 17.1485C18.1895 17.1485 13.3484 12.1421 13.3484 12.1421ZM7.38945 12.9326C4.56844 12.9326 2.27369 10.5594 2.27369 7.64201C2.27369 4.72459 4.56844 2.3514 7.38945 2.3514C10.2105 2.3514 12.5052 4.72459 12.5052 7.64201C12.5052 10.5594 10.2104 12.9326 7.38945 12.9326Z"
              fill="#173CBA" // Consider changing fill color if needed based on design
            />
          </svg>
        </div>
        {/* THIS IS THE MAIN CHANGE - Reduced mt-[149px] significantly */}
        <button className="mt-8 bg-[#0C9] text-white p-3 rounded-md shadow-lg flex items-center gap-1"> {/* Changed mt-[149px] to mt-8 */}
          Get Board Alerts
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
          >
            <path
              d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default EducationBanner;