import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../util/config";
import { closeAlert } from "../../features/lead-generation/leadGenSlice";
import { RootState } from "../../store/store";
import {BaseURLAuth} from "../../services/api";

export const postCtaData = createAsyncThunk(
  "leadGen/postCtaData",
  async (
    { leadPageName, leadOn }: { leadPageName: string; leadOn: string },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      await BaseURLAuth.post(
        "/api/leads/create",
        {
          leadPageName: leadPageName,
          leadOn: leadOn,
          userId: JSON.parse(localStorage.getItem("user") || "{}")?.userId,
          scheduleOn: new Date(Date.now()).toISOString(),
        },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      setTimeout(() => {
        dispatch(closeAlert());
      }, 5000);

      return null;
    } catch (e) {
      setTimeout(() => {
        dispatch(closeAlert());
      }, 5000);

      return rejectWithValue("Error creating lead");
    }
  }
);
