// CollegeCard.tsx
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import config from "../../../util/config";
import { BaseURL } from "../../../services/api";

interface CollegeCardProps {
  collegeName: string;
  image: string;
  category: string;
  categoryId: number;
  location: string;
  type: string;
}
interface Shortlist {
  image: string;
  title: string;
  category: string;
  collegeId?: number;
  location: string;
}

const CollegeCard: React.FC<CollegeCardProps> = ({
  collegeName,
  image,
  category,
  categoryId,
  location,
  type,
}) => {
  const LocationIcon = () => (
    <div className="">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 256 256"
        fill="none"
      >
        <g transform="scale(2.81 2.81)">
          <path
            d="M 45 90 c -1.415 0 -2.725 -0.748 -3.444 -1.966 l -4.385 -7.417 C 28.167 65.396 19.664 51.02 16.759 45.189 c -2.112 -4.331 -3.175 -8.955 -3.175 -13.773 C 13.584 14.093 27.677 0 45 0 c 17.323 0 31.416 14.093 31.416 31.416 c 0 4.815 -1.063 9.438 -3.157 13.741 c -0.025 0.052 -0.053 0.104 -0.08 0.155 c -2.961 5.909 -11.41 20.193 -20.353 35.309 l -4.382 7.413 C 47.725 89.252 46.415 90 45 90 z"
            fill="rgb(4,136,219)"
          />
          <path
            d="M 45 45.678 c -8.474 0 -15.369 -6.894 -15.369 -15.368 S 36.526 14.941 45 14.941 c 8.474 0 15.368 6.895 15.368 15.369 S 53.474 45.678 45 45.678 z"
            fill="rgb(255,255,255)"
          />
        </g>
      </svg>
    </div>
  );
  const DetailIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 256 256"
      fill="none"
    >
      <g transform="scale(2.81 2.81)">
        <path
          d="M 71.4 1 H 18.6 c -1.144 0 -2.071 0.927 -2.071 2.071 v 70.736 v 13.122 c 0 1.144 0.927 2.071 2.071 2.071 h 14.488 h 23.824 h 9.584 c 3.852 0 6.975 -3.123 6.975 -6.975 v -8.218 V 3.071 C 73.471 1.927 72.543 1 71.4 1 z"
          fill="rgb(249,249,249)"
        />
        <path
          d="M 57.049 66.54 l -6.673 -2.039 c -0.862 -0.263 -1.668 0.543 -1.404 1.404 l 2.039 6.673 c 0.294 0.961 0.819 1.836 1.53 2.547 l 10.08 10.08 l 2.645 2.645 c 1.225 1.225 3.21 1.225 4.435 0 l 2.62 -2.62 c 1.225 -1.225 1.225 -3.21 0 -4.435 l -2.645 -2.645 l -10.08 -10.08 C 58.885 67.359 58.01 66.834 57.049 66.54 z"
          fill="rgb(255,220,141)"
        />
        <path
          d="M 62.621 85.205 l 2.645 2.645 c 1.225 1.225 3.21 1.225 4.435 0 l 2.62 -2.62 c 1.225 -1.225 1.225 -3.21 0 -4.435 l -2.645 -2.645 l 0 0 C 69.676 78.15 62.621 85.205 62.621 85.205 z"
          fill="rgb(255,255,255)"
        />
        <path
          d="M 56.912 90 H 18.601 c -1.693 0 -3.071 -1.378 -3.071 -3.071 V 3.071 C 15.529 1.377 16.907 0 18.601 0 h 52.799 c 1.693 0 3.071 1.377 3.071 3.071 v 70.737 c 0 0.553 -0.447 1 -1 1 s -1 -0.447 -1 -1 V 3.071 C 72.471 2.48 71.99 2 71.399 2 H 18.601 c -0.591 0 -1.071 0.48 -1.071 1.071 v 83.858 c 0 0.591 0.48 1.071 1.071 1.071 h 38.312 c 0.553 0 1 0.447 1 1 S 57.465 90 56.912 90 z"
          fill="rgb(0,0,0)"
        />
        <path d="M 67.482 89.767 c -1.059 0 -2.117 -0.402 -2.924 -1.209 L 51.834 75.832 c -0.824 -0.824 -1.439 -1.848 -1.779 -2.962 l -2.039 -6.673 c -0.23 -0.752 -0.027 -1.566 0.529 -2.123 c 0.557 -0.558 1.368 -0.763 2.124 -0.529 l 6.672 2.039 l 0 0 c 1.114 0.34 2.138 0.955 2.962 1.779 l 12.725 12.725 c 1.612 1.612 1.612 4.236 0 5.849 l -2.62 2.621 C 69.602 89.364 68.542 89.767 67.482 89.767 z" />
        <path
          d="M 50.043 65.45 c -0.023 0 -0.054 0.008 -0.084 0.038 c -0.053 0.053 -0.037 0.104 -0.031 0.124 l 2.039 6.674 c 0.245 0.802 0.688 1.539 1.281 2.132 l 12.725 12.726 c 0.833 0.833 2.189 0.83 3.021 0 l 2.62 -2.621 c 0.833 -0.833 0.833 -2.188 0 -3.021 L 58.889 68.777 c -0.593 -0.593 -1.33 -1.036 -2.132 -1.281 l -6.673 -2.039 C 50.075 65.455 50.061 65.45 50.043 65.45 z"
          fill="rgb(0,0,0)"
        />
        <path
          d="M 62.621 86.205 c -0.256 0 -0.512 -0.098 -0.707 -0.293 c -0.391 -0.391 -0.391 -1.023 0 -1.414 l 7.055 -7.055 c 0.391 -0.391 1.023 -0.391 1.414 0 s 0.391 1.023 0 1.414 l -7.055 7.055 C 63.133 86.107 62.877 86.205 62.621 86.205 z"
          fill="rgb(0,0,0)"
        />
        <path
          d="M 62.936 46 H 27.064 c -0.552 0 -1 -0.448 -1 -1 s 0.448 -1 1 -1 h 35.872 c 0.553 0 1 0.448 1 1 S 63.488 46 62.936 46 z"
          fill="rgb(0,0,0)"
        />
        <path
          d="M 62.936 56.353 H 27.064 c -0.552 0 -1 -0.447 -1 -1 s 0.448 -1 1 -1 h 35.872 c 0.553 0 1 0.447 1 1 S 63.488 56.353 62.936 56.353 z"
          fill="rgb(0,0,0)"
        />
        <path
          d="M 37.054 66.706 h -9.99 c -0.552 0 -1 -0.447 -1 -1 s 0.448 -1 1 -1 h 9.99 c 0.552 0 1 0.447 1 1 S 37.606 66.706 37.054 66.706 z"
          fill="rgb(0,0,0)"
        />
        <path
          d="M 43.524 77.059 h -16.46 c -0.552 0 -1 -0.447 -1 -1 s 0.448 -1 1 -1 h 16.46 c 0.552 0 1 0.447 1 1 S 44.076 77.059 43.524 77.059 z"
          fill="rgb(0,0,0)"
        />
        <circle cx="45.002" cy="23.492" r="12.722" fill="rgb(255,220,141)" />
      </g>
    </svg>
  );
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [shortListData, setShortListData] = useState<Shortlist[]>([]);

  const [userId, setUserId] = useState<number | null>(null);
  console.log("im in college card ", userId);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserId(parsedUser.userId);
    }
  }, []);
  useEffect(() => {
    if (userId) {
      const userId = JSON.parse(localStorage.getItem("user") ?? "{}")?.userId;
    const accessToken = localStorage.getItem("accessToken");
      BaseURL.get(`/api/wishlist/category/${userId}?category=college`, {
        headers: {
          Authorization: accessToken ? `${accessToken}` : "",
        },
      })
        .then((response) => {
          setShortListData(response.data.data);
          const isCollegeWishlisted = response.data.data.some(
            (item: Shortlist) => item.collegeId === categoryId
          );
          setIsWishlisted(isCollegeWishlisted);
        })
        .catch((error) => {
          console.error("Error fetching mentor data:", error);
        });
    } else {
      console.log("userId is null, skipping API call");
    }
  }, [userId]);
  const WishlistIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="30"
      height="30"
      viewBox="0 0 256 256"
      xmlSpace="preserve"
    >
      <g
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        fill={isWishlisted ? "red" : "black"} // Set fill color for the entire group
      >
        <path d="M 64.44 12.016 c 5.225 0 10.136 2.035 13.831 5.729 c 7.626 7.626 7.626 20.035 0 27.662 l -19.44 19.44 L 45 78.677 L 31.169 64.846 l -19.44 -19.44 c -7.626 -7.626 -7.626 -20.035 0 -27.662 c 3.694 -3.694 8.606 -5.729 13.831 -5.729 c 5.225 0 10.136 2.035 13.831 5.729 l 1.367 1.367 L 45 23.354 l 4.242 -4.242 l 1.367 -1.367 C 54.304 14.05 59.216 12.016 64.44 12.016 M 64.44 6.016 c -6.541 0 -13.083 2.495 -18.073 7.486 L 45 14.869 l 0 0 l 0 0 l -1.367 -1.367 C 38.642 8.511 32.101 6.016 25.56 6.016 S 12.477 8.511 7.486 13.502 c -9.982 9.982 -9.982 26.165 0 36.147 l 19.44 19.44 c 0 0 0 0 0.001 0 L 45 87.163 l 18.073 -18.073 c 0 0 0 0 0 0 l 19.44 -19.44 c 9.982 -9.982 9.982 -26.165 0 -36.147 C 77.523 8.511 70.982 6.016 64.44 6.016 L 64.44 6.016 z" />
      </g>
    </svg>
  );

  const toggleWishlist = async (collegeName: string, categoryId: number) => {
    try {
      if (userId) {
        console.log("im inside");
        const apiUrl = isWishlisted
          ? config.apiUrl + "/api/wishlist/remove"
          : config.apiUrl + "/api/wishlist/add";
        console.log(collegeName, categoryId, userId);

        // let values: any = {
        //   categoryName: "gitam school of pharmacy, hyderabad",
        //   categoryId: 1486,
        //   userId: 167,
        // };

        const response = await axios.post(
          apiUrl,
          {
            categoryName: "college",
            categoryId: categoryId,
            userId: userId,
          },
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response", response);
        if (response.data) {
          setIsWishlisted(!isWishlisted);
        } else {
          console.error("Error toggling wishlist:", response.statusText);
        }
      }
    } catch (error) {
      console.error("Error toggling wishlist:", error);
    }
  };
 
  const handleApply = () => {
    try {
      if (userId) {
        axios.post(
          config.apiUrl +
          `/api/admissions/apply?userId=${userId}&collegeId=${categoryId}&status=${"APPLY"}`
        )
        .then((response) => {
          if (response.status === 200) {
        alert("Apply request sent, college mentor team will update you soon");
          } else {
        console.error("Error applying to college:", response.statusText);
          }
        })
        .catch((error) => {
          console.error("Error applying to college:", error);
        });
      }
    } catch (e) {
      console.log("Error applying to college");
    }
  };

  return (
    <div className="flex flex-col college-item border border-grey bg-white">
      <div className="flex flex-grow flex-row gap-4 p-4">
        <div className="w-[80%]  ">
          <h3 className="college-name font-bold text-[20px] line-clamp-2 overflow-hidden">
            {collegeName.charAt(0).toUpperCase() + collegeName.slice(1)}
          </h3>{" "}
        </div>
        <div className="w-[20%]">
          {" "}
          <img
            src={image}
            alt={collegeName}
            className="college-image w-[50px] h-[50px] rounded-full"
          />
        </div>
      </div>
      <p className="college-category text-[14px] pl-4 pb-4 flex line-clamp-1">
        {" "}
        <LocationIcon />
        {location}
      </p>

      <p className="college-category text-[14px] pl-4 pb-4">
        <span className="font-bold">Category:</span> {category}
      </p>

      <div className="flex flex-col justify-center items-center pb-2 gap-2 px-2">
        <a href="/college-details" className="w-full p-2 border border-grey flex flex-row items-center justify-center gap-2 text-[#3f53d9]">
          <DetailIcon /> View Details
        </a>
        <div className="flex items-center gap-2 w-full">
          {" "}
          {/* Add flex container for wishlist icon */}
          {type === "explore" && (
            <button
              onClick={() => toggleWishlist(collegeName, categoryId)}
              className="flex-grow"
            >
              <WishlistIcon />
            </button>
          )}
          <div className=" bg-[#3f53d9] w-full rounded-lg">
            <button
              className="w-full p-2 font-bold arrow-button flex flex-row items-center justify-center gap-2  text-white"
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeCard;
