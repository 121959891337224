import React from "react";
import { LineMdArrowRight } from "../banner/Banner";

import ImageLink from "../../../images/career/request-info-applynow.png";
import { Link } from "react-router-dom";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";

interface RequestApplyNowData {
  title: string;
  description: string;
  buttontext: string;
  buttonLink: string;
}

interface RequestApplyProps {
  requestInfo: RequestApplyNowData;
  applyNow: RequestApplyNowData;
}

const RequestApplyNow: React.FC<RequestApplyProps> = ({
  requestInfo,
  applyNow,
}) => {
  return (
    <div className="max-lg:py-10 py-28">
      <div className="max-sm:w-full  mx-auto ">
        <div className="flex flex-col lg:flex-row justify-center items-center">
          <div className="w-full lg:w-[30%] h-fit">
            <div className="request-info flex flex-col relative justify-center items-center w-full">
              <div className="flex flex-col gap-10 bg-[#00B489] py-16 px-8 w-full max-sm:py-8 max-sm:gap-4">
                <div className="flex flex-col gap-2">
                  <h4 className="text-white text-4xl font-bold">
                    {requestInfo.title}
                  </h4>
                  <p className="text-base text-white">
                    {requestInfo.description}
                  </p>
                </div>
                <div className="flex">
                  <LeadGenCtaBtn
                    pageName="career-landing-page"
                    ctaName="request-info-submit-request"
                    className="bg-green arrow-button button-styles hover:bg-[#00B489] border-2 border-white py-3 px-8 text-base text-white flex flex-row gap-1 items-center justify-center rounded-full"
                  >
                    {requestInfo.buttontext}{" "}
                    <LineMdArrowRight className="arrow-icon" />
                  </LeadGenCtaBtn>
                </div>
              </div>
            </div>
          </div>
          <div className="w-11/12 mx-auto xl:-my-20 -my-4 relative z-20 lg:w-[40%]">
            <div className="flex flex-col gap-10 w-full">
              <img
                src="https://cdncollegementor.blob.core.windows.net/careers/careerWebp/requestApplyNow/request-info-applynow.webp"
                alt={requestInfo.title}
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="w-full lg:w-[30%] h-fit">
            <div className="request-info flex flex-col relative justify-center items-center ">
              <div className="flex flex-col bg-[#061958] py-16 px-8 gap-10 w-full">
                <div className="flex flex-col gap-2">
                  <h4 className="text-white text-4xl font-bold">
                    {applyNow.title}
                  </h4>
                  <p className="text-base text-white">{applyNow.description}</p>
                </div>
                <div className="flex">
                  <LeadGenCtaBtn
                    pageName="career-landing-page"
                    ctaName="apply-now-start-application"
                    className="arrow-button button-styles hover:bg-[#061958] border-2 border-white py-3 px-8 text-base text-white flex flex-row gap-1 items-center justify-center rounded-full"
                  >
                    {applyNow.buttontext}
                    <LineMdArrowRight className="arrow-icon" />
                  </LeadGenCtaBtn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestApplyNow;
