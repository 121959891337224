import React, { useContext, useEffect, useState, useRef } from "react";
import "./Courses.scss";
import { LoadingContext } from "../../components/Spinner/LoadingContext";
import Banner from "../../components/courses/Banner";
import SemesterAbroadProgram from "../../components/courses/SemesterAbroadProgram";
import MeetTopMentors from "../../components/courses/MeetTopMentors";
import ExploreStreamCourse from "../../components/courses/ExploreStreamCourse";
import TakeAFreetest from "../../components/courses/TakeaFreetest";
import SuccessStrip from "../../components/courses/SuccessStrip";
import ScholarshipGuidance from "../../components/courses/ScholarshipGuidance";
import StudentTestimonials from "../../components/courses/StudentTestimonials";
import CourseStreamQuiz from "../../components/courses/CourseStreamQuiz";
import ExploreMore from "../../components/courses/ExploreMore/ExploreMore";
import ExploreOccupation from "../../components/courses/ExploreOccupation";
import CollegeMentors from "../../components/courses/CollegeMentors";
import SubscribeNow from "../../components/courses/SubscribeNow";
import config from "../../util/config";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";

interface IStreamData {
  streamTitle: string;
  streamName: string;
  streamIcon: string;
}

interface SpotLight {
  image: string;
  pushlishDate: string;
  link: string;
  description: string;
  title: string;
}

interface VisualStory {
  id: number;
  title: string;
  description: string;
  stories: {
    id: number;
    type: "video" | "article" | "image";
    url: string;
    careerVisualStoryDataId?: number;
  }[];
}

interface Article {
  publishDate: string;
  description: string;
  title: string;
  slug: string;
}

interface Webinar {
  date: string;
  by: string;
  description: string;
  time: string;
  title: string;
  image1: string;
  name2: string;
  image2: string;
  name1: string;
}

interface ExploreMoreData {
  spotLight: SpotLight[];
  visualStories: VisualStory[];
  articles: Article[];
  webinar: Webinar[];
}

interface IApiData {
  streams: IStreamData[];
  exploreMore: ExploreMoreData;
}

const Courses: React.FC = () => {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [data, setData] = useState<IApiData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [activeSectionIndex, setActiveSectionIndex] = useState<number | null>(
    null
  );

  const streams =
    data?.streams?.map((item) => ({
      name: item.streamTitle,
      key: item.streamName,
      icon: item.streamIcon,
    })) || [];

  const exploreMore: ExploreMoreData = {
    spotLight: data?.exploreMore?.spotLight || [],
    visualStories: data?.exploreMore?.visualStories || [],
    articles: data?.exploreMore?.articles || [],
    webinar: data?.exploreMore?.webinar || [],
  };

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const response = await fetch(
          config.apiUrl + "/api/course/get-all-streams-courses"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result);
      } catch (err: any) {
        setError(err.message || "An unknown error occurred");
      } finally {
        stopLoading();
      }
    };
    fetchData();
    document.title =
      "College Mentor | Explore Trending & AI, Vocational Courses";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Learn essential skills for today’s world with College Mentor. Explore professional & trending courses, including AI, Gen AI, vocational programs & more courses."
      );
  }, [startLoading, stopLoading]);

  const careerVisualStoriesData = [];

  useEffect(() => {
    const observerCallbacks = [
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSectionIndex(0);
          }
        });
      },
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSectionIndex(1);
          }
        });
      },
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSectionIndex(2);
          }
        });
      },
    ];

    const observers = [
      new IntersectionObserver(observerCallbacks[0], { threshold: 0.1 }),
      new IntersectionObserver(observerCallbacks[1], { threshold: 0.5 }),
      new IntersectionObserver(observerCallbacks[2], { threshold: 0.1 }),
    ];

    sectionRefs.current.forEach((ref, index) => {
      if (ref) observers[index].observe(ref);
    });

    return () => {
      sectionRefs.current.forEach((ref, index) => {
        if (ref) observers[index].unobserve(ref);
      });
    };
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentor | Explore Trending & AI, Vocational Courses
          </title>
          <meta
            name="description"
            content="Learn essential skills for today’s world with College Mentor. Explore professional & trending courses, including AI, Gen AI, vocational programs & more courses."
          />
          <meta
            name="keywords"
            content="best free online courses, best free online course platforms, best free online learning platforms, best course for data science, trending courses, professional courses, top privileged courses, popular courses, explore courses by stream, vocational courses, AI courses, Gen AI courses."
          />
          <link rel="canonical" href={`${config.apiUrl}/courses`} />

          <meta
            property="og:title"
            content="College Mentor | Explore Trending & AI, Vocational Courses"
          />
          <meta
            property="og:description"
            content="Learn essential skills for today’s world with College Mentor. Explore professional & trending courses, including AI, Gen AI, vocational programs & more courses."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | Explore Trending & AI, Vocational Courses"
          />
          <meta
            name="twitter:description"
            content="Learn essential skills for today’s world with College Mentor. Explore professional & trending courses, including AI, Gen AI, vocational programs & more courses."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
          
          {/* Schema.org markup for Courses page */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "CollectionPage",
              "name": "Career Courses - College Mentor",
              "url": `${config.apiUrl}/courses`,
              "description": "College Mentor Courses - Explore a wide range of career-oriented courses across various fields including engineering, medical, management, and arts, with insights on eligibility, syllabus, and career prospects.",
              "about": [
                {
                  "@type": "Thing",
                  "name": "Trending Careers"
                },
                {
                  "@type": "Thing",
                  "name": "Trending Courses"
                },
                {
                  "@type": "Thing",
                  "name": "Online Courses"
                },
                {
                  "@type": "Thing",
                  "name": "Startup & Innovations"
                }
              ],
              "publisher": {
                "@type": "EducationalOrganization",
                "name": "College Mentor",
                "url": `${config.apiUrl}`,
                "logo": `${config.apiUrl}/static/media/College%20Mentor_Final_Logo-01.ae0ff9c1ffa9fc9fac3e.png`,
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+91-7997166666",
                  "contactType": "customer support",
                  "availableLanguage": ["English", "Hindi", "Telugu"]
                }
              }
            })}
          </script>
        </Helmet>
      </HelmetProvider>
      <Banner />
      <SemesterAbroadProgram />
      <MeetTopMentors />
      <ExploreStreamCourse data={streams} />
      <TakeAFreetest />
      <ExploreOccupation />
      <CourseStreamQuiz />
      <SuccessStrip />
      <CollegeMentors />
      <ScholarshipGuidance />
      <StudentTestimonials />
      <ExploreMore exploreMore={exploreMore} />
      <SubscribeNow />
    </>
  );
};

export default Courses;
