// src/components/StepNavigationWrapper.tsx
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { prevStep } from "../../../../../store/slices/registrationFormSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Signup,
  Login,
  verifyOtp,
  signupPAFDetail,
} from "../../../../../store/thunks/authThunks";
import { useNavigate } from "react-router-dom";
import { IconParkSolidRightC } from "../CurrentLevelStudy";

interface StepNavigationWrapperProps {
  children: React.ReactNode;
  onNext: () => void;
  showNext: boolean;
  showBack: boolean;
  stepName: string;
  stepDescription: string;
}

const StepNavigationWrapper: React.FC<StepNavigationWrapperProps> = ({
  children,
  onNext,
  showNext = true,
  showBack = true,
  stepName,
  stepDescription,
}) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const { data, step } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );
  const { otpSent, error, isAuthenticated, token, user, tcAgreed } =
    useSelector((state: RootState) => state.auth);

  const [isContinueEnabled, setIsContinueEnabled] = useState(false);

  useEffect(() => {
    if (otpSent && isAuthenticated && tcAgreed && user) {
      localStorage.setItem("user", JSON.stringify(user));
      console.log("User data stored in local storage:", user);

      setIsContinueEnabled(true);
    } else {
      setIsContinueEnabled(false);
    }
  }, [otpSent, isAuthenticated, tcAgreed, user]);

  const handleNext = () => {
    debugger;
    const existingData = JSON.parse(localStorage.getItem("paf_latest") || "{}");
    console.log("handleNext", existingData);
    console.log("step", step);
    if (step === 9) {
      console.log("Call Register API ");

      dispatch(
        Signup({
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          mobileNumber: data?.mobileNumber,
          currentLevelOfStudy: data?.currentLevelOfStudy,
          lookingForDegree: data?.degree,
          lookingForStream: data?.stream?.streamName,
          lookingForCourse: data?.course?.courseName,
          state: data?.state,
          lookingForScholarship: existingData?.lookingForScholarship,
          scholarshipType: "",
          educationFund: data?.educationFund,
          collegeChoiceFactor: "",
          highEducationAt: data?.higherEducation,
          percentageAchieveOrExpect: existingData?.percentages,
          studyBoard: data?.boardOfEducation,
        })
      )
        .unwrap()
        .then(() => {
          navigate("/");
        })
        .catch((err: any) => {
          console.error("OTP verification failed:", err);
        });
    } else {
      onNext();
      // console.log("handleNext", data);
      // dispatch(
      //   signupPAFDetail({
      //     streamId: data?.stream?.streamId,
      //     courseId: 0,
      //     state: 0,
      //     city: 0,
      //     currentLevelOfStudy: "",
      //   })
      // )
      //   .unwrap()
      //   .then(() => {
      //     // onNext();
      //   })
      //   .catch((err: any) => {
      //     console.error("OTP verification failed:", err);
      //   });
    }
  };

  const handleBack = () => {
    const existingData = JSON.parse(localStorage.getItem("paf_latest") || "{}");

    // Getting the currentLevelOfStudy from existingData
    const currentLevelOfStudy = existingData.currentLevelOfStudy || "";

    // Checking if current level of study is "UG", "PG", or "Others"
    if (
      step === 4 &&
      (currentLevelOfStudy === "UG" ||
        currentLevelOfStudy === "PG" ||
        currentLevelOfStudy === "Others")
    ) {
      // Navigate back to step 2 when the current level of study is "UG", "PG", or "Others"
      dispatch(prevStep(2));
    } else {
      //Default step
      dispatch(prevStep());
    }
  };

  return (
    <Fragment>
      <div className="flex flex-row items-start gap-2">
        {showBack && step > 2 && (
          <button
            className="flex items-center justify-center w-7 h-7 text-blue rounded-full bg-none hover:text-green"
            onClick={handleBack}
          >
            <IconParkSolidRightC className="rotate-180" />
          </button>
        )}
        <div className="flex flex-col">
          <span className="text-xl font-normal text-[#113cc0]">{stepName}</span>
          <p className="text-sm text-black-500">{stepDescription}</p>
        </div>
      </div>
      <>{children}</>
      <div className="flex flex-row justify-center gap-2 navigation-buttons">
        {/* {showBack && step > 1 && (
          <button
            type="button"
            onClick={handleBack}
            className="p-3 hover:text-grey-500"
            style={{ padding: "8px 18px" }}
          >
            Previous
          </button>
        )} */}
        {showNext && (
          <button
            type="button"
            onClick={handleNext}
            className={`transition duration-0 hover:duration-150 py-3 px-5 border rounded-full ${
              isContinueEnabled
                ? "bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white"
                : "bg-green text-black cursor-not-allowed opacity-30"
            } ease-in-out`}
            disabled={!isContinueEnabled}
          >
            Continue
          </button>
        )}

        {/* <button
          type="button"
          // onClick={handleBack}
          className="p-3 hover:text-grey-500"
          style={{ padding: "8px 18px" }}
        >
          Skip
        </button> */}
      </div>
    </Fragment>
  );
};

export default StepNavigationWrapper;
