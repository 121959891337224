import React from "react";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";

const ApplicationBanner = () => {
  return (
    <div className="bg-[#F4F7FB] pb-10 max-sm:pb-8">
      <div className="list-of-colleges-in-india-banner-section">
        <div className="bg-gradient-to-r from-[#0B3D8C] to-[#06AFA9]">
          <div className="container max-w-screen-2xl text-white py-8 md:py-16">
            <div className="flex flex-col md:flex-row gap-8">
              <div className="relative w-full md:w-[70%] flex flex-col justify-center">
                <div className="md:mb-10">
                  <Breadcrumbs
                    links={[
                      { name: "Home", path: "/" },
                      { name: "College Direct Application", path: "/application-admission" }
                    ]}
                  />
                </div>
                <h3 className="text-4xl md:text-5xl min-max-xss:mt-6 min-max-xs:mt-6 font-bold line-clamp-2">
                  Unlock Your Next Chapter
                </h3>
                <p className="text-lg md:text-xl mt-4 mb-auto line-clamp-3">
                  Explore colleges, exams & more. Apply directly.
                  <br />
                  Simplify your education journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationBanner;