import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { Banner } from "../../components/careers-comp/banner/Banner";
import FeaturedCareers from "../../components/careers-comp/featuredCareers/FeaturedCareers";
import HighPaidCareer from "../../components/careers-comp/highPaidCareer/HighPaidCareer";
import ConnectToMentor from "../../components/careers-comp/connectMentor/ConnectToMentor";
import KnowledgeHub from "../../components/careers-comp/knowledgeHub/KnowledgeHub";
import SubscribeNow from "../../components/courses/SubscribeNow";
import DreamCareerJourney from "../../components/careers-comp/dreamCareerJourney/DreamCareerJourney";
import RequestApplyNow from "../../components/careers-comp/requestApplyNow/RequestApplyNow";
import DreamCareers from "../../components/careers-comp/dreamCareers/DreamCareers";
import type { SVGProps } from "react";
import "animate.css";
import config from "../../util/config";
import { Helmet, HelmetProvider } from "react-helmet-async";
import logo from "../../images/navbar/College Mentor_Final_Logo-01.png";

export function IcBaselineError(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3em"
      height="3em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m1 15h-2v-2h2zm0-4h-2V7h2z"
      ></path>
    </svg>
  );
}
// CareersMockData object
const CareersMockData = {
  dreamCareerJourney: {
    Experience: [
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Student%20Mentor.webp",
        description: "Real people like you",
        title: "Student Mentor",
      },
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Career%20Spotlight.webp",
        description: "Award-winning documentaries",
        title: "Career Spotlight",
      },
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Career%20pathway.webp",
        description: "Stories exploring new careers and paths",
        title: "Career pathway",
      },
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Talk%20to%20Career%20Mentor.webp",
        description:
          "It’s different having a dream than when you actually see it in person.",
        title: "Talk to Career Mentor",
      },
    ],
    Explore: [
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Career%20Match.webp",
        description:
          "Gain Insights from Experienced Career Mentors Who Have Traveled the Same Path",
        title: "Career Match",
      },
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Career%20Library.webp",
        description: "Personalized matches to careers and stories",
        title: "Career Library",
      },
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Career%20Assessment%20Test.webp",
        description: "A Starting Point for Achieving Your Goals and Plans",
        title: "Career Assesment Test",
      },
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Map%20My%20Career.webp",
        description: "Hearing their stories truly ignited my passion",
        title: "Map My Career",
      },
    ],
    ways: ["Explore", "Learn", "Experience"],
    Learn: [
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Virtual%20Internships.webp",
        description: "Five lesson videos and internship activities",
        title: "Virtual Internships",
      },
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Career%20goals.webp",
        description: "Careers driven by your interests",
        title: "Career goals",
      },
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Certifications.webp",
        description: "Skill-building and exposure to real careers",
        title: "Certifications",
      },
      {
        imageUrl:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/dreamCareerJourney/Map%20My%20Career.webp",
        description: "Now I know it’s possible and I know that I can do it.",
        title: "Map My Career",
      },
    ],
  },
  bannerInfo: [
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/bannerInfo/careerLanding-banner1.webp",
      description:
        "Discover how you match with more than 800 careers based on your personality, interests, experience and ambitions.",
      title: "Engineerin",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/bannerInfo/careerLanding-banner2.webp",
      description:
        "Discover how you match with more than 800 careers based on your personality, interests, experience and ambitions.",
      title: "Medical & Allied Sciences",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/bannerInfo/careerLanding-banner3.webp",
      description:
        "Discover how you match with more than 800 careers based on your personality, interests, experience and ambitions.",
      title: "Management",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/bannerInfo/careerLanding-banner4.webp",
      description:
        "Discover how you match with more than 800 careers based on your personality, interests, experience and ambitions.",
      title: "Liberal arts",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/bannerInfo/careerLanding-banner5.webp",
      description:
        "Discover how you match with more than 800 careers based on your personality, interests, experience and ambitions.",
      title: "Design",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/bannerInfo/careerLanding-banner6.webp",
      description:
        "Discover how you match with more than 800 careers based on your personality, interests, experience and ambitions.",
      title: "Law",
    },
    {
      image:
        "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/bannerInfo/careerLanding-banner7.webp",
      description:
        "Discover how you match with more than 800 careers based on your personality, interests, experience and ambitions.",
      title: "Hotel & Tourism",
    },
  ],
  knowledgeHub: {
    careerVisualStories: [],
    careerInterest: [
      {
        careers: [
          { name: "ui-ux-designer", title: "Ui/UX Designer" },
          { name: "fashion-designer", title: "Fashion Designer" },
          { name: "journalist", title: "Journalist" },
          { name: "graphic-designer", title: "Graphic Designer" },
          { name: "film-director", title: "Film Director" },
          { name: "photographer", title: "Photographer" },
          { name: "game-designer", title: "Game Designer" },
          { name: "ux-ui-designer", title: "UX/UI Designer" },
          { name: "fashion-designer", title: "Fashion Designer" },
          { name: "chef", title: "Chef" },
          { name: "cinematographer", title: "Cinematographer" },
          { name: "sound-engineer", title: "Sound Engineer" },
          { name: "car-designer", title: "Car Designer" },
          { name: "art-director", title: "Art Director" },
          { name: "game-designer", title: "Game Designer" },
          { name: "acting", title: "Acting" },
        ],
        imageUrls:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/knowledgeHub/Artistic.webp",
        name: "Artistic Interest",
        description: "Creative Designing and Making your own Rules",
      },
      {
        careers: [
          { name: "nuclear-engineer", title: "Nuclear Engineer" },
          { name: "web-developer", title: "Web Developer" },
          { name: "cloud-developer", title: "Cloud Developer" },
          { name: "full-stack-developer", title: "Full Stack Developer" },
          { name: "devops-engineer", title: "DevOps Engineer" },
          { name: "biomedical-engineer", title: "Biomedical Engineer" },
          { name: "software-engineer", title: "Software Engineer" },
          { name: "electrical-engineer", title: "Electrical Engineer" },
          { name: "civil-engineer", title: "Civil Engineer" },
          { name: "aerospace-engineer", title: "Aerospace Engineer" },
          { name: "automobile-engineer", title: "Automobile Engineer" },
          { name: "pilot", title: "Pilot" },
          { name: "doctor", title: "Doctor" },
          { name: "engineer", title: "Engineer" },
          { name: "agriculture", title: "Agriculture" },
          { name: "firefighter", title: "Firefighter" },
          { name: "electrician", title: "Electrician" },
          {
            name: "computer-hardware-engineer",
            title: "Computer Hardware Engineer",
          },
          { name: "software-developer", title: "Software Developer" },
          { name: "aeronautical-engineer", title: "Aeronautical Engineer" },
          { name: "process-engineer", title: "Process Engineer" },
          {
            name: "automation-test-engineer",
            title: "Automation Test Engineer",
          },
          { name: "marine-engineer", title: "Marine Engineer" },
          { name: "engineer", title: "Engineer" },
        ],
        imageUrls:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/knowledgeHub/Realistic.webp",
        name: "Realistic Interest",
        description: "Practical Hands on Problems and Solutions",
      },
      {
        careers: [
          { name: "data-scientist", title: "Data Scientist" },
          { name: "big-data-analyst", title: "Big Data Analyst" },
          { name: "cyber-security-analyst", title: "Cyber Security Analyst" },
          { name: "blockchain-developer", title: "Blockchain Developer" },
          { name: "ethical-hacker", title: "Ethical Hacker" },
          { name: "pharmacist", title: "Pharmacist" },
          { name: "prompt-engineer", title: "Prompt Engineer" },
          { name: "radiologist", title: "Radiologist" },
          {
            name: "digital-forensics-analyst",
            title: "Digital Forensics Analyst",
          },
          { name: "forensic-scientist", title: "Forensic Scientist" },
          { name: "environmental-scientist", title: "Environmental Scientist" },
          { name: "psychiatrist", title: "Psychiatrist" },
          { name: "criminal-investigator", title: "Criminal Investigator" },
          {
            name: "artificial-intelligence-researcher",
            title: "Artificial Intelligence Researcher",
          },
          { name: "financial-analyst", title: "Financial Analyst" },
        ],
        imageUrls:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/knowledgeHub/Investigative.webp",
        name: "Investigative Interest",
        description: "Ideas, Thinking and Figuring Things out",
      },
      {
        careers: [
          { name: "hr-manager", title: "HR Manager" },
          { name: "dentist", title: "Dentist" },
          { name: "surgeon", title: "Surgeon" },
          { name: "psychiatrist", title: "Psychiatrist" },
          { name: "paediatrician", title: "Paediatrician" },
          { name: "occupational-therapist", title: "Occupational Therapist" },
          { name: "veterinarian", title: "Veterinarian" },
          { name: "psychologist", title: "Psychologist" },
          { name: "child-psychologist", title: "Child Psychologist" },
          { name: "teacher", title: "Teacher" },
          { name: "nurse", title: "Nurse" },
          { name: "mental-health-counselor", title: "Mental Health Counselor" },
          { name: "police-officer", title: "Police Officer" },
          { name: "career-counselor", title: "Career Counselor" },
          { name: "social-worker", title: "Social Worker" },
        ],
        imageUrls:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/knowledgeHub/Social.webp",
        name: "Social Interest",
        description: "Helping People. Teaching and Talking",
      },
      {
        careers: [
          { name: "judge", title: "Judge" },
          { name: "business-analyst", title: "Business Analyst" },
          { name: "investment-banker", title: "Investment Banker" },
          { name: "cryptocurrency-trader", title: "Cryptocurrency Trader" },
          { name: "supply-chain-manager", title: "Supply Chain Manager" },
          { name: "accountant", title: "Accountant" },
          { name: "marine-manager", title: "Marine Manager" },
          { name: "entrepreneur", title: "Entrepreneur" },
          { name: "marketing-manager", title: "Marketing Manager" },
          { name: "financial-planner", title: "Financial Planner" },
          { name: "politician", title: "Politician" },
          { name: "film-producer", title: "Film Producer" },
          { name: "sports-manager", title: "Sports Manager" },
          { name: "real-estate-developer", title: "Real Estate Developer" },
        ],
        imageUrls:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/knowledgeHub/Conventiona.webp",
        name: "Conventional",
        description: "Creative Designing and Making your own Rules",
      },
      {
        careers: [
          { name: "corporate-lawyer", title: "Corporate Lawyer" },
          { name: "criminal-lawyer", title: "Criminal Lawyer" },
          {
            name: "digital-marketing-executive",
            title: "Digital Marketing Executive",
          },
          { name: "marketing-manager", title: "Marketing Manager" },
          { name: "project-manager", title: "Project Manager" },
          { name: "sales-manager", title: "Sales Manager" },
          { name: "social-media-influencer", title: "Social Media Influencer" },
          { name: "seo-specialist", title: "SEO Specialist" },
          { name: "legal-advisor", title: "Legal Advisor" },
          { name: "hotel-manager", title: "Hotel Manager" },
          { name: "receptionist", title: "Receptionist" },
          { name: "library-technician", title: "Library Technician" },
          { name: "loan-officer", title: "Loan Officer" },
          { name: "logistics-manager", title: "Logistics Manager" },
          { name: "school-secretary", title: "School Secretary" },
          { name: "tele-caller", title: "Tele Caller" },
        ],
        imageUrls:
          "https://cdncollegementor.blob.core.windows.net/careers/careerWebp/knowledgeHub/Enterprising.webp",
        name: "Enterprising Interest",
        description: "Practical Hands on Problems and Solutions",
      },
    ],

    careerspotlights: [],
    blogsAndArticles: [],
    careerbulleyes: [],
  },
};

// Define the RequestApplyNowData object here
const RequestApplyNowData = {
  requestInfo: {
    title: "Request Info",
    description: "Interested in knowing more about Career Scholarships?",
    buttontext: "Submit Request",
    buttonLink: "/career-intermediate",
  },
  applyNow: {
    title: "Apply Now",
    description: "Ready to start the Application to Admission Process?",
    buttontext: "Start Application",
    buttonLink: "/career-intermediate",
  },
};

const Careers = () => {
  const [careerData, setCareerData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [courseCareersData, setcourseCareersData] = useState<any>([]);
  const [blogsAndArticlesData, setBlogsAndArticlesData] = useState<any>([]);

  useEffect(() => {
    axios
      .get(config.apiUrl + `/api/career/landing-page`)
      .then((response) => {
        setCareerData(response.data);
        getBlogsandNews();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    document.title =
      "College Mentor | Find Best Careers for the Future & Top Paying Careers";
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        "Explore top paying careers, Emerging carers and engineering roles, with updated salary information. Find your dream career and explore featured careers with College Mentor’s career listing and compatibility tools."
      );
  }, []);

  const getBlogsandNews = () => {
    axios
      .get(config.apiUrl + `/api/blogs/blogsandnews`)
      .then((response) => {
        setcourseCareersData(
          response.data.sections[0]["Career & Courses"].subcategories[
            "Articles & Blogs"
          ].results
        );
        prepareCareersData(
          response.data.sections[0]["Career & Courses"].subcategories[
            "Articles & Blogs"
          ].results
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const prepareCareersData = (data: any) => {
    const filteredData = data.filter((item: any) =>
      item.subTopic.toLowerCase().includes("".toLowerCase())
    );
    setBlogsAndArticlesData(filteredData);
  };

  if (loading) {
    return <Loader />;
  }
  if (!careerData) {
    return (
      <div className="py-10 lg:py-16">
        <div className="container mx-auto">
          <div className="flex flex-col justify-center items-center">
            <IcBaselineError className="text-6xl text-red-500" />
            <h3 className="text-xl text-black animate-pulse">
              Error loading data...
            </h3>
          </div>
        </div>
      </div>
    );
  }
  const { Explore, Learn, Experience } =
    CareersMockData.dreamCareerJourney || {};
  const {
    careerInterest,
    careerbulleyes,
    careerVisualStories,
    careerspotlights,
    blogsAndArticles,
  } = CareersMockData.knowledgeHub || {};

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* Google Tag Manager Script */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${config.GA_TRACKING_ID}');
        `}
          </script>

          <title>
            College Mentor | Find Best Careers for the Future & Top Paying
            Careers
          </title>
          <meta
            name="description"
            content="Explore top paying careers, Emerging carers and engineering roles, with updated salary information. Find your dream career and explore featured careers with College Mentor’s career listing and compatibility tools."
          />
          <meta
            name="keywords"
            content="Top paying careers, engineering, ai careers, up to date salary, compatability, college mentor, career list in 2 min, featured careers, dream career, careers repository, career listing, high paid career"
          />
          <link rel="canonical" href={`${config.apiUrl}/careers`} />

          <meta
            property="og:title"
            content="College Mentor | Find Best Careers for the Future & Top Paying Careers"
          />
          <meta
            property="og:description"
            content="Explore top paying careers, Emerging carers and engineering roles, with updated salary information. Find your dream career and explore featured careers with College Mentor’s career listing and compatibility tools."
          />
          <meta property="og:url" content={logo} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="College Mentor | Find Best Careers for the Future & Top Paying Careers"
          />
          <meta
            name="twitter:description"
            content="Explore top paying careers, Emerging carers and engineering roles, with updated salary information. Find your dream career and explore featured careers with College Mentor’s career listing and compatibility tools."
          />
          <meta name="twitter:image" content={logo} />
          <meta name="robots" content="index, follow" />
        </Helmet>
      </HelmetProvider>
      <div>
        <Banner bannerInfo={CareersMockData.bannerInfo} />
        <FeaturedCareers featuredCareers={careerData.featuredCareers} />
        <DreamCareers dreamCareers={careerData.dreamCareers} />
        <SubscribeNow />
        <DreamCareerJourney
          Explore={Explore || []}
          Learn={Learn || []}
          Experience={Experience || []}
        />
        <HighPaidCareer highPaidCareer={careerData.highPaidCareer} />
        <ConnectToMentor connectToMentor={careerData.connectToMentor} />
        <KnowledgeHub
          careerInterest={careerInterest || []}
          careerbullseye={careerbulleyes || []}
          careerVisualStories={careerVisualStories || []}
          careerspotlights={careerspotlights || []}
          blogsAndArticles={blogsAndArticlesData || []}
        />
        <RequestApplyNow
          requestInfo={RequestApplyNowData.requestInfo}
          applyNow={RequestApplyNowData.applyNow}
        />
      </div>
    </>
  );
};

export default Careers;
