import React, { useEffect, useState, useRef } from "react";
import Loader from "../../../components/Loader/Loader";
import config from "../../../util/config";
import type { SVGProps } from "react";

export function MingcuteRightLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0z" />
        <path
          fill="currentColor"
          d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 0 1 1.414-1.414z"
        />
      </g>
    </svg>
  );
}

interface TabContentProps {
  boardName: string;
  tabName: string;
  onLoad?: (hasContent: boolean) => void;
}

const TabContent: React.FC<TabContentProps> = ({
  boardName,
  tabName,
  onLoad,
}) => {
  const [tabContent, setTabContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [headings, setHeadings] = useState<string[]>([]);
  const contentRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [showArrows, setShowArrows] = useState(false);

  useEffect(() => {
    fetchTabContent();
  }, [tabName, boardName]);

  const fetchTabContent = async () => {
    setLoading(true);
    try {
      const res = await fetch(
        `${config.apiUrl}/api/educationBoard/get-details?boardName=${boardName}&tabName=${tabName}`
      );
      const data = await res.json();
      const content = data?.data?.htmlContent || "No data available";
      setTabContent(content);
    } catch (err) {
      setTabContent("No data available");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading && tabContent) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = tabContent;
      const h2Elements = tempDiv.querySelectorAll("h2");
      const h2Texts = Array.from(h2Elements).map((el) => el.textContent || "");
      setHeadings(h2Texts);
    }
  }, [loading, tabContent]);

  useEffect(() => {
    const h2Tags = contentRef.current?.querySelectorAll("h2");
    h2Tags?.forEach((el) => el.classList.add("scroll-mt-16"));
  }, [tabContent]);

  useEffect(() => {
    const checkHeight = () => {
      if (tableRef.current) {
        setShowArrows(tableRef.current.scrollHeight > 345);
      }
    };
    checkHeight();
    window.addEventListener("resize", checkHeight);
    return () => window.removeEventListener("resize", checkHeight);
  }, [headings]);

  // 🔥 Report height to parent
  useEffect(() => {
    if (!loading && onLoad) {
      setTimeout(() => {
        const contentHeight = contentRef.current?.clientHeight || 0;
        const tocHeight = tableRef.current?.clientHeight || 0;
        const total = contentHeight + tocHeight;
        onLoad(tabContent !== "No data available" && total > 0);
      }, 100); // let layout settle
    }
  }, [loading, tabContent]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="w-full container mx-auto p-0">
      {headings.length > 0 && (
        <div className="mb-6 text-white">
          <h2 className="text-2xl font-semibold bg-blue py-2 px-4">
            Table of Contents
          </h2>
          <div className="flex w-full flex-col p-4 border-[0.5px] border-[#a0a0a0] text-gray-500 relative">
            {showArrows && (
              <MingcuteRightLine className="-rotate-90 absolute right-[7px] top-0 text-[#a0a0a0]" />
            )}
            <div
              ref={tableRef}
              className="tableofContent max-h-[345px] overflow-y-auto"
            >
              <ul className="space-y-2 text-blue font-medium flex flex-col gap-3">
                {headings.map((title, index) => (
                  <li
                    key={index}
                    className="cursor-pointer hover:underline text-black text-sm"
                    onClick={() => {
                      const allH2 = contentRef.current?.querySelectorAll("h2");
                      allH2?.forEach((el) => {
                        if (el.textContent?.trim() === title.trim()) {
                          const top =
                            el.getBoundingClientRect().top +
                            window.pageYOffset -
                            120;
                          window.scrollTo({ top, behavior: "smooth" });
                        }
                      });
                    }}
                  >
                    {title}
                  </li>
                ))}
              </ul>
            </div>
            {showArrows && (
              <MingcuteRightLine className="rotate-90 absolute right-[7px] bottom-0 text-[#a0a0a0]" />
            )}
          </div>
        </div>
      )}

      <div
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: tabContent }}
        className="bg-white text-[15px] leading-7 educationBoard"
      />
    </div>
  );
};

export default TabContent;
