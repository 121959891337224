import React, { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "animate.css";
import "swiper/css";
import "swiper/css/bundle";
import { Autoplay, Navigation } from "swiper/modules";
import rightarrow from "../../../images/courses/course-details/rightarrow.svg";
import LeadGenCtaBtn from "../../../features/lead-generation/LeadGenCtaBtn";

const TopIndianPrivateCollegesList = () => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [navBtnDisabled, setNavBtnDisabled] = useState({
    prev: true,
    next: false,
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [isPrevHovered, setIsPrevHovered] = useState(false);

  useEffect(() => {
    if (swiperRef && swiperRef.slides) {
      setTotalSlides(swiperRef.slides.length);
    }
  }, [swiperRef]);

  const handleSlideChange = () => {
    if (swiperRef) {
      setCurrentSlide(swiperRef.realIndex || 0);
      setNavBtnDisabled({
        prev: swiperRef.isBeginning,
        next: swiperRef.isEnd,
      });
    }
  };

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const nextButton = (
    <button
      className={`${navBtnDisabled.next ? "" : ""} p-2`}
      onClick={handleNext}
    >
      <RightArrow isPrevHovered={isPrevHovered} />
    </button>
  );

  const TopPrivateCollegesContent = {
    title: "Top Private Colleges & Universities",
    collegeList: [
      {
        collegeImg: "/images/AdityaUniversity.webp",
        title: "Aditya University",
        location: "Andhra Pradesh",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total B.Tech 6+ Specialisations",
        totalFee: "Total Fees: 1 Lakh to 2.7 Lakh Per Year",
        moreLink: "/college/aditya-university",
      },
      {
        collegeImg: "/images/alliance-university.webp",
        title: "Alliance University",
        location: "Karnataka",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total B.Tech 10+ Specialisations",
        totalFee: "Total Fees: 2 Lakh to 4.5 Lakh Per Year",
        moreLink: "/college/alliance-university",
      },
      {
        collegeImg: "/images/woxsen-university.webp",
        title: "Woxsen University",
        location: "Telangana",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total B.Tech 8+ Specialisations",
        totalFee: "Total Fees: 7.8 Lakh Per Year",
        moreLink: "/college/woxsen-university",
      },
      {
        collegeImg: "/images/SaiUniversity.webp",
        title: "Sai University",
        location: "Tamil Nadu",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total B.Tech 5+ Specialisations",
        totalFee: "Total Fees: 2.5 Lakh to 3.5 Lakh Per Year",
        moreLink: "/college/sai-university",
      },
      {
        collegeImg: "/images/saveetha-university.webp",
        title: "Saveetha University",
        location: "Tamil Nadu",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total B.Tech 10+ Specialisations",
        totalFee: "Total Fees: 2.5 Lakh to 4.5 Lakh Per Year",
        moreLink: "/college/saveetha-university",
      },
      {
        collegeImg: "/images/satyabhama-university.webp",
        title: "Sathyabama University",
        location: "Tamil Nadu",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total B.Tech 15+ Specialisations",
        totalFee: "Total Fees: 2.5 Lakh to 4.5 Lakh Per Year",
        moreLink: "/college/sathyabama-university",
      },
      {
        collegeImg: "/images/s-vyasa-university.webp",
        title: "S-Vyasa University",
        location: "Karnataka",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total B.Tech 5+ Specialisations",
        totalFee: "Total Fees: 3 Lakh to 5 Lakh Per Year",
        moreLink: "/college/s-vyasa-university",
      },
      {
        collegeImg: "/images/vel-tech-university.webp",
        title: "Vel-Tech University",
        location: "Tamil Nadu",
        program: "BTech Program | UGC Recognised",
        specialisations: "Total B.Tech 10+ Specialisations",
        totalFee: "Total Fees: 3 Lakh Per Year",
        moreLink: "/college/vel-tech-university",
      },
    ],
  };

  return (
    <div className="flex w-full flex-row lg:gap-[60px] ">
      <Swiper
        onSwiper={setSwiperRef}
        onSlideChange={handleSlideChange}
        spaceBetween={30}
        className="w-full"
        modules={[Navigation, Autoplay]}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 2.5,
          },
          1270: {
            slidesPerView: 4,
          },
        }}
      >
        {TopPrivateCollegesContent.collegeList.map(
          (college: any, index: number) => (
            <SwiperSlide
              className="flex flex-col gap-4 bg-[#FFF6F1] rounded-lg "
              style={{
                boxShadow: "0px 2.033px 7.622px 0px rgba(0, 0, 0, 0.10)",
              }}
              key={index}
            >
              <div className="flex flex-col gap-1">
                <img
                  src={college.collegeImg}
                  alt={college.title}
                  className="w-full h-[150px] object-cover"
                  style={{ borderRadius: "7px 7px 0px 0px" }}
                />

                <div className="flex flex-col gap-2 py-2 px-2 xl:h-[117px]">
                  <h4 className="text-sm font-semibold text-[#383838] m-0">
                    {college.title} <br />
                    <span className="font-normal text-xs">
                      {college.location}
                    </span>
                  </h4>

                  <p className="text-[10px] text-[#383838] m-0 flex items-center gap-2">
                    {college?.program}
                  </p>
                  <p className="text-[10px] text-[#4C495C] flex flex-row items-center gap-[3px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                    >
                      <path
                        d="M7.625 4.00022C7.625 6.00239 6.00201 7.62543 4 7.62543C1.99799 7.62543 0.375 6.00238 0.375 4.00022C0.375 1.99804 1.99799 0.375 4 0.375C6.00201 0.375 7.625 1.99804 7.625 4.00022Z"
                        stroke="#00CC99"
                        stroke-width="0.75"
                      />
                      <path
                        d="M1.81836 4.54552L3.63664 5.45466C4.00029 4.60613 4.98217 2.83633 6.0004 2.54541"
                        stroke="#00CC99"
                        stroke-width="0.75"
                        stroke-linecap="round"
                      />
                    </svg>
                    {college.specialisations}
                  </p>
                  <p className="text-[10px] text-right text-[#4C495C] font-normal">
                    Total Fees:{" "}
                    <span className="font-semibold">{college.totalFee}</span>
                  </p>
                </div>
              </div>
              <div className="mx-auto flex flex-row w-[240px] justify-center rounded-md my-2 gap-2 bg-[--primary-color] hover:bg-[--secondary-color] transition-all duration-300 ease-in-out group">
                {/* <button className=" flex  text-[11px] text-white font-semibold rounded-md py-2 px-5 cursor-pointer ">
                  
                </button> */}
                <LeadGenCtaBtn
                  pageName="application-to-direct-admission-landing"
                  ctaName="apply-now"
                  additionalInfo={college.url}
                  className="flex  text-[11px] text-white font-semibold rounded-md py-2 px-5 cursor-pointer"
                >
                  <span> Apply Now & Book College Tour</span>
                </LeadGenCtaBtn>
                <img
                  src={rightarrow}
                  alt="rightarrow"
                  className="w-3 h-3 max-xss:w-4 max-xss:h-4 lg:w-8 max-lg:w-6  max-lg:h-6 lg:h-8 group-hover:translate-x-2 transition-transform duration-300 max-lg:my-auto "
                />
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>
      <div className="flex items-center max-md:hidden">{nextButton}</div>
    </div>
  );
};

const ExploreYourFuture = () => {
  return (
    <div className="bg-[#F7F7F7] w-full relative md:pt-[30px] md:pb-[70px] md:px-20 max-md:py-6">
      <div className="flex flex-col pb-6">
        <h3 className="text-[#0C9] text-center font-roboto text-[36px] max-md:text-[32px] font-bold leading-[50px] capitalize">
          Explore Your Future
        </h3>
        <p className="text-[#173CBA] text-center font-roboto text-[32px] max-md:text-[27px] font-normal leading-[50px] capitalize">
          Book A Campus Tour For Students & Parent
        </p>
      </div>
      <div className="flex flex-col  bg-white max-md:bg-[#F7F7F7] max-md:mx-[30px]">
        <div className="flex ">
          <h2 className="text-[18px] lg:text-2xl max-sm:text-center font-semibold text-[#fff] bg-[#173CBA] px-4 py-2.5 lg:px-9 lg:py-2 m-0 relative max-md:w-full">
            {`Top Private Colleges & Universities `}
          </h2>
        </div>
        <div className="flex container px-9 pt-9 pb-5 max-sm:pb-0 ">
          <TopIndianPrivateCollegesList />
        </div>
      </div>
    </div>
  );
};

export default ExploreYourFuture;

const RightArrow = ({ isPrevHovered }: { isPrevHovered: boolean }) => {
  return (
    <div
      className={`p-1 border rounded-full ${
        isPrevHovered ? "bg-white border-black" : "bg-blue border-blue"
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
      >
        <path
          d="M20.5 41.0022C31.8218 41.0022 41 31.8235 41 20.5011C41 9.17866 31.8218 0 20.5 0C9.17816 0 0 9.17866 0 20.5011C0 31.8235 9.17816 41.0022 20.5 41.0022Z"
          fill="#173CBA"
        />
        <path
          d="M15.4883 6.37793V12.6599L23.0759 20.5009L15.4883 28.3419V34.6239L29.1549 20.5009L15.4883 6.37793Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
